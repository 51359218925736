import React, { useEffect, useMemo, useRef, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import axios from "axios";
import { useMyGlobal } from "../component/Global";
import { Button, Form, FormInput, FormSelect, Icon, Popup, TextArea } from "semantic-ui-react";
import Modal from "rsuite/Modal"
import moment from "moment";
import Table from "react-bootstrap/Table";
import { curency, curencyNoSym } from "../component/utils";
import { Toaster } from "../component/Toaster"
import { Reacttable } from "../component/Reacttable";
import Stack from "react-bootstrap/Stack"
import DateRangePicker from 'rsuite/DateRangePicker';

export const ICTExpenseEntry = () => {
    const { host, userProfile } = useMyGlobal()
    const [modalEntry, setModalEntry] = useState(false)
    const [trxDate, setTrxDate] = useState(moment().format('YYYY-MM-DD'))
    const [trxCat, setTrxCat] = useState('')
    const [trxInv, setTrxInv] = useState('')
    const [trxDesc, setTrxDesc] = useState('')
    const [itemData, setItemData] = useState([])
    const [newRow, setNewRow] = useState({ item: '', qty: 1, price: 0, discount: 0, dsc: 0, total: 0 })
    const nameInputRef = useRef(null); // Ref untuk input name
    const [isNewRowAdded, setIsNewRowAdded] = useState(false) // State untuk melacak penambahan baris baru
    const [totalQty, setTotalQty] = useState(0)
    const [totalDsc, setTotalDsc] = useState(0)
    const [grandTotal, setGrandTotal] = useState(0)
    const [modalDiscount, setModalDiscount] = useState(false)
    const [dscType, setDscType] = useState('')
    const [dscDesc, setDscDesc] = useState('')
    const [dscValue, setDscValue] = useState(0)
    const [selectedRow, setSelectedRow] = useState(null)
    const [total, setTotal] = useState(0)
    const [supplier, setSupplier] = useState('')
    const [expenseData, setExpenseData] = useState([])
    const [modalDetail, setModalDetail] = useState(false)
    const [selectedExpense, setSelectedExpense] = useState(null)
    
    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])


    const ictCategory = [
        { key: 0, text: 'Desktop Computer', value: 'Desktop Computer' },
        { key: 1, text: 'Laptop', value: 'Laptop' },
        { key: 2, text: 'Tablet Computer', value: 'Tablet Computer' },
        { key: 3, text: 'Printer', value: 'Printer' },
        { key: 4, text: 'Peripheral', value: 'Peripheral' },
        { key: 5, text: 'Laptop', value: 'Laptop' },
        { key: 6, text: 'Service Fee', value: 'Service Fee' }
    ]

    const collumnEntry = useMemo(() => [
        {
            Header:'Trx Number',
            accessor:'header.trx_number'
        },
        {
            Header:'Date',
            accessor:'header.trx_date'
        },
        {
            Header:'Invoice',
            accessor:'header.inv_number'
        },
        {
            Header:'Category',
            accessor:'header.category'
        },
        {
            Header:'Description',
            accessor:'header.description',
            Cell:({cell}) => (
                <div style={{width:'100px'}}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    }/>
                </div>
            )
        },
        {
            Header:'Total Qty',
            accessor:'header.total_qty',
            Cell:({cell}) => (curencyNoSym.format(cell.value))
        },
        {
            Header:'Total',
            accessor:'header.total',
            Cell:({cell}) => (curencyNoSym.format(cell.value))
        },
        {
            Header:'Discount',
            accessor:'header.total_discount',
            Cell:({cell}) => (curencyNoSym.format(cell.value))
        },
        {
            Header:'Grand Total',
            accessor:'header.grand_total',
            Cell:({cell}) => (curencyNoSym.format(cell.value))
        },
        {
            Header:'Create By',
            accessor:'header.user_name',
            Cell:({cell}) => (
                <Stack direction="vertical" style={{lineHeight:'0.9em'}}>
                    <div>{cell.value}</div>
                    <div className="text-muted">{cell.row.original.header.role_name}</div>
                </Stack>
            )
        },
        {
            Header:'Action',
            accessor:null,
            Cell:({cell}) => (
                <Button.Group basic size="mini">
                    <Popup content="View Detail" size="tiny" trigger={
                        <Button icon={{name:"list layout", color:"blue"}} onClick={() => handleClickDetail(cell.row.original)}/>
                    }/>
                </Button.Group>
            )
        },
    ])

    const columnDetail = useMemo(() => [
        {
            Header:'Item',
            accessor:'item'
        },
        {
            Header:'Qty',
            accessor:'qty'
        },
        {
            Header:'Price',
            accessor:'price',
            Cell:({cell}) => (curencyNoSym.format(cell.value))
        },
        {
            Header:'Discount',
            accessor:'discount',
            Cell:({cell}) => {
                if (cell.row.original.dsc_type === 'percent') {
                    return <span>{cell.value}%</span>
                } else if (cell.row.original.dsc_type === 'nominal'){
                    return <span>{curencyNoSym.format(cell.value)}</span>
                }
            }
        },
        {
            Header:'Dsc',
            accessor :'dsc',
            Cell:({cell}) => (curencyNoSym.format(cell.value))
        },
        {
            Header:'Dsc Description',
            accessor :'dsc_description'
        },
        {
            Header:'Total',
            accessor:'total',
            Cell:({cell}) => (curencyNoSym.format(cell.value))
        },
    ])

    const handleClickDetail = (data) => {
        setSelectedExpense(data)
        setModalDetail(true)
    }

    // Fungsi untuk mengedit nilai di sel
    const handleEdit = (index, field, value, numeric) => {
        const updatedData = [...itemData]
        updatedData[index][field] = value

        if (field === 'qty' || field === 'price') {
            const qty = parseFloat(updatedData[index].qty) || 0
            const price = parseFloat(updatedData[index].price) || 0
            const dsc = parseFloat(updatedData[index].dsc) || 0
            const discount = parseFloat(dsc) * (price * qty) / 100

            updatedData[index].total = (qty * price) - discount
            updatedData[index].discount = dsc * (qty * price) / 100
        }

        setItemData(updatedData)
    }

    // Fungsi untuk menambahkan baris baru
    const handleAddRow = () => {
        setItemData([...itemData, { ...newRow, id: itemData.length + 1 }])
        setNewRow({ item: '', qty: 1, price: 0, discount: 0, dsc: 0, total: 0 }) // Reset input form
        setIsNewRowAdded(true); // Set flag bahwa baris baru ditambahkan
    }

    const handleClickCreate = () => {
        setModalEntry(true)
    }

    const handleRemoveRow = (index) => {
        const updatedData = [...itemData]
        updatedData.splice(index, 1) // Hapus baris berdasarkan index
        setItemData(updatedData)
    }

    const handleAddDsc = (item, index) => {
        setSelectedRow(item)
        setModalDiscount(true)
    }

    const handleDscSubmit = () => {
        const updatedData = itemData.map((item) => {
            if (dscType === 'percent') {
                const dscAmount = dscValue * (parseFloat(item.price) * parseInt(item.qty)) / 100
                if (item.id === selectedRow.id) {
                    return {
                        ...item,
                        discount: dscAmount,
                        dsc: dscValue,
                        dsc_description: dscDesc,
                        total: (item.qty * item.price) - dscAmount,
                        dsc_type:'percent'
                    }
                }
            } else if (dscType === 'nominal') {
                const dscAmount = dscValue
                const currentTotal = selectedRow.qty * selectedRow.price

                if ((parseFloat(currentTotal) - parseFloat(dscValue)) <= 0) {
                    Toaster.fire({
                        icon: 'warning',
                        text: 'Discount is to high',
                        position: 'top-right'
                    })
                } else {
                    if (item.id === selectedRow.id) {
                        return {
                            ...item,
                            discount: dscAmount,
                            dsc: dscValue,
                            dsc_description: dscDesc,
                            total: (item.qty * item.price) - dscAmount,
                            dsc_type:'nominal'
                        }
                    }
                }
            }

            return item
        })

        setItemData(updatedData)
        setModalDiscount(false)
    }

    const clearForm = () => {
        setTrxDate(moment().format('YYYY-MM-DD'))
        setTrxCat('')
        setTrxDesc('')
        setTrxInv('')
        setSupplier('')
        setTotal(0)
        setGrandTotal(0)
        setTotalQty(0)
        setDscDesc('')
        setDscType('')
        setDscValue(0)
        setItemData([])
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const handleSubmit = async () => {
        const header = {
            trx_date: trxDate,
            category: trxCat,
            inv_number: trxInv,
            description: trxDesc,
            total_qty: totalQty,
            total: total,
            total_discount: totalDsc,
            grand_total: grandTotal,
            supplier: supplier,
            id_user: userProfile.profile.id_user,
        }

        const detail = itemData
        console.log(detail)
        await axios({
            method: 'POST',
            url: `${host}/ict/index.php?action=createExpense`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            },
            data: {
                header: header,
                detail: detail,
            }
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success create transaction',
                    position: 'top-right'
                })
                clearForm()
                setModalEntry(false)
                getExpenseData()
            } else {
                Toaster.fire({
                    icon: 'warning',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const getExpenseData = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRange.map(date => formatDate(date));
        const date_range = [startDate, endDate];

        await axios({
            method: 'GET',
            url: `${host}/ict/index.php?action=ictExpense`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            },
            params:{
                date_range : date_range
            }
        }).then (res => {
            if (res.data.status === 200){ 
                setExpenseData(res.data.data)
            }
        })
    }
 
    useEffect(() => {
        if (isNewRowAdded && nameInputRef.current) {
            nameInputRef.current.focus();
            setIsNewRowAdded(false); // Reset flag setelah fokus diberikan
        }
    }, [isNewRowAdded])

    useEffect(() => {
        setTotalQty(itemData.reduce((total, item) => total + parseInt(item.qty), 0))
        setTotal(itemData.reduce((total, item) => total + parseFloat(item.price), 0))
        setTotalDsc(itemData.reduce((total, item) => total + parseFloat(item.discount), 0))
        setGrandTotal(itemData.reduce((total, item) => total + parseFloat(item.total), 0))
    }, [itemData])

    useEffect(() => {
        getExpenseData()
    }, [dateRange])

    useEffect(() => {
        getExpenseData()
    }, [])
    
    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">ICT Expenditure Entry</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={5} lg={5} md={6} sm={6} className="mb-3">
                        <Button content="Create New Entry" icon="add" color="blue" onClick={handleClickCreate} />
                    </Col>
                    <Col xl={5} lg={5} md={6} sm={6} className="mb-3 text-end">
                        <DateRangePicker
                            label="Date Range : "
                            format="yyyy-MM-dd"
                            placement="auto"
                            cleanable={true}
                            defaultValue={dateRange}
                            value={dateRange}
                            onChange={handleChangeDate}
                            onClean={() => setDateRange([
                                new Date(moment().startOf('month')),
                                new Date(moment()),
                            ])}
                        />
                    </Col>
                    <Col lxl={10} lg={10} md={12} sm={12}>
                        <Reacttable
                            data={expenseData}
                            columns={collumnEntry}
                            pageRow={10}
                            totals={['header.grand_total', 'header.total_qty']}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalEntry} onClose={() => setModalEntry(false)} overflow={false} backdrop="static" size="lg">
                <Modal.Header>
                    <Modal.Title>ICT Expenditure Entry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            <Form id="frmEntry" onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Input type="date" label="Transaction Date" value={trxDate} onChange={(e) => setTrxDate(e.target.value)} required />
                                    <FormSelect
                                        required
                                        options={ictCategory}
                                        label="Category"
                                        search
                                        value={trxCat}
                                        onChange={(e, { value }) => setTrxCat(value)}
                                    />
                                    <Form.Input type="text" label="Invoice Number" value={trxInv} onChange={(e) => setTrxInv(e.target.value)} required />
                                    <Form.Input type="text" label="Supplier/Store" width={10} value={supplier} onChange={(e) => setSupplier(e.target.value)} />
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col xl={12} lg={12} md={12}>
                            <div className="mb-2">
                                <span className="h5">Item Details</span>
                            </div>
                            <Table className="table table-hover table-bordered">
                                <thead className="bg-light" style={{ fontSize: '0.9em' }}>
                                    <tr>
                                        <th>#</th>
                                        <th>Item</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Discount</th>
                                        <th>Total</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: '0.9em' }}>
                                    {
                                        itemData.map((row, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td >
                                                    <input
                                                        type="text"
                                                        value={row.item}
                                                        onChange={(e) => handleEdit(index, 'item', e.target.value, false)}
                                                        ref={nameInputRef}
                                                        style={{ border: 'none', outline: 'none', padding: 2, width: '100%', borderRadius: '3px', fontFamily: 'inherit' }}
                                                        placeholder="Item description here"
                                                    />
                                                </td>
                                                <td style={{ width: '70px' }}>
                                                    <input
                                                        type="text"
                                                        value={row.qty}
                                                        onChange={(e) => handleEdit(index, 'qty', e.target.value, true)}
                                                        style={{ border: 'none', outline: 'none', width: '100%', padding: 2, borderRadius: '3px', textAlign: 'right', fontFamily: 'inherit' }}
                                                    />
                                                </td>
                                                <td style={{ width: '120px' }}>
                                                    <input
                                                        type="text"
                                                        value={row.price}
                                                        onChange={(e) => handleEdit(index, 'price', e.target.value, true)}
                                                        style={{ border: 'none', outline: 'none', width: '100%', padding: 2, borderRadius: '3px', textAlign: 'right', fontFamily: 'inherit' }}
                                                    />
                                                </td>
                                                <td style={{ width: '120px', textAlign: 'right' }}>
                                                    <span className="me-1">{row.discount}</span>
                                                    <span>
                                                        <Popup content="Add discount" size="tiny" trigger={
                                                            <Icon name="plus circle" color="blue" style={{ cursor: 'pointer' }} onClick={() => handleAddDsc(row, index)} />}
                                                        />
                                                    </span>
                                                </td>
                                                <td style={{ width: '120px', textAlign: 'right' }}>
                                                    <span className="me-2">{row.total}</span>
                                                    <Popup content={row.dsc_description} size="tiny" trigger={
                                                        <span className="me-2" style={{ color: row.discount == 0 ? 'lightgrey' : 'coral' }}>(-{row.dsc}{dscType === 'percent' ? '%' : ''})</span>}
                                                    />
                                                </td>
                                                <td className="text-center" style={{ width: '50px' }}>
                                                    <Popup content="Remove this item" size="tiny" trigger={
                                                        <Icon name="close" color="orange" style={{ cursor: 'pointer' }} onClick={() => handleRemoveRow(index)} />}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    <tr>
                                        <td colSpan={7} onClick={handleAddRow} style={{ cursor: 'pointer' }} className="text-center fw-bold">
                                            <span><Icon name="add" color="blue" /></span>
                                            <span>Add New Row</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col xl={8} lg={8} md={8}>
                            <Form>
                                <label className="fw-bold">Description</label><br />
                                <TextArea style={{ width: '300px' }} maxLength={100} value={trxDesc} onChange={(e) => setTrxDesc(e.target.value)} /><br />
                                <small>Maximum 100 Chars</small>
                            </Form>
                        </Col>
                        <Col xl={4} lg={4} md={4}>
                            <Table className="table table-borderless table-sm">
                                <tbody className="text-end">
                                    <tr>
                                        <td>Total Qty : </td>
                                        <td className="fw-bold">{curencyNoSym.format(totalQty)}</td>
                                    </tr>
                                    <tr>
                                        <td>Total : </td>
                                        <td className="fw-bold">{curency.format(total)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'coral' }}>Total Discount : </td>
                                        <td className="fw-bold" style={{ color: 'coral' }}>{curency.format(totalDsc)}</td>
                                    </tr>
                                    <tr style={{ borderTop: '2px dashed grey' }}>
                                        <td>Grand Total : </td>
                                        <td className="fw-bold">{curency.format(grandTotal)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" color="blue" type="submit" form="frmEntry" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalDiscount} onClose={() => setModalDiscount(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Additional Discount</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmDsc" onSubmit={handleDscSubmit}>
                        <Form.Group>
                            <Form.Radio
                                label="Percent"
                                value="percent"
                                checked={dscType === "percent"}
                                onChange={(e, { value }) => setDscType(value)}
                            />
                            <Form.Radio
                                label="Nominal"
                                value="nominal"
                                checked={dscType === "nominal"}
                                onChange={(e, { value }) => setDscType(value)}
                            />
                        </Form.Group>
                        <Form.Group grouped>
                            <FormInput type="text" label="Discount Value" maxLength={dscType === 'percent' && 2} value={dscValue} onChange={(e) => setDscValue(e.target.value)} required disabled={dscType !== '' ? false : true} />
                            <FormInput type="text" label="Description" value={dscDesc} onChange={(e) => setDscDesc(e.target.value)} disabled={dscType !== '' ? false : true} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Add Discount" icon="plus" type="submit" form="frmDsc" color="blue" disabled={dscType !== '' ? false : true} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalDetail} onClose={() => setModalDetail(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Expense Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable
                        data={selectedExpense?.detail}
                        columns={columnDetail}
                        pageRow={10}
                        totals= {['total']}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
} 
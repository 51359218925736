import React, { useEffect, useMemo, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useMyGlobal } from "../component/Global";
import axios from "axios";
import Modal from "rsuite/Modal";
import { Button, Dimmer, Form, Loader, Popup } from "semantic-ui-react";
import { curencyNoSym, currentDate } from "../component/utils";
import { Reacttable } from "../component/Reacttable";
import Table from "react-bootstrap/Table";
import InlineEdit from 'rsuite/InlineEdit';
import Stack from "react-bootstrap/Stack";
import { Toaster } from "../component/Toaster";
import DateRangePicker from 'rsuite/DateRangePicker';
import moment from "moment";

export const StockTransfer = () => {
    const { host, userProfile } = useMyGlobal()
    const [modalAddTranster, setModalAddTransfer] = useState(false)
    const [modalListArticle, setModalListArticle] = useState(false)
    const [modalDetail, setModalDetail] = useState(false)
    const [modalChangeQty, setModalChangeQty] = useState(false)
    const [dataStockTranser, setDataStockTransfer] = useState([])
    const [dataWarehouseOpt, setDataWarehouseOpt] = useState([])
    const [stockByWarehouse, setStockByWarehouse] = useState([])
    const [deliverDate, setDeliverDate] = useState(currentDate)
    const [dateTrans, setDateTrans] = useState(currentDate)
    const [whFrom, setWhFrom] = useState('')
    const [whDest, setWhDest] = useState('')
    const [description, setDescription] = useState('')
    const [selectedArticle, setSelectedArticle] = useState([])
    const [selectedQty, setSelectedQty] = useState(null)
    const tableRef = useRef()
    const [newQty, setNewQty] = useState(0)
    const [searchValue, setSearchValue] = useState('')
    const [loadCreate, setLoadCreate] = useState(false)
    const [stockTransData, setStockTransData] = useState([])
    const [selectedTransStock, setSelectedTransStock] = useState(null)
    const [initSel, setInitSel] = useState([])
    const [loadStock, setLoadStock] = useState(false)
    const [loadGenerate, setLoadGenerate] = useState(false)

    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 6

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = selectedArticle.slice(startIndex, endIndex);

    const totalPages = Math.ceil(selectedArticle.length / itemsPerPage);

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    }

    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    }

    const handleSearch = (value) => {
        setSearchValue(value)
        const key = value ? value : ''
        if (key) {
            const flt = selectedArticle.filter(item =>
                item.article.toLowerCase().includes(key.toLowerCase())
            )
            setSelectedArticle(flt.length > 0 ? flt : initSel);
        } else {
            setSelectedArticle(initSel);
        }
    }

    const columnTransfer = useMemo(() => [
        {
            Header: "Trans. Number",
            accessor: "header.transfer_number"
        },
        {
            Header: "Trans. Date",
            accessor: "header.transfer_date"
        },
        {
            Header: "Deliver Date",
            accessor: "header.delivery_date"
        },
        {
            Header: "Origin",
            accessor: "header.warehouse_from"
        },
        {
            Header: "Destination",
            accessor: "header.warehouse_dest"
        },
        {
            Header: "User",
            accessor: "header.user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '0.8em' }}>{cell.row.original.header.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group size="mini" basic>
                    <Popup content="View Detail" size="tiny" trigger={
                        <Button icon={{ name: 'list layout', color: 'blue' }} onClick={() => handleClickDetail(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const columnDetail = useMemo(() => [
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Barcode",
            accessor: "barcode"
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Qty",
            accessor: "qty"
        },
    ])

    const handleClickDetail = (data) => {
        setSelectedTransStock(data)
        setModalDetail(true)
    }

    const columnsArt = useMemo(() => [
        {
            Header: "Brand",
            accessor: "brand_code"
        },
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Barcode",
            accessor: "barcode"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '200px' }}>
                    <div className="text-truncate">{cell.value}</div>
                </div>
            )
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Qty",
            accessor: "qty"
        },
    ])

    const handleClickAddTrans = () => {
        setModalAddTransfer(true)
    }

    const getStockTransfer = async () => {
        setLoadGenerate(true)
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/stock/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params:{
                range:  date_range,
                action: 'stockTransfer'
            }
        }).then(res => {
            if (res.data.status === 200) {
                setStockTransData(res.data.data)
            }
        }).finally(() => setLoadGenerate(false))
    }

    const getWarehouseData = async () => {
        await axios({
            method: "GET",
            url: `${host}/showroom/index.php?action=warehouse`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataWarehouseOpt(res.data.data.map(i => {
                    return { key: i.id_warehouse, text: i.desc, value: i.id_warehouse }
                }))
            }
        })
    }

    const getStockByWarehouse = async (wh) => {
        setLoadStock(true)
        await axios({
            method: "GET",
            url: `${host}/stock/index.php?action=stockByWarehouse&id_warehouse=${wh}`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setStockByWarehouse(res.data.data)
            }
        }).finally(() => setLoadStock(false))
    }

    const handleChangeFrom = (value) => {
        setWhFrom(value)
    }

    const handleChangeDestination = (value) => {
        setWhDest(value)
    }

    const handleClickLookup = () => {
        if (whFrom !== '') {
            getStockByWarehouse(whFrom)
            setModalListArticle(true)
        } else {
            Toaster.fire({
                icon: 'warning',
                text: 'First select the warehouse of origin',
                position: 'top-right'
            })
        }
    }

    const handleGetSelected = () => {
        if (tableRef.current) {
            const selectedRows = tableRef.current.getSelectedRows()
            const withKeyTransferQty = selectedRows.map(i => { return { ...i, qty_transfer: 0 } })
            setSelectedArticle(prevState => [
                ...prevState,     // Menjaga data lama
                ...withKeyTransferQty  // Menambah data baru
            ])
            setInitSel(withKeyTransferQty)
            setModalListArticle(false)
        }
    }

    const handleCloseModalArticle = () => {
        setModalListArticle(false)
        // setSelectedArticle([])
        // setDataStockTransfer([])
    }

    const handleCloseModalChangeQty = () => {
        setModalChangeQty(false)
    }

    const handleClickChangeQty = (data) => {
        setSelectedQty(data)
        setModalChangeQty(true)
    }

    const handleSubmitNewQty = () => {
        const transQty = parseInt(newQty)
        const availableQty = parseInt(selectedQty?.qty)

        if (availableQty >= transQty && transQty > 0) {
            const selectArtUpdate = selectedArticle.map((item, i) => {
                if (item.article === selectedQty.article && item.size === selectedQty.size) {
                    return { ...item, qty_transfer: transQty }
                }
                return item
            })
            setSelectedArticle(selectArtUpdate)
            setModalChangeQty(false)
            setNewQty(0)
        } else if (transQty === 0) {
            Toaster.fire({
                icon: 'warning',
                text: "Transfer Quantity can't be 0",
                position: 'top-right'
            })
        } else {
            Toaster.fire({
                icon: 'warning',
                text: 'Transfer Quantity exceeds the stock amount',
                position: 'top-right'
            })
        }
    }

    const clearState = () => {
        setDeliverDate(currentDate)
        setDateTrans(currentDate)
        setWhFrom('')
        setWhDest('')
        setDescription('')
        setSelectedArticle([])
        setNewQty(0)
    }

    const handleSubmit = () => {
        const cfm = window.confirm("Are you sure, have you check everything?")
        if (cfm) {
            const isQtyNol = selectedArticle.find(i => i.qty_transfer == 0)
            if (selectedArticle.length > 0 && whDest !== '' && whFrom !== '' && whFrom !== whDest && deliverDate !== '' && !isQtyNol) {
                setLoadCreate(true)
                const param = new FormData()
                const header = {
                    transfer_date: dateTrans,
                    delivery_date: deliverDate,
                    from: whFrom,
                    destination: whDest,
                    description: description,
                    total_qty: selectedArticle.reduce((a, b) => a + parseInt(b.qty_transfer), 0),
                    id_user: userProfile.profile.id_user,
                }
                const detail = selectedArticle
                param.append('header', JSON.stringify(header))
                param.append('detail', JSON.stringify(detail))

                axios({
                    method: "POST",
                    url: `${host}/stock/index.php?action=createStockTransfer`,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                    },
                    data: param
                }).then(res => {
                    if (res.data.status === 200) {
                        Toaster.fire({
                            icon: 'success',
                            text: 'Success create transfer stock',
                            position: 'top-right'
                        })
                    } else {
                        Toaster.fire({
                            icon: 'error',
                            text: 'Failed create transfer stock',
                            position: 'top-right'
                        })
                    }
                }).finally(() => {
                    setLoadCreate(false)
                    setModalAddTransfer(false)
                    clearState()
                    getStockTransfer()
                })
            } else if (whDest === '') {
                Toaster.fire({
                    icon: 'warning',
                    text: 'Please select destination warehouse',
                    position: 'top-right'
                })
            } else if (whFrom === '') {
                Toaster.fire({
                    icon: 'warning',
                    text: 'Please select origin warehouse',
                    position: 'top-right'
                })
            } else if (selectedArticle.length === 0) {
                Toaster.fire({
                    icon: 'warning',
                    text: 'Please select one of the articles to be transferred',
                    position: 'top-right'
                })
            } else if (whFrom === whDest) {
                Toaster.fire({
                    icon: 'error',
                    text: 'The origin warehouse and destination warehouse cannot be the same',
                    position: 'top-right'
                })
            } else if (isQtyNol) {
                Toaster.fire({
                    icon: 'warning',
                    text: "There's quantity for transfer with 0 value",
                    position: 'top-right'
                })
            }
        }
    }

    const handleRemoveItem = (item) => {
        const filtered = selectedArticle.filter(i => i.article !== item.article && i.size !== item.size)
        setSelectedArticle(filtered)
    }

    const handleGenerateData = () => {
        getStockTransfer(dateRange)
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    useEffect(() => {
        getWarehouseData()
        getStockTransfer(dateRange)
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Stock Transfer</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={5} lg={4} md={4} sm={12} className="mb-3">
                        <Button content="Create Stock Transfer" icon="add" onClick={handleClickAddTrans} color="blue" />
                    </Col>
                    <Col xl={5} lg={8} md={8} xs={12} className="mb-3 text-end">
                        <DateRangePicker
                            style={{ width: '60%' }}
                            label="Date Range : "
                            format="yyyy-MM-dd"
                            placement="auto"
                            cleanable={true}
                            defaultValue={dateRange}
                            value={dateRange}
                            onChange={handleChangeDate}
                            onClean={() => setDateRange([
                                new Date(moment().startOf('month')),
                                new Date(moment()),
                            ])}
                        />
                        <Button content="Generate Data" loading={loadGenerate} disabled={loadGenerate} className="ms-2" style={{ paddingTop: '10px' }} color="blue" onClick={() => handleGenerateData()} />
                    </Col>
                    <Col xl={10} lg={12} md={12} sm={12}>
                        <Reacttable data={stockTransData} columns={columnTransfer} pageRow={0} noData={stockTransData.length === 0 ? true : false} />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAddTranster} onClose={() => setModalAddTransfer(false)} overflow={false} backdrop="static" size="lg">
                <Modal.Header>
                    <Modal.Title>Create Stock Transfer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12} md={12}>
                            <Form id="formTrans" onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Input type="date" label="Transfer Date" value={dateTrans} onChange={(e) => setDateTrans(e.target.value)} width={4} required />
                                    <Form.Input type="date" label="Delivery Date" value={deliverDate} onChange={(e) => setDeliverDate(e.target.value)} width={4} required />
                                    <Form.Dropdown
                                        selection
                                        options={dataWarehouseOpt}
                                        label="From"
                                        search
                                        required
                                        width={4}
                                        value={whFrom}
                                        onChange={(e, { value }) => handleChangeFrom(value)}
                                    />
                                    <Form.Dropdown
                                        selection
                                        options={dataWarehouseOpt}
                                        label="Destination"
                                        search
                                        required
                                        width={4}
                                        value={whDest}
                                        onChange={(e, { value }) => handleChangeDestination(value)}
                                    />
                                    <Form.Input type="text" label="Description" width={6} value={description} onChange={(e) => setDescription(e.target.value)} />
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col lg={6} className="mb-3">
                            <Button content="Lookup for Article" size="mini" icon="zoom" onClick={() => handleClickLookup()} color="orange" />
                        </Col>
                        <Col lg={6} className="d-flex justify-content-end">
                            <Form>
                                <Form.Input
                                    size="mini"
                                    icon="search"
                                    placeholder="Search Article"
                                    type="text"
                                    value={searchValue}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />
                            </Form>
                        </Col>
                        <Col lg={12}>
                            <Table className="table">
                                <thead style={{ fontSize: '0.9em' }}>
                                    <tr>
                                        <th>#</th>
                                        <th>Brand</th>
                                        <th>Article</th>
                                        <th>Barcode</th>
                                        <th>Description</th>
                                        <th>Size</th>
                                        <th>Price</th>
                                        <th>Avail.</th>
                                        <th>Trf.</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: '0.8em' }} className="align-middle">
                                    {
                                        currentData?.map((item, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{item.brand_code}</td>
                                                <td>{item.article}</td>
                                                <td>{item.barcode}</td>
                                                <td >
                                                    <div style={{ width: '250px' }}>
                                                        <div className="text-truncate">
                                                            {item.description}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{item.size}</td>
                                                <td>{curencyNoSym.format(item.price)}</td>
                                                <td>{item.qty}</td>
                                                <td>{item.qty_transfer}</td>
                                                <td>
                                                    <Button.Group size="mini" basic>
                                                        <Popup content="Add Transfered Quantity" size="tiny" trigger={
                                                            <Button icon="plus" onClick={() => handleClickChangeQty(item)} />
                                                        } />
                                                        <Popup content="Remove This item" size="tiny" trigger={
                                                            <Button icon="close" onClick={() => handleRemoveItem(item)} />
                                                        } />
                                                    </Button.Group>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot style={{ fontSize: '0.9em', fontWeight: 'bold' }}>
                                    <tr>
                                        <td colSpan={6}>Total</td>
                                        <td>{curencyNoSym.format(selectedArticle.reduce((a, b) => a + parseFloat(b.price), 0))}</td>
                                        <td>{selectedArticle.reduce((a, b) => a + parseInt(b.qty), 0)}</td>
                                        <td>{selectedArticle.reduce((a, b) => a + parseInt(b.qty_transfer), 0)}</td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                        <Col>
                            <div>
                                <Button className="me-3" icon="backward" size="mini" compact onClick={prevPage} disabled={currentPage === 0} />
                                <span>Page {currentPage + 1} of {totalPages}</span>
                                <Button className="ms-3" icon="forward" size="mini" compact onClick={nextPage} disabled={currentPage === totalPages - 1} />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit Transfer" color="blue" icon="arrow alternate circle right outline" labelPosition="right" type="submit" form="formTrans" loading={loadCreate} disabled={loadCreate} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalListArticle} onClose={handleCloseModalArticle} overflow={false} backdrop="static" size="md">
                <Modal.Header>
                    <Modal.Title>List of Article</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dimmer active={loadStock} inverted>
                        <Loader inverted>Loading...</Loader>
                    </Dimmer>
                    <Reacttable data={stockByWarehouse.filter(item => !selectedArticle.some(exc => exc.barcode === item.barcode))} columns={columnsArt} pageRow={10} checkbox ref={tableRef} />
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Confirm Selected Item's" onClick={handleGetSelected} icon="arrow alternate circle right outline" labelPosition="right" color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalChangeQty} onClose={handleCloseModalChangeQty} overflow={false} size="xs" backdrop="static" children>
                <Modal.Header>
                    <Modal.Title>Change Transfer Quantity</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: '0.9em' }}>
                    <Row>
                        <Col lg={12}>
                            <Stack direction="horizontal" gap={1}>
                                <Stack direction="vertical" gap={0}>
                                    <div>Article</div>
                                    <div>Size</div>
                                    <div>Available Qty</div>
                                </Stack>
                                <Stack direction="vertical" gap={0} className="fw-bold">
                                    <div>: {selectedQty?.article}</div>
                                    <div>: {selectedQty?.size}</div>
                                    <div>: {selectedQty?.qty}</div>
                                </Stack>
                            </Stack>
                        </Col>
                        <Col lg={12}>
                            <Form id="formChangeQty" onSubmit={handleSubmitNewQty}>
                                <Form.Group grouped>
                                    <Form.Input type="number" label="New Quantity" value={newQty} onChange={(e) => setNewQty(e.target.value)} />
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Save" icon="save outline" form="formChangeQty" type="submit" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalDetail} onClose={() => setModalDetail(false)} overflow={false} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Stock Transfer Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Row>
                        <Col lg={12} className="mb-3" style={{ fontSize: '0.9em' }}>
                            <Stack direction="horizontal" gap={1}>
                                <Stack direction="vertical" gap={0}>
                                    <div>Transfer Number</div>
                                    <div>Total Qty</div>
                                    <div>Origin</div>
                                    <div>Destination</div>
                                </Stack>
                                <Stack direction="vertical" gap={0} className="fw-bold">
                                    <div>: <span className="me-2">{selectedTransStock?.header?.transfer_number}</span><span>({selectedTransStock?.header?.transfer_date})</span></div>
                                    <div>: {selectedTransStock?.header?.total_qty}</div>
                                    <div>: {selectedTransStock?.header?.warehouse_from}</div>
                                    <div>: {selectedTransStock?.header?.warehouse_dest}</div>
                                </Stack>
                            </Stack>
                        </Col>
                        <Col lg={12}>
                            <Reacttable data={selectedTransStock?.detail} columns={columnDetail} pageRow={10} />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useMyGlobal } from "../../component/Global";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Stack from "react-bootstrap/Stack"
import { Button, Checkbox, Form, FormGroup, FormInput, FormRadio, FormSelect, Icon, Label, Popup } from "semantic-ui-react";
import Modal from "rsuite/Modal";
import { capitalize } from "../../component/utils";
import { Toaster } from "../../component/Toaster";
import { Reacttable } from "../../component/Reacttable";

export const MasterSalesman = () => {
    const [modalCreate, setModalCreate] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const { host, userProfile, cityOpt, provOpt } = useMyGlobal();
    const [dataSalesman, setDataSalesman] = useState([])
    const [salesName, setSalesName] = useState('')
    const [salesTarget, setSalesTarget] = useState('')
    const [comision, setComision] = useState('')
    const [description, setDescription] = useState('')
    const [idSalesman, setIdSalesman] = useState('')

    const columnsSalesman = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_salesman"
        },
        {
            Header: "Sales Name",
            accessor: "sales_name"
        },
        {
            Header: "Target",
            accessor: "target"
        },
        {
            Header: "Commission",
            accessor: "commision"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "User",
            accessor: "user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '0.8em' }}>{cell.row.original.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Create",
            accessor: "createAt"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup size="tiny" content="Edit" trigger={
                        <Button icon={{ name: 'edit', color: 'blue' }} onClick={() => handleClickEdit(cell.row.original)} />
                    } />
                    <Popup size="tiny" content="Delete" trigger={
                        <Button icon={{ name: 'trash alternate', color: 'blue' }} onClick={() => handleClickDelete(cell.row.original.id_salesman)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const handleClickEdit = (row) => { 
        setIdSalesman(row.id_salesman)
        setModalEdit(true)
        setSalesName(row.sales_name)
        setSalesTarget(row.target)
        setComision(row.commision)
        setDescription(row.description)
    }

    const handleClickDelete = (id) => {
        let cfm = window.confirm("Are you sure ?")

        if (cfm){
            axios({
                method: "DELETE",
                url: `${host}/directsales/index.php?action=deleteSalesman`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: {
                    id_salesman:id
                }
            }).then (res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: res.data.msg,
                        position: 'top-right',
                    })
                    salesman()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right',
                    })
                }
            })
        }
    }

    const handleClickAdd = () => {
        setModalCreate(true)
        clearForm()
    }

    const clearForm = () => {
        setSalesName('')
        setSalesTarget('')
        setComision('')
        setDescription('')
    }

    const handleSubmitEdit = () => {
        const data = {
            id_user:userProfile.profile.id_user,
            id_salesman:idSalesman,
            sales_name:salesName,
            target:salesTarget,
            commision:comision,
            desc:description,
        }

        axios({
            method: "PUT",
            url: `${host}/directsales/index.php?action=editSalesman`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: data
        }).then (res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: res.data.msg,
                    position: 'top-right',
                })
                clearForm()
                setModalEdit(false)
                salesman()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right',
                })
            }
        })
    }

    const handleSubmit = () => {
        const param = new FormData()

        param.append('id_user', userProfile.profile.id_user)
        param.append('sales_name', salesName)
        param.append('target', salesTarget)
        param.append('comision', comision)
        param.append('desc', description)

        axios({
            method: "POST",
            url: `${host}/directsales/index.php?action=createSalesman`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: res.data.msg,
                    position: 'top-right',
                })
                setModalCreate(false)
                clearForm()
                salesman()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right',
                })
            }
        })
    }

    const salesman = () => {
        axios({
            method: "GET",
            url: `${host}/directsales/index.php?action=salesman`,
            headers:{
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataSalesman(res.data.data)
            }
        })
    }

    useEffect(() => {
        salesman()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Master Salesman</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={10} lg={12} md={12} className="mb-3">
                        <Button content="Add New Salesman" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={10} lg={12} md={12}>
                        <Reacttable data={dataSalesman} columns={columnsSalesman} pageRow={10} />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalCreate} onClose={() => setModalCreate(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Create New Salesman</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmCreate" onSubmit={handleSubmit}>
                        <Form.Group grouped>
                            <FormInput label="Sales Name" type="text" maxLength="35" value={salesName} onChange={(e, { value }) => setSalesName(value)} required />
                            <FormInput label="Target" type="text" maxLength="35" value={salesTarget} onChange={(e, { value }) => setSalesTarget(value)} />
                            <FormInput label="Commission" type="text" maxLength="30" value={comision} onChange={(e, { value }) => setComision(value)} />
                            <FormInput label="Description" type="text" maxLength="50" value={description} onChange={(e, { value }) => setDescription(value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit New Salesman" type="submit" form="frmCreate" color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Salesman</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                        <Form.Group grouped>
                            <FormInput label="Sales Name" type="text" maxLength="35" value={salesName} onChange={(e, { value }) => setSalesName(value)} required />
                            <FormInput label="Target" type="text" maxLength="35" value={salesTarget} onChange={(e, { value }) => setSalesTarget(value)} />
                            <FormInput label="Commission" type="text" maxLength="30" value={comision} onChange={(e, { value }) => setComision(value)} />
                            <FormInput label="Description" type="text" maxLength="50" value={description} onChange={(e, { value }) => setDescription(value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit edit Salesman" type="submit" form="frmEdit" color="blue" />
                </Modal.Footer>
            </Modal>
        </>
    )
}
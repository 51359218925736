import React, { useEffect, useState } from "react";
import 'react-pro-sidebar/dist/css/styles.css';
import { Menu, MenuItem, SubMenu, ProSidebar, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar'
import { Link } from "react-router-dom";
import { BsBagCheck, BsBagPlus, BsBank2, BsBarChart, BsBox, BsBox2, BsCart, BsCashCoin, BsChevronDoubleLeft, BsChevronDoubleRight, BsClipboardData, BsGear, BsGift, BsGraphUp, BsHouse, BsKeyboard, BsList, BsPower } from "react-icons/bs";
import Cookies from "js-cookie";
import { Button, Icon, MenuHeader, Popup } from "semantic-ui-react";
import Stack from "react-bootstrap/Stack";
import axios from "axios";
import { useMyGlobal } from "./Global";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logoJfs from "../asset/javafootwear.png";
import { Image } from "react-bootstrap";

export const SidebarComponent = ({ collaps }) => {
    const [collapsed, setCollapsed] = useState(false)
    const [userProf, setUserProf] = useState(null)
    const { host } = useMyGlobal()

    const handleCollapsed = () => {
        setCollapsed(!collapsed)
    }

    const handleLogout = () => {
        Cookies.remove('_erpLog')
        updateStatusLog(userProf.profile.id_user, 'offline')
        window.location.reload()
    }

    const updateStatusLog = (id, status) => {
        const param = {
            id_user: id,
            status: status
        }
        axios({
            method: 'PUT',
            url: `${host}/log/index.php?action=updateStatusLog`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            },
            data: param
        })
    }

    useEffect(() => {
        setCollapsed(collaps);
    }, [collaps]);


    useEffect(() => {
        setUserProf(JSON.parse(Cookies.get('_erpLog')))
    }, [])

    return (
        <>
            <ProSidebar
                collapsed={collapsed}
                style={{ height: '100vh' }}
            >
                <SidebarHeader>
                    <Menu iconShape="circle">
                        {collapsed ? (
                            <MenuItem
                                icon={<BsList />}
                                onClick={handleCollapsed}
                            ></MenuItem>
                        ) : (
                            <MenuHeader
                                suffix={<BsChevronDoubleLeft />}
                                onClick={handleCollapsed}
                            >
                                <Row>
                                    <Col lg={3} md={3} className="d-flex align-items-center justify-content-right" >
                                        <div className="ms-2" style={{backgroundColor:'whitesmoke', padding:'5px', borderRadius:'5px', height:'100%', justifyContent:'center', display:'flex', alignItems:'center'}}>
                                            <Image src={logoJfs} alt="logo" width={40} height={28} />
                                        </div>
                                    </Col>
                                    <Col lg={7} md={7}>
                                        <div
                                            style={{
                                                textAlign: "left",
                                                color: '#EC551D',
                                                textTransform: 'uppercase',
                                                fontWeight: 800,
                                                fontSize: 12,
                                                fontFamily: 'inherit',
                                            }}
                                        >
                                            PT. JAVA FOOTWEAR SPORTINDO
                                        </div>
                                        <div style={{ 
                                            fontSize: 8, 
                                            textAlign: 'left', 
                                            textTransform: 'uppercase', 
                                            fontWeight: 500, 
                                            fontFamily: 'inherit',
                                        }}>
                                            Enterprise Resource Planning
                                        </div>
                                    </Col>
                                    <Col lg={2} md={2} className="d-flex align-items-center justify-content-center">
                                        <Icon name="bars" style={{cursor:'pointer'}}/>
                                    </Col>
                                </Row>
                            </MenuHeader>
                        )}
                    </Menu>
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="round">
                        {
                            userProf?.access?.some(item => item.id_page === '1') ?
                                <MenuItem icon={<BsBarChart />} suffix={<Link to="/" />}>Dashboard</MenuItem>
                                : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '2') ?
                                <SubMenu title="Product" icon={<BsBox />}>
                                    <MenuItem suffix={<Link to="/product/master_article" />}>Master Article</MenuItem>
                                    <MenuItem suffix={<Link to="/product/discount" />}>Discount</MenuItem>
                                    <MenuItem suffix={<Link to="/product/brand" />}>Brand</MenuItem>
                                </SubMenu>
                                : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '3') ?
                                <SubMenu title="Purchasing" icon={<BsBagPlus />} >
                                    <MenuItem suffix={<Link to="/purchasing/master_vendor" />}>Master Vendor</MenuItem>
                                    <MenuItem suffix={<Link to="/purchasing/purchase_order" />}>Purchase Order</MenuItem>
                                    <MenuItem suffix={<Link to="/purchasing/goods_receipt" />}>Goods Receipt</MenuItem>
                                </SubMenu>
                                : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '14') ?
                                <SubMenu title="Direct Sales" icon={<BsBox2 />}>
                                    <MenuItem suffix={<Link to="/directsales/master_customer" />}>Master Customer</MenuItem>
                                    <MenuItem suffix={<Link to="/directsales/master_salesman" />}>Master Salesman</MenuItem>
                                    <MenuItem suffix={<Link to="/directsales/customer_delivery_address" />}>Customer Delivery Address</MenuItem>
                                    <MenuItem suffix={<Link to="/directsales/customer_type" />}>Customer Type</MenuItem>
                                    <MenuItem suffix={<Link to="/directsales/sales_order" />}>Sales Order</MenuItem>
                                    <MenuItem suffix={<Link to="/directsales/delivery_order" />}>Delivery Order</MenuItem>
                                    <MenuItem suffix={<Link to="/directsales/so_invoice" />}>Invoice</MenuItem>
                                </SubMenu>
                                : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '10') ?
                                <SubMenu title="Payment" icon={<BsCashCoin />} >
                                    <MenuItem suffix={<Link to="/payment/master_bank" />}>Master Bank</MenuItem>
                                    <MenuItem suffix={<Link to="/payment/payable_invoice" />}>Payable Invoice</MenuItem>
                                    <MenuItem suffix={<Link to="/payment/payable_invoice_payment" />}>Payable Invoice (Payment)</MenuItem>
                                </SubMenu>
                                : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '13') ?
                                <SubMenu title="Finance" icon={<BsBank2 />}>
                                    <MenuItem suffix={<Link to="/finance/fee_cash_bank" />}>Fee Cash Bank</MenuItem>
                                </SubMenu>
                                : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '4') ?
                                <SubMenu title="Showroom" icon={<BsHouse />}>
                                    <MenuItem suffix={<Link to="/showroom/warehouse" />}>Warehouse</MenuItem>
                                    <MenuItem suffix={<Link to="/showroom/showroom" />}>Showroom</MenuItem>
                                </SubMenu>
                                : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '5') ?
                                <SubMenu title="Stock" icon={<BsGraphUp />}>
                                    <MenuItem suffix={<Link to="/stock/product_stock" />}>Product Stock</MenuItem>
                                    <MenuItem suffix={<Link to="/stock/stock_transfer" />}>Stock Transfer</MenuItem>
                                    <MenuItem suffix={<Link to="/stock/stock_adjustment" />}>Stock Adjustment</MenuItem>
                                    <MenuItem suffix={<Link to="/stock/stock_movement" />}>Stock Movement</MenuItem>
                                    <MenuItem suffix={<Link to="/stock/stock_opname_entry" />}>Stock Opname Entry</MenuItem>
                                    <MenuItem suffix={<Link to="/stock/stock_opname_release" />}>Stock Opname Release</MenuItem>
                                </SubMenu>
                                : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '6') ?
                                <SubMenu title="Sales" icon={<BsCart />}>
                                    {
                                        userProf?.profile.isCashier === '1' ? // KASIR
                                            <MenuItem suffix={<Link to="/sales/pos" />}>Sales Entry POS</MenuItem>
                                            : <></>
                                    }
                                    <MenuItem suffix={<Link to="/sales/agent" />}>Sales Agent</MenuItem>
                                    <MenuItem suffix={<Link to="/sales/master_edc" />}>Master EDC</MenuItem>
                                    <MenuItem suffix={<Link to="/sales/master_cc" />}>Master Credit Card</MenuItem>
                                    <MenuItem suffix={<Link to="/sales/voucher" />}>Voucher</MenuItem>
                                </SubMenu>
                                : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '8') ?
                                <SubMenu title="Report" icon={<BsClipboardData />}>
                                    <MenuItem suffix={<Link to="/report/sales" />}>POS Sales Report</MenuItem>
                                    <MenuItem suffix={<Link to="/report/sale_through" />}>Sale Through</MenuItem>
                                </SubMenu>
                                : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '9') ?
                                <SubMenu title="Loyalty Program" icon={<BsGift />}>
                                    <MenuItem suffix={<Link to="/loyalty_program/membership" />}>Membership</MenuItem>
                                    <MenuItem suffix={<Link to="/loyalty_program/lockerPage" />}>Locker</MenuItem>
                                </SubMenu>
                                : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '12') ?
                                <SubMenu title="ICT" icon={<BsKeyboard />}>
                                    <MenuItem suffix={<Link to="/ict/ict_expense_entry" />}>Expenditure Entry</MenuItem>
                                    <MenuItem suffix={<Link to="/ict/inventory_pc" />}>Inventory PC</MenuItem>
                                </SubMenu>
                                : <></>
                        }
                        {
                            userProf?.access?.some(item => item.id_page === '7') ?
                                <SubMenu title="Administrator" icon={<BsGear />}>
                                    <MenuItem suffix={<Link to="/administrator/user" />}>User</MenuItem>
                                    <MenuItem suffix={<Link to="/administrator/role" />}>Role</MenuItem>
                                    <MenuItem suffix={<Link to="/administrator/announcement" />}>Announcement</MenuItem>
                                </SubMenu>
                                : <></>
                        }

                    </Menu>
                </SidebarContent>
                <SidebarFooter>
                    <Menu iconShape="circle">
                        <MenuItem icon={<Popup trigger={<BsPower />} content="Logout" color="black" size="small" />} onClick={handleLogout}>
                            <Stack direction="vertical">
                                <div>Sign as {userProf?.profile.name}</div>
                                <div className="text-capitalize" style={{ fontSize: '0.8em', color: 'coral' }}>{userProf?.profile.role_name}</div>
                            </Stack>
                        </MenuItem>
                    </Menu>
                </SidebarFooter>
            </ProSidebar>
        </>
    )
}
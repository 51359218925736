import React, { useEffect, useState, useMemo, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { useMyGlobal } from "../component/Global";
import { Button, Checkbox, Dimmer, Divider, Dropdown, Form, Icon, Input, Label, Loader, Popup, Transition } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { capitalize, curency, curencyNoSym, currentDate, currentDateTime, currentTime } from "../component/utils";
import Stack from "react-bootstrap/Stack";
import { Toaster } from "../component/Toaster";
import { QtyInput } from "../component/QtyInput";
import Modal from "rsuite/Modal";
import Tabs from 'rsuite/Tabs';
import { Reacttable } from "../component/Reacttable";
import Badge from "rsuite/Badge"
import Tag from 'rsuite/Tag';
import TagGroup from 'rsuite/TagGroup';
import { BsCashCoin, BsCreditCard, BsCurrencyExchange } from "react-icons/bs";
import moment from "moment";



export const Pos = () => {
    const openPos = useLocation().state;
    const { host, userProfile, showroomOpt } = useMyGlobal();
    const [modalPosCard, setModalPosCard] = useState(false)
    const [agentOpt, setAgentOpt] = useState([]);
    const [trxCode, setTrxCode] = useState('');
    const [kasir, setKasir] = useState('')
    const [trxDate, setTrxDate] = useState('');
    const [trxTime, setTrxTime] = useState('');
    const [agent, setAgent] = useState('')
    const [agentId, setAgentId] = useState('')
    const [stock, setStock] = useState([]);
    const [filteredStock, setFilteredStock] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 7;
    const [totalTrx, setTotalTrx] = useState(0)
    const [totalQty, setTotalQty] = useState(0)
    const [grandTotal, setGrandTotal] = useState(0)
    const [totalAdditionalDsc, setTotalAdditionalDsc] = useState(0)
    const [addDiscount, setAddDicount] = useState(1)
    const [addDiscountItem, setAddDicountItem] = useState(1)
    const [addDesc, setAddDesc] = useState('')
    const [addDescItem, setAddDescItem] = useState('')
    const [modalAddDiscount, setModalAddDiscount] = useState(false)
    const [discountType, setDiscountType] = useState('percent')
    const [dscPerItem, setDscPerItem] = useState(0)
    const [modalDscPerItem, setModalDscPerItem] = useState(false)
    const [selItemForDscItem, setSelItemForDscItem] = useState(null)
    const [usedDiscount, setUsedDiscount] = useState('')
    const [trxState, setTrxState] = useState('inactive')
    const [modalPayment, setModalPayment] = useState(false)
    const [payVoucher, setPayVoucher] = useState(0)
    const [paidAmount, setPaidAmount] = useState(0)
    const [totalPayment, setTotalPayment] = useState(0)
    const [payChange, setPayChange] = useState(0)
    const [modalVoucher, setModalVoucher] = useState(false)
    const [modalCard, setModalCard] = useState(false)
    const [modalEdc, setModalEdc] = useState(false)
    const [voucherData, setVoucherData] = useState([])
    const [dataCc, setDataCc] = useState([])
    const [edcData, setEdcData] = useState([])
    const [selectedVoucher, setSelectedVoucher] = useState([])
    const [selectedCard, setSelectedCard] = useState(null)
    const [selectedEdc, setSelectedEdc] = useState(null)
    const [lastDigitCard, setLastDigitCard] = useState('xxxx')
    const [refNumber, setRefNumber] = useState('xxxxxx')
    const [loadSubmitPay, setLoadSubmitPay] = useState(false)
    const [returnData, setReturnData] = useState([])
    const [returnDeposit, setReturnDeposit] = useState(0)
    const [returnCode, setReturnCode] = useState('')
    const [modalReturnCode, setModalReturnCode] = useState(false)
    const [isReturn, setIsReturn] = useState('N')
    const [payCash, setPayCash] = useState(0)
    const [payCard, setPayCard] = useState(0)
    const [sumItem, setSumItem] = useState([])
    const [sumDscItem, setSumDscItem] = useState([])
    const [sumOos, setSumOos] = useState([])
    const [sumNewItem, setSumNewItem] = useState([])
    const [modalBarcode, setModalBarcode] = useState(false)
    const inputRef = useRef(null)
    const [dataFromScan, setDataFromScan] = useState(null)
    const [barcode, setBarcode] = useState('')
    const [btnScan, setBtnScan] = useState(true)
    const [startTrx, setStartTrx] = useState(false)
    const [loadStock, setLoadStock] = useState(false)
    const [modalSpvPwd, setModalSpvPwd] = useState(false)
    const [spvPasskey, setSpvPasskey] = useState("")
    const [discountBy, setDiscountBy] = useState("")
    const [itemData, setItemData] = useState([])
    const [isNewRowAdded, setIsNewRowAdded] = useState(false)
    const cardInputRef = useRef(null)
    const [selRow, setSelRow] = useState(null)
    const [curentTotal, setCurentTotal] = useState(0)
    const [newRow, setNewRow] = useState({ card_no: '', description: '', amount: curentTotal, bank_edc: '', trace_number: '' })
    const [cardList, setCardList] = useState([])
    const [edcList, setEdcList] = useState([])
    const [isSplit, setIsSplit] = useState(false)
    const currentYear = new Date().getFullYear();

    const [payment, setPayment] = useState({
        grand_total: 0,
        total_payement: 0,
        paid: 0,
        change: 0,
        pay_type: '',
        total_voucher: 0,
        voucher: [],
        card: "",
        edc: "",
        last_digit: "",
        ref: "",
    })

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = filteredStock.slice(startIndex, endIndex);

    const totalPages = Math.ceil(filteredStock.length / itemsPerPage);

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleAddRow = () => {
        setItemData(prevItemData => {
            // Hitung ulang total amount dari state terbaru
            const totalAmountUsed = prevItemData.reduce((acc, item) => acc + (Number(item.amount) || 0), 0);
            console.log(isSplit)
            // Hitung sisa amount
            const remainingAmount = isSplit ? (curentTotal - payCash) - totalAmountUsed : curentTotal - totalAmountUsed;
            const newAmount = remainingAmount > 0 ? remainingAmount : 0;

            // Tambahkan baris baru dengan amount yang benar
            return [...prevItemData, { ...newRow, id: prevItemData.length + 1, amount: newAmount }];
        });

        // Reset input form
        setNewRow({ card_no: '', description: '', amount: 0, bank_edc: '', trace_number: '' });

        // Set flag bahwa baris baru ditambahkan
        setIsNewRowAdded(true);
        setNewRow({ card_no: '', description: '', amount: 0, bank_edc: '', trace_number: '' }) // Reset input form
        setIsNewRowAdded(true); // Set flag bahwa baris baru ditambahkan
    }

    const handleRemoveRow = (index) => {
        const updatedData = [...itemData]
        updatedData.splice(index, 1) // Hapus baris berdasarkan index
        setItemData(updatedData)
    }

    const handleEdit = (index, field, value, numeric) => {
        setItemData(prevItemData => {
            const updatedData = [...prevItemData];
            const rawValue = numeric ? Number(value.replace(/\D/g, "")) || 0 : value; // Hanya angka yang disimpan
            updatedData[index][field] = rawValue;
            return updatedData;
        })
    }

    const handleAddCard = (index, field, value, numeric) => {
        setSelRow({ index: index, field: field, value: value, numeric: numeric })
        setModalCard(true)
    }

    const handleAddEdc = (index, field, value, numeric) => {
        setSelRow({ index: index, field: field, value: value, numeric: numeric })
        getEdc()
        setModalEdc(true)
    }

    const handleClickSubmitCard = () => {
        const isInvalid = itemData.some(item => item.amount === 0 || item.description.trim() === '' || item.bank_edc.trim() === '' || item.card_no === '' || item.trace_number === '');

        if (isInvalid) {
            alert("Make sure all amounts are not 0 , description, EDC and Trace Number column are filled in!");
            return;
        } else {
            setCardList(itemData)
            setModalPosCard(false)
        }
    }

    const formatCurrency = (value) => {
        if (!value) return value;
        const onlyNums = value.replace(/[^\d]/g, '');
        const numberValue = Number(onlyNums).toString();
        return numberValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    const columnsVoucher = useMemo(() => [
        {
            Header: 'ID',
            accessor: "id_voucher"
        },
        {
            Header: 'Showroom',
            accessor: "showroom_name"
        },
        {
            Header: 'Description',
            accessor: "desc"
        },
        {
            Header: 'Active Date',
            accessor: "start_date"
        },
        {
            Header: 'End Date',
            accessor: "end_date"
        },
        {
            Header: 'Status',
            accessor: "status",
            Cell: ({ cell }) => (
                <>
                    <Badge className="me-1" color={cell.value === 'running' ? 'green' : cell.value === 'expired' ? 'orange' : cell.value === 'upcoming' ? 'blue' : ''} /><span className="text-capitalize">{cell.value}</span>
                </>
            )
        },
        {
            Header: 'Code',
            accessor: "code"
        },
        {
            Header: 'Nominal',
            accessor: "nominal",
            Cell: ({ cell }) => (<div>{curencyNoSym.format(cell.value)}</div>)
        },
        {
            Header: 'Qty',
            accessor: "qty"
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup content="Select this voucher" size="tiny" trigger={
                        <Button icon="plus" onClick={() => handleClickAddVoucher(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const columnsCard = useMemo(() => [
        {
            Header: 'ID',
            accessor: "id"
        },
        {
            Header: 'Type',
            accessor: "card_type",
            Cell: ({ cell }) => (
                <div className="fw-bold">{cell.value}</div>
            )
        },
        {
            Header: 'Class',
            accessor: "class",
            Cell: ({ cell }) => (<div className="text-capitalize">{cell.value}</div>)
        },
        {
            Header: 'Bank',
            accessor: "bank"
        },
        {
            Header: 'Description',
            accessor: "desc"
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => (
                <Popup content="Select" size="tiny" trigger={
                    <Icon style={{ cursor: 'pointer' }} name="plus" color="blue" onClick={() => handleClickAddCard(cell.row.original)} />
                } />
            )
        },
    ])

    const columnsEdc = useMemo(() => [
        {
            Header: "Showroom",
            accessor: "showroom_name"
        },
        {
            Header: "Bank",
            accessor: "bank",
            Cell: ({ cell }) => (
                <div className="fw-bold">{cell.value}</div>
            )
        },
        {
            Header: "Description",
            accessor: "desc"
        },
        {
            Header: "Create",
            accessor: "createAt"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Popup content="Select" size="tiny" trigger={
                    <Icon style={{ cursor: 'pointer' }} name="plus" color="blue" onClick={() => handleClickAddEdc(cell.row.original)} />
                } />
            )
        },

    ])

    const handleClickAddVoucher = (data) => {
        // setSelectedVoucher(prev => [...prev, data])
        try {
            setSelectedVoucher(prev => {
                const existingVoucher = prev.find(voucher => voucher.id_voucher === data.id_voucher);
                if (existingVoucher) {
                    return prev.map(voucher =>
                        voucher.id_voucher === data.id_voucher
                            ? { ...voucher, used: (voucher.used || 1) + 1 }
                            : voucher
                    );
                } else {
                    return [...prev, { ...data, used: 1 }];
                }
            });
            // setVoucherData(prev => prev.filter(voucher => voucher.id_voucher !== data.id_voucher))
            setModalVoucher(false)
            Toaster.fire({ icon: 'success', text: 'Voucher added', position: 'top-right' })
        } catch (error) {
            Toaster.fire({ icon: 'error', text: 'Error', position: 'top-right' })
        }
    }

    const handleClickAddCard = (data) => {
        setSelectedCard(data)
        setModalCard(false)
        handleEdit(selRow.index, selRow.field, data.card_type + ' - ' + data.desc, selRow.numeric)
    }

    const handleClickAddEdc = (data) => {
        setSelectedEdc(data)
        setModalEdc(false)
        handleEdit(selRow.index, selRow.field, data.desc, selRow.numeric)
    }

    const getSalesAgent = async (idSh) => {
        await axios({
            method: 'GET',
            url: `${host}/sales/index.php?action=salesAgentByShowroom&id_showroom=${idSh}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            if (res.data.status === 200) {
                setAgentOpt(res.data.data.map(i => {
                    return {
                        key: i.id,
                        text: i.name,
                        value: i.id
                    };
                }));
            }
        });
    };

    const getTrxCode = async (abr, id) => {
        await axios({
            method: 'GET',
            url: `${host}/sales/index.php?action=trxCode&abr=${abr}&id_showroom=${id}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            setTrxCode(res.data);
        });
    };

    const updateStatusDsc = () => {
        axios({
            method: 'PUT',
            url: `${host}/stock/`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: {
                action: 'updateStatusDiscount'
            }
        })
    }

    const getStockByWh = async (id) => {
        setLoadStock(true)
        updateStatusDsc() //UPDATE DULU STATUS DISKON 
        await axios({
            method: 'GET',
            url: `${host}/stock/index.php?action=stockByWarehouse&id_warehouse=${id}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            const ct = moment()
            const newArrivalThreshold = ct.clone().subtract(7, 'days')
            if (res.data.status === 200) {
                setStock(res.data.data);
                setFilteredStock(res.data.data)
                setSumItem(res.data.data)
                setSumDscItem(res.data.data.filter(item => item.start_date <= currentDate && item.end_date >= currentDate && (item.discount_state !== 'null' && item.discount_state !== '0')))
                setSumOos(res.data.data.filter(item => parseInt(item.qty) === 0))
                setSumNewItem(res.data.data.filter(item => moment(item.createAt).isAfter(newArrivalThreshold)))
                setBtnScan(false)
                setStartTrx(true)
            }
        }).finally(() => setLoadStock(false))
    }

    const handleClickNewTrx = () => {
        getSalesAgent([openPos.id_showroom]);
        getTrxCode(openPos.showroom_abr, openPos.id_showroom);
        setKasir(openPos.user_name)
        setTrxTime(currentTime);
        setTrxDate(currentDate);
        getStockByWh(openPos.id_warehouse);
        setTrxState('active')
        setAgent('')
        setAgentId('')
        setStartTrx(true)
    }

    const handleChangeSearch = (value) => {
        setSearchValue(value);
    }

    const handleSelectItem = (item) => { 
        const exist = selectedItems?.some(i => i.article === item.article && i.size === item.size);
        if (!exist) {
            const newItem = {
                ...item,
                qty_order: 1,
                additional_discount_value: 0,
                additional_discount: '',
                additional_discount_description: '',
                before_add_dsc_price: item.final_price,
                additional_discount_type: '',
                additional_discount_option: '',
            }
            setSelectedItems([...selectedItems, newItem])
        } else {
            const updatedItems = selectedItems.map((selectedItem) => {
                if (selectedItem.article === item.article && selectedItem.size === item.size) {
                    const newQtyOrder = selectedItem.qty_order ? selectedItem.qty_order + 1 : 2;

                    // Pastikan qty_order tidak melebihi qty
                    if (newQtyOrder > selectedItem.qty) {
                        Toaster.fire({
                            icon: 'warning',
                            text: `Order quantity for ${selectedItem.article} size ${selectedItem.size} cannot exceed available stock.`,
                            position: 'top-right'
                        })
                        return {
                            ...selectedItem,
                            qty_order: selectedItem.qty,
                        };
                    }

                    return {
                        ...selectedItem,
                        qty_order: newQtyOrder,
                    };
                }
                return selectedItem;
            });

            setSelectedItems(updatedItems);
        }
    }

    const handleQuantityChange = (qty, item) => {
        const updatedCartData = selectedItems.map((cartItem) => {
            if (cartItem.article === item.article && cartItem.size === item.size) {
                return { ...cartItem, qty_order: qty }
            }
            return cartItem;
        });
        setSelectedItems(updatedCartData)
    }

    const handleRemoveSelected = (item) => {
        const newSelected = selectedItems.filter(i => i.article !== item.article || i.size !== item.size)
        setSelectedItems(newSelected)
    }

    const handleAddDiscount = () => {
        setDiscountBy("allitem")
        setModalSpvPwd(true)
    }

    const handleSubmitPasskey = () => {
        const param = new FormData()
        param.append('id_showroom', openPos.id_showroom)
        param.append('spv_pwd', spvPasskey)

        axios({
            method: 'POST',
            url: `${host}/pos/?action=cekSpvPasskeyValid`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                if (res.data.is_valid) {
                    if (discountBy === 'allitem') {
                        setModalAddDiscount(true)
                        setModalSpvPwd(false)
                    } else if (discountBy === 'item') {
                        setModalDscPerItem(true)
                        setModalSpvPwd(false)
                    }
                    setSpvPasskey('')
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: 'Invalid SPV Passkey',
                        position: 'top-right'
                    })
                }
            }
        })
    }

    const handleRemoveAddDiscount = () => {
        const updateValue = selectedItems.map(item => {
            return {
                ...item,
                additional_discount_value: 0,
                additional_discount: '',
                additional_discount_description: '',
                final_price: item.before_add_dsc_price,
                before_add_dsc_price: item.price,
                additional_discount_type: '',
                additional_discount_option: ''
            }
        })
        setSelectedItems(updateValue)
        setTotalAdditionalDsc(0)
        setUsedDiscount('')
        setAddDicount(0)
        setAddDesc('')
    }

    const handleChangeDscType = (e, { value }) => {
        setDiscountType(value)
        setAddDicount(1)
        setAddDesc('')
    }

    const handleClickAddDscItem = (item) => {
        setDiscountBy('item')
        setSelItemForDscItem(item)
        setModalSpvPwd(true)
    }

    const handleChangeAddDscItem = (value) => {
        const itemPrice = parseFloat(selItemForDscItem.final_price)
        const addDsc = (itemPrice * value / 100)
        setAddDicountItem(value)
    }

    const handleSubmitDscItem = () => {
        if (parseInt(addDiscountItem) > 0) {
            if (discountType === 'percent') {
                const itemPrice = parseFloat(selItemForDscItem.final_price)
                const addDsc = (itemPrice * addDiscountItem / 100)
                const newPrice = itemPrice - addDsc
                const updateValue = selectedItems.map(item => {
                    if (item.article === selItemForDscItem.article && item.size === selItemForDscItem.size) {
                        return {
                            ...item,
                            additional_discount_value: addDsc,
                            additional_discount: addDiscountItem,
                            additional_discount_description: addDescItem,
                            final_price: newPrice,
                            before_add_dsc_price: itemPrice,
                            additional_discount_type: 'percent',
                            additional_discount_option: 'item'
                        }
                    }
                    return item
                })
                setSelectedItems(updateValue)
                setModalDscPerItem(false)
                setAddDicountItem(0)
                setAddDescItem('')
                setTotalAdditionalDsc(updateValue.reduce((total, item) => total + parseFloat(item.additional_discount_value), 0))
                setUsedDiscount('item')
            } else if (discountType === 'nominal') {
                const itemPrice = parseFloat(selItemForDscItem.final_price)
                const addDsc = addDiscountItem
                const newPrice = itemPrice - addDsc
                const updateValue = selectedItems.map(item => {
                    if (item.article === selItemForDscItem.article && item.size === selItemForDscItem.size) {
                        return {
                            ...item,
                            additional_discount_value: addDsc,
                            additional_discount: addDiscountItem,
                            additional_discount_description: addDescItem,
                            final_price: newPrice,
                            before_add_dsc_price: itemPrice,
                            additional_discount_type: 'nominal',
                            additional_discount_option: 'item'
                        }
                    }
                    return item
                })
                setSelectedItems(updateValue)
                setModalDscPerItem(false)
                setAddDicountItem(0)
                setAddDescItem('')
                setTotalAdditionalDsc(updateValue.reduce((total, item) => total + parseFloat(item.additional_discount_value), 0))
                setUsedDiscount('item')
            }
        } else {
            Toaster.fire({
                icon: 'error',
                text: 'Additional discount must be greater than 0%',
                position: 'top-right'
            })
        }
    }

    const handleSubmitDscTrx = () => {
        const subTotal = selectedItems?.reduce((total, item) => total + parseFloat(item.final_price), 0)
        if (parseInt(addDiscount) > 0) {
            if (discountType === 'percent') {
                const updateValue = selectedItems.map(item => {
                    const price = parseFloat(item.before_add_dsc_price)
                    const addDsc = price * addDiscount / 100
                    const newPrice = price - addDsc
                    return {
                        ...item,
                        additional_discount_value: addDsc,
                        additional_discount: addDiscount,
                        additional_discount_description: addDesc,
                        final_price: newPrice,
                        before_add_dsc_price: price,
                        additional_discount_type: 'percent',
                        additional_discount_option: 'transaction'
                    }
                })
                setSelectedItems(updateValue)
                setTotalAdditionalDsc(subTotal * addDiscount / 100)
                setModalAddDiscount(false)
                setUsedDiscount('trx')
            } else if (discountType === 'nominal') {
                const addDsc = parseFloat(addDiscount) / selectedItems?.length
                const updateValue = selectedItems.map(item => {
                    const price = parseFloat(item.before_add_dsc_price)
                    const newPrice = price - addDsc
                    return {
                        ...item,
                        additional_discount_value: addDsc,
                        additional_discount: addDsc,
                        additional_discount_description: addDesc,
                        final_price: newPrice,
                        before_add_dsc_price: price,
                        additional_discount_type: 'nominal',
                        additional_discount_option: 'transaction'
                    }
                })
                setSelectedItems(updateValue)
                setTotalAdditionalDsc(addDiscount)
                setModalAddDiscount(false)
                setUsedDiscount('trx')
            }
        } else {
            Toaster.fire({
                icon: 'error',
                text: 'Discount value must be greater than 0',
                position: 'top-right'
            })
        }
    }

    const handleRemoveDscItem = (selected) => {
        if (usedDiscount === 'item') {
            const updateValue = selectedItems.map(item => {
                if (item.article === selected.article && item.size === selected.size) {
                    return {
                        ...item,
                        additional_discount_value: 0,
                        additional_discount: '',
                        additional_discount_description: '',
                        final_price: item.before_add_dsc_price,
                        additional_discount_type: ''
                    }
                }
                return item
            })

            setSelectedItems(updateValue)
            setTotalAdditionalDsc(updateValue.reduce((total, item) => total + parseFloat(item.additional_discount_value), 0))
        } else if (usedDiscount === 'trx') {
            Toaster.fire({
                icon: 'warning',
                title: 'Cannot remove from here',
                text: 'This additional discount option is (Discount Transaction), remove it from button above',
                position: 'top-right'
            })
        }
    }

    const handleCancelTrx = () => {
        setTotalAdditionalDsc(0)
        setTotalTrx(0)
        setTotalQty(0)
        setAgentOpt([])
        setSearchValue('')
        setGrandTotal(0)
        setKasir('')
        setSelectedItems([])
        setTrxState('inactive')
        setStock([])
        setTrxCode('')
        setTrxDate('')
        setPayCash(0)
        setPayCard(0)
        setAgent('')
        setAgentId('')

        setTotalPayment(0)
        setPayChange(0)
        setPaidAmount(0)
        setSelectedEdc(null)
        setSelectedCard(null)
        setSelectedVoucher([])
        setLastDigitCard('xxxx')
        setRefNumber('xxxxxx')
        setPayCard(0)
        setPayCash(0)
        setReturnCode('')
        setBtnScan(true)
        setLoadSubmitPay(false)

        setStartTrx(false)
    }

    const handleClickPayment = () => {
        if (agent === '') {
            Toaster.fire({
                icon: 'warning',
                text: 'Sales Agent cannot be empty!',
                position: 'top-right'
            })
        }
        if (grandTotal <= 0) {
            Toaster.fire({
                icon: 'warning',
                title: 'Unreasonable grand total',
                text: 'You must select at last one item!',
                position: 'top-right'
            })
        }
        if (agent !== '' && grandTotal > 1000) {
            setModalPayment(true)
        }
    }

    const handleClosePayment = () => {
        setModalPayment(false)
    }

    const handleChangePayAmount = (value) => {
        const inputValue = value
        const onlyNums = inputValue.replace(/[^\d]/g, '')
        setPaidAmount(onlyNums)
        setPayChange(onlyNums == 0 || '' ? 0 : onlyNums - totalPayment)
    }

    const getVoucher = (idShowroom) => {
        axios({
            method: "GET",
            url: `${host}/sales/index.php?action=voucherByShowroom&id_showroom=${idShowroom}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                const data = res.data.data.filter(item => item.is_active === 'Y' && item.status === 'running' && parseInt(item.qty) > 0)
                const filterBySelected = data.filter(
                    voucher => !selectedVoucher?.some(selected => selected.id_voucher === voucher.id_voucher)
                )
                setVoucherData(data)

            }
        })
    }

    const getCC = async () => {
        await axios({
            method: "GET",
            url: `${host}/sales/index.php?action=CC`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            if (res.data.status === 200) {
                setDataCc(res.data.data)
            }
        })
    }

    const getEdc = async () => {
        axios({
            method: "GET",
            url: `${host}/sales/index.php?action=edc`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            if (res.data.status === 200) {
                setEdcData(res.data.data)
            }
        })
    }

    const handleClickVoucher = () => {
        getVoucher(openPos.id_showroom)
        setModalVoucher(true)
    }

    const handleClickCard = () => {
        getCC()
        // setModalCard(true)
        setModalPosCard(true)
    }

    const handleClickEdc = () => {
        getEdc()
        setModalEdc(true)
    }

    // const handleRemoveVoucher = (data) => {
    //     const updateVoucher = selectedVoucher?.filter(item => item.id_voucher !== data.id_voucher)
    //     setSelectedVoucher(updateVoucher)
    //     const currentTotalVoucher = updateVoucher.reduce((total, item) => total + parseInt(item.nominal), 0)
    //     setPayVoucher(currentTotalVoucher)
    // }

    const handleRemoveVoucher = (data) => {
        try {
            setSelectedVoucher(prev => {
                const existingVoucher = prev.find(voucher => voucher.id_voucher === data.id_voucher);
                if (existingVoucher) {
                    if (existingVoucher.used > 1) {
                        return prev.map(voucher =>
                            voucher.id_voucher === data.id_voucher
                                ? { ...voucher, used: voucher.used - 1 }
                                : voucher
                        );
                    } else {
                        return prev.filter(voucher => voucher.id_voucher !== data.id_voucher);
                    }
                }
                return prev;
            });
            Toaster.fire({ icon: 'success', text: 'Voucher removed', position: 'top-right' })
        } catch (error) {
            Toaster.fire({ icon: 'error', text: 'Error', position: 'top-right' })
        }
    };

    const getReturnData = async (idReturn) => {
        await axios({
            method: "GET",
            url: `${host}/pos/index.php?action=getReturnDataById&id_return=${idReturn}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            if (res.data.status === 200) {
                if (res.data.data.length > 0) {
                    setReturnData(res.data.data)
                    setReturnDeposit(res.data.data.reduce((total, cur) => total + parseFloat(cur.final_price), 0))
                    setModalReturnCode(false)
                } else {
                    alert('Return code was not found or has been replaced')
                    setIsReturn('N')
                    setModalReturnCode(false)
                }
            }
        })
    }

    const handleClickReturn = () => {
        setModalReturnCode(true)
    }

    const clearPayment = () => {
        setPayment({
            grand_total: 0,
            total_payement: 0,
            paid: 0,
            change: 0,
            pay_type: '',
            total_voucher: 0,
            voucher: [],
            card: "",
            edc: "",
            last_digit: "",
            ref: "",
            pay_cash: 0,
            pay_card: 0,
        })
        setTotalPayment(0)
        setPayChange(0)
        setPaidAmount(0)
        setSelectedEdc(null)
        setSelectedCard(null)
        setSelectedVoucher([])
        setLastDigitCard('xxxx')
        setRefNumber('xxxxxx')
        setPayCard(0)
        setPayCash(0)
        setReturnCode('')
        setItemData([])
        setCardList([])
    }

    const handlePrint = (data) => {
        const locHost = "http://localhost"
        axios({
            method: "POST",
            url: `${locHost}/pos_print/`,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(data)
        }).then(res => {
            if (res.data.status === 'success') {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success transaction',
                    position: 'top-right'
                })
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.message,
                    position: 'top-right'
                })
            }
        })
    }

    const handleClickSubmitPaymentCard = async () => {
        if ((totalPayment <= 0 || selectedEdc === null || selectedCard === null || lastDigitCard === '') && isReturn === "N") {
            Toaster.fire({
                icon: 'error',
                title: 'Some have not been filled in !',
                text: "Maybe there's something wrong, please checking again",
                position: 'top-right',
            })
        } else {
            const msg = window.confirm('Is everything correct?')
            if (msg) {
                if (isReturn === 'Y') {
                    const cfm = window.confirm('You have check for Return Deposit, are you sure this transaction is used to exchange from return?\nIf not, please uncheck the deposit from this payment form')
                    if (cfm) {
                        setLoadSubmitPay(true)
                        const header = {
                            trx_code: trxCode,
                            id_open: openPos.id_open,
                            kasir_id: openPos.id_user,
                            kasir: kasir,
                            trx_time: currentTime,
                            trx_date: trxDate,
                            sales: agent,
                            sales_id: agentId,
                            store_name: openPos.showroom_name,
                            store_addr: openPos.address,
                            store_id: openPos.id_showroom,
                            id_warehouse: openPos.id_warehouse,
                            total_trx: grandTotal,
                            total_payment: totalPayment,
                            total_qty: selectedItems.reduce((total, item) => total + parseInt(item.qty_order), 0),
                            is_return: isReturn,
                            return_code: returnCode,
                        }
                        const detail = selectedItems
                        const dataPayment = {
                            grand_total: grandTotal,
                            deposit: returnDeposit,
                            total_payment: totalPayment,
                            paid: parseInt(paidAmount),
                            change: payChange,
                            pay_type: "card",
                            total_voucher: payVoucher,
                            voucher: selectedVoucher,
                            card: selectedCard,
                            edc: selectedEdc,
                            last_digit: lastDigitCard,
                            ref: refNumber,
                            pay_cash: 0,
                            pay_card: parseFloat(totalPayment),
                        }
                        const card = cardList

                        const dataTrx = {
                            transaction: {
                                header: header,
                                detail: detail,
                            },
                            payment: dataPayment,
                            card: card,
                            action: "Entry",
                        }

                        setPayment(dataTrx)

                        await axios({
                            method: "POST",
                            url: `${host}/pos/index.php?action=createTransaction`,
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                            },
                            data: dataTrx
                        }).then(res => {
                            if (res.data.status === 200) {
                                Toaster.fire({
                                    icon: 'success',
                                    text: 'Transaction success',
                                    position: 'top-right'
                                })
                                handlePrint(dataTrx)
                                clearPayment()
                                handleCancelTrx()
                                setModalPayment(false)
                            } else {
                                Toaster.fire({
                                    icon: 'error',
                                    text: res.data.msg,
                                    position: 'top-right'
                                })
                            }
                            setLoadSubmitPay(false)
                        })
                    }
                } else {
                    setLoadSubmitPay(true)
                    const header = {
                        trx_code: trxCode,
                        id_open: openPos.id_open,
                        kasir_id: openPos.id_user,
                        kasir: kasir,
                        trx_time: currentTime,
                        trx_date: trxDate,
                        sales: agent,
                        sales_id: agentId,
                        store_name: openPos.showroom_name,
                        store_addr: openPos.address,
                        store_id: openPos.id_showroom,
                        id_warehouse: openPos.id_warehouse,
                        total_trx: grandTotal,
                        total_payment: totalPayment,
                        total_qty: selectedItems.reduce((total, item) => total + parseInt(item.qty_order), 0),
                        is_return: isReturn,
                        return_code: returnCode,
                    }
                    const detail = selectedItems
                    const dataPayment = {
                        grand_total: grandTotal,
                        deposit: returnDeposit,
                        total_payment: totalPayment,
                        paid: parseInt(paidAmount),
                        change: payChange,
                        pay_type: "card",
                        total_voucher: payVoucher,
                        voucher: selectedVoucher,
                        card: selectedCard,
                        edc: selectedEdc,
                        last_digit: lastDigitCard,
                        ref: refNumber,
                        pay_cash: 0,
                        pay_card: parseFloat(totalPayment),
                    }
                    const card = cardList

                    const dataTrx = {
                        transaction: {
                            header: header,
                            detail: detail,
                        },
                        card: card,
                        payment: dataPayment,
                        action: "Entry",
                    }
                    setPayment(dataTrx)

                    await axios({
                        method: "POST",
                        url: `${host}/pos/index.php?action=createTransaction`,
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                        },
                        data: dataTrx
                    }).then(res => {
                        if (res.data.status === 200) {
                            Toaster.fire({
                                icon: 'success',
                                text: 'Transaction success',
                                position: 'top-right'
                            })
                            handlePrint(dataTrx)
                            clearPayment()
                            handleCancelTrx()
                            setModalPayment(false)
                        } else {
                            Toaster.fire({
                                icon: 'error',
                                text: res.data.msg,
                                position: 'top-right'
                            })
                        }
                        setLoadSubmitPay(false)
                    })
                }
            }
        }
    }

    const handleClickSubmitPaymentCash = async () => {
        if (returnDeposit < 0) {
            Toaster.fire({
                icon: 'error',
                title: 'Some are incomplete !',
                text: 'Maybe something is wrong, try checking again',
                position: 'top-right',
            })
        } else {
            const msg = window.confirm('Is everything correct?')

            if (msg) {
                if (isReturn === 'Y') {
                    const cfm = window.confirm('You have check for Return Deposit, are you sure this transaction is used to exchange from return?\nIf not, please uncheck the deposit from this payment form')
                    if (cfm) {
                        setLoadSubmitPay(true)

                        const header = {
                            trx_code: trxCode,
                            id_open: openPos.id_open,
                            kasir_id: openPos.id_user,
                            kasir: kasir,
                            trx_time: currentTime,
                            trx_date: trxDate,
                            sales: agent,
                            sales_id: agentId,
                            store_name: openPos.showroom_name,
                            store_addr: openPos.address,
                            store_id: openPos.id_showroom,
                            id_warehouse: openPos.id_warehouse,
                            total_trx: grandTotal,
                            total_payment: totalPayment,
                            total_qty: selectedItems.reduce((total, item) => total + parseInt(item.qty_order), 0),
                            is_return: isReturn,
                            return_code: returnCode,
                        }
                        const detail = selectedItems
                        const dataPayment = {
                            grand_total: grandTotal,
                            deposit: returnDeposit,
                            total_payment: totalPayment,
                            paid: parseInt(paidAmount),
                            change: payChange,
                            pay_type: "cash",
                            total_voucher: payVoucher,
                            voucher: selectedVoucher,
                            card: "",
                            edc: "",
                            last_digit: "",
                            ref: "",
                            pay_cash: parseFloat(totalPayment),
                            pay_card: 0,
                        }

                        const dataTrx = {
                            transaction: {
                                header: header,
                                detail: detail,
                            },
                            payment: dataPayment,
                            action: "Entry",
                        }

                        setPayment(dataTrx)

                        await axios({
                            method: "POST",
                            url: `${host}/pos/index.php?action=createTransaction`,
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                            },
                            data: dataTrx
                        }).then(res => {
                            if (res.data.status === 200) {
                                Toaster.fire({
                                    icon: 'success',
                                    text: 'Transaction success',
                                    position: 'top-right'
                                })
                                handlePrint(dataTrx)
                                clearPayment()
                                handleCancelTrx()
                                setModalPayment(false)
                            } else {
                                Toaster.fire({
                                    icon: 'error',
                                    text: res.data.msg,
                                    position: 'top-right'
                                })
                            }
                            setLoadSubmitPay(false)
                        })
                    }
                } else {
                    const header = {
                        trx_code: trxCode,
                        id_open: openPos.id_open,
                        kasir_id: openPos.id_user,
                        kasir: kasir,
                        trx_time: currentTime,
                        trx_date: trxDate,
                        sales: agent,
                        sales_id: agentId,
                        store_name: openPos.showroom_name,
                        store_addr: openPos.address,
                        store_id: openPos.id_showroom,
                        id_warehouse: openPos.id_warehouse,
                        total_trx: grandTotal,
                        total_payment: totalPayment,
                        total_qty: selectedItems.reduce((total, item) => total + parseInt(item.qty_order), 0),
                        is_return: isReturn,
                        return_code: returnCode,
                    }
                    const detail = selectedItems
                    const dataPayment = {
                        grand_total: grandTotal,
                        deposit: returnDeposit,
                        total_payment: totalPayment,
                        paid: parseInt(paidAmount),
                        change: payChange,
                        pay_type: "cash",
                        total_voucher: payVoucher,
                        voucher: selectedVoucher,
                        card: "",
                        edc: "",
                        last_digit: "",
                        ref: "",
                        pay_cash: parseFloat(totalPayment),
                        pay_card: 0,
                    }

                    const dataTrx = {
                        transaction: {
                            header: header,
                            detail: detail,
                        },
                        payment: dataPayment,
                        action: "Entry",
                    }

                    setPayment(dataTrx)

                    await axios({
                        method: "POST",
                        url: `${host}/pos/index.php?action=createTransaction`,
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                        },
                        data: dataTrx
                    }).then(res => {
                        if (res.data.status === 200) {
                            Toaster.fire({
                                icon: 'success',
                                text: 'Transaction success',
                                position: 'top-right'
                            })
                            handlePrint(dataTrx)
                            clearPayment()
                            handleCancelTrx()
                            setModalPayment(false)
                        } else {
                            Toaster.fire({
                                icon: 'error',
                                text: res.data.msg,
                                position: 'top-right'
                            })
                        }
                        setLoadSubmitPay(false)
                    })
                }
            }
        }
    }

    const handleClickSubmitPaymentSplit = async () => {
        if (totalPayment <= 0 || selectedEdc === null || selectedCard === null || lastDigitCard === '' || payCash === 0 || payCard === 0) {
            Toaster.fire({
                icon: 'error',
                title: 'Some are incomplete !',
                text: 'Maybe something is wrong, try checking again',
                position: 'top-right',
            })
        } else if (parseFloat(payCash) >= totalPayment || parseFloat(payCash) < 10000) {
            Toaster.fire({
                icon: 'error',
                title: 'Some are wrong value !',
                text: 'Cash must not be less than 10,000 and must not be greater than or equal to the payment amount',
                position: 'top-right',
            })
        } else {
            const msg = window.confirm('Is everything correct?')
            if (msg) {
                setLoadSubmitPay(true)
                if (isReturn === 'Y') {
                    const cfm = window.confirm('You have check for Return Deposit, are you sure this transaction is used to exchange from return?\nIf not, please uncheck the deposit from this payment form')
                    if (cfm) {
                        const header = {
                            trx_code: trxCode,
                            id_open: openPos.id_open,
                            kasir_id: openPos.id_user,
                            kasir: kasir,
                            trx_time: currentTime,
                            trx_date: trxDate,
                            sales: agent,
                            sales_id: agentId,
                            store_name: openPos.showroom_name,
                            store_addr: openPos.address,
                            store_id: openPos.id_showroom,
                            id_warehouse: openPos.id_warehouse,
                            total_trx: grandTotal,
                            total_payment: totalPayment,
                            total_qty: selectedItems.reduce((total, item) => total + parseInt(item.qty_order), 0),
                            is_return: isReturn,
                            return_code: returnCode,
                        }
                        const detail = selectedItems
                        const dataPayment = {
                            grand_total: grandTotal,
                            deposit: returnDeposit,
                            total_payment: totalPayment,
                            paid: parseInt(paidAmount),
                            change: payChange,
                            pay_type: "split",
                            total_voucher: payVoucher,
                            voucher: selectedVoucher,
                            card: selectedCard,
                            edc: selectedEdc,
                            last_digit: lastDigitCard,
                            ref: refNumber,
                            pay_cash: parseFloat(payCash),
                            pay_card: payCard,
                        }
                        const card = cardList

                        const dataTrx = {
                            transaction: {
                                header: header,
                                detail: detail,
                            },
                            card: card,
                            payment: dataPayment,
                            action: "Entry",
                        }

                        setPayment(dataTrx)

                        await axios({
                            method: "POST",
                            url: `${host}/pos/index.php?action=createTransaction`,
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                            },
                            data: dataTrx
                        }).then(res => {
                            if (res.data.status === 200) {
                                Toaster.fire({
                                    icon: 'success',
                                    text: 'Transaction success',
                                    position: 'top-right'
                                })
                                handlePrint(dataTrx)
                                clearPayment()
                                handleCancelTrx()
                                setModalPayment(false)
                            } else {
                                Toaster.fire({
                                    icon: 'error',
                                    text: res.data.msg,
                                    position: 'top-right'
                                })
                            }
                            setLoadSubmitPay(false)
                        }).finally(() => setIsSplit(false))
                    }
                } else {
                    const header = {
                        trx_code: trxCode,
                        id_open: openPos.id_open,
                        kasir_id: openPos.id_user,
                        kasir: kasir,
                        trx_time: currentTime,
                        trx_date: trxDate,
                        sales: agent,
                        sales_id: agentId,
                        store_name: openPos.showroom_name,
                        store_addr: openPos.address,
                        store_id: openPos.id_showroom,
                        id_warehouse: openPos.id_warehouse,
                        total_trx: grandTotal,
                        total_payment: totalPayment,
                        total_qty: selectedItems.reduce((total, item) => total + parseInt(item.qty_order), 0),
                        is_return: isReturn,
                        return_code: returnCode,
                    }
                    const detail = selectedItems
                    const dataPayment = {
                        grand_total: grandTotal,
                        deposit: returnDeposit,
                        total_payment: totalPayment,
                        paid: parseInt(paidAmount),
                        change: payChange,
                        pay_type: "split",
                        total_voucher: payVoucher,
                        voucher: selectedVoucher,
                        card: selectedCard,
                        edc: selectedEdc,
                        last_digit: lastDigitCard,
                        ref: refNumber,
                        pay_cash: parseFloat(payCash),
                        pay_card: payCard,
                    }
                    const card = cardList

                    const dataTrx = {
                        transaction: {
                            header: header,
                            detail: detail,
                        },
                        card: card,
                        payment: dataPayment,
                        action: "Entry",
                    }

                    setPayment(dataTrx)

                    await axios({
                        method: "POST",
                        url: `${host}/pos/index.php?action=createTransaction`,
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                        },
                        data: dataTrx
                    }).then(res => {
                        if (res.data.status === 200) {
                            Toaster.fire({
                                icon: 'success',
                                text: 'Transaction success',
                                position: 'top-right'
                            })
                            handlePrint(dataTrx)
                            clearPayment()
                            handleCancelTrx()
                            setModalPayment(false)
                        } else {
                            Toaster.fire({
                                icon: 'error',
                                text: res.data.msg,
                                position: 'top-right'
                            })
                        }
                        setLoadSubmitPay(false)
                    }).finally(() => setIsSplit(false))
                }
            }
        }
    }

    const handleSubmitReturnCode = () => {
        getReturnData(returnCode)
    }

    const handleClickSumItem = () => {
        setFilteredStock(sumItem)
        setCurrentPage(0)
    }

    const handleClickDscItem = () => {
        setFilteredStock(sumDscItem)
        setCurrentPage(0)
    }

    const handleClickNewItem = () => {
        setFilteredStock(sumNewItem)
        setCurrentPage(0)
    }

    const handleClickOutItem = () => {
        setFilteredStock(sumOos)
        setCurrentPage(0)
    }

    const handleChangeIsReturn = (data) => {
        data.checked ? setIsReturn('Y') : setIsReturn('N')
        setReturnData([])
        setReturnCode('')
        setReturnDeposit(0)
    }

    const handleChangeBarcode = (barcode) => {
        setDataFromScan(stock.filter(item => item.barcode === barcode)[0])
        setBarcode(barcode)
    }

    const handleClickArt = (art) => {
        try {
            window.open(`https://www.google.co.id/search?q=${art}`, '_blank')
        } catch (error) {
            alert(error.message)
        }
    }

    const handleSubmitBarcode = () => {
        if (dataFromScan) {
            const item = dataFromScan
            const exist = selectedItems?.some(i => i.article === item.article && i.size === item.size);
            if (!exist) {
                const newItem = {
                    ...item,
                    qty_order: 1,
                    additional_discount_value: 0,
                    additional_discount: '',
                    additional_discount_description: '',
                    before_add_dsc_price: item.final_price,
                    additional_discount_type: '',
                    additional_discount_option: '',
                }
                setSelectedItems([...selectedItems, newItem])
            } else {
                const updatedItems = selectedItems.map((selectedItem) => {
                    if (selectedItem.article === item.article && selectedItem.size === item.size) {
                        return {
                            ...selectedItem,
                            qty_order: selectedItem.qty_order ? selectedItem.qty_order + 1 : 2,
                        };
                    }
                    return selectedItem
                });
                setSelectedItems(updatedItems)
            }
        } else {
            alert('No data')
        }
        setDataFromScan(null)
        setBarcode('')
    }

    const handleTabSelect = (key) => {
        if (key === "3") {
            setIsSplit(true)
        } else {
            setIsSplit(false)
        }
    };

    useEffect(() => {
        const totalAmountUsed = itemData.reduce((acc, item) => acc + (item.amount || 0), 0);
    }, [itemData]);

    useEffect(() => {
        if (isNewRowAdded && cardInputRef.current) {
            cardInputRef.current.focus();
            setIsNewRowAdded(false); // Reset flag setelah fokus diberikan
        }
    }, [isNewRowAdded])

    useEffect(() => {
        if (modalBarcode && inputRef.current) {
            inputRef.current.focus()
        }
    }, [modalBarcode])

    useEffect(() => {
        const sumVoucher = selectedVoucher.reduce((total, cur) => total + parseFloat(cur.nominal), 0)
        let cardAmount = (totalTrx - sumVoucher) - payCash
        setPayCard(cardAmount)
    }, [payCash])

    useEffect(() => {
        let newGrandTotal = 0
        if (returnData.length > 0 && returnDeposit > 0) {
            newGrandTotal = grandTotal - parseFloat(returnDeposit)
            setTotalPayment(newGrandTotal)
        } else {
            newGrandTotal = grandTotal - 0
            setTotalPayment(newGrandTotal)
        }
        setTotalPayment(newGrandTotal - payVoucher)
    }, [grandTotal, returnDeposit])

    // useEffect(() => {
    //     if (selectedVoucher?.length > 0) {
    //         const total = selectedVoucher.reduce((total, item) => {
    //             const nominal = parseInt(item.nominal, 10)
    //             return total + (isNaN(nominal) ? 0 : nominal)
    //         }, 0)
    //         setPayVoucher(total)
    //         if (returnData.length > 0 && returnDeposit > 0) {
    //             setTotalPayment(grandTotal - total - returnDeposit)
    //         } else {
    //             setTotalPayment(grandTotal - total)
    //         }
    //     } else {
    //         setPayVoucher(0)
    //         if (returnData) {
    //             setTotalPayment(grandTotal - returnDeposit)
    //         } else {
    //             setTotalPayment(grandTotal)
    //         }
    //     }
    // }, [selectedVoucher])

    useEffect(() => {
        if (selectedVoucher?.length > 0) {
            const total = selectedVoucher.reduce((total, item) => {
                const nominal = parseInt(item.nominal, 10);
                const used = item.used || 1; // Default to 1 if 'used' is not defined
                return total + (isNaN(nominal) ? 0 : nominal * used);
            }, 0);
            setPayVoucher(total);
            let newTotalPayment = grandTotal - total;
            if (returnData.length > 0 && returnDeposit > 0) {
                newTotalPayment -= returnDeposit;
            }
            setTotalPayment(newTotalPayment < 0 ? 0 : newTotalPayment);
        } else {
            setPayVoucher(0);
            let newTotalPayment = grandTotal;
            if (returnData) {
                newTotalPayment -= returnDeposit;
            }
            setTotalPayment(newTotalPayment < 0 ? 0 : newTotalPayment);
        }
    }, [selectedVoucher, grandTotal, returnDeposit, returnData]);

    useEffect(() => {
        setCurentTotal(totalPayment)
    }, [totalPayment])

    useEffect(() => {
        const filteredData = stock.filter(item => {
            if (searchValue === '') {
                return true;
            }
            return item.description?.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.article?.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.barcode?.toLowerCase().includes(searchValue.toLowerCase());
        });
        setFilteredStock(filteredData);
        setCurrentPage(0); // Reset ke halaman pertama setelah pencarian
    }, [searchValue, stock]);

    useEffect(() => {
        const discountExist = selectedItems?.some(item => item.additional_discount_value > 0)
        const newSubTotal = selectedItems?.reduce((a, b) => {
            const finalPrice = isNaN(b.final_price) ? 0 : parseFloat(b.final_price)
            const quantity = isNaN(b.qty_order) ? 0 : parseInt(b.qty_order)
            return a + (finalPrice * quantity)
        }, 0)
        setTotalTrx(newSubTotal);
        setTotalQty(selectedItems?.reduce((a, b) => a + (isNaN(b.qty_order) ? 0 : parseInt(b.qty_order)), 0))
        setTotalAdditionalDsc(selectedItems?.reduce((total, item) => total + parseFloat(item.additional_discount_value), 0))
        // setGrandTotal(newSubTotal + selectedItems?.reduce((total, item) => total + parseFloat(item.additional_discount_value), 0))
        setGrandTotal(newSubTotal)
        if (selectedItems?.length === 0) {
            setAddDicount(0)
            setAddDesc('')
            setAddDicountItem(0)
            setAddDescItem('')
            setDiscountType('percent')
            setUsedDiscount('')
        }
        if (!discountExist) {
            setUsedDiscount('')
        }
    }, [selectedItems])
    console.log(itemData)
    return (
        <>
            <Container fluid style={{ background: 'whitesmoke', minHeight: '100%' }}>
                <Dimmer active={loadStock} inverted>
                    <Loader inverted>Loading Stock...</Loader>
                </Dimmer>

                <Row className="d-flex" style={{ minHeight: '100vh' }}>
                    <Col xl={8} lg={7} style={{ background: 'whitesmoke', minHeight: '100%' }}>
                        <Row>
                            <Col lg={12}>
                                <Row>
                                    <Col lg={12} md={12} sm={12} className="mb-3 mt-3">
                                        <div
                                            className="ms-2 me-2 mt-2 mb-2"
                                            style={{
                                                // borderLeft: '4px solid #13a89e',
                                                borderLeft: '4px solid #4183c4',
                                                background: 'white',
                                                borderRadius: '0.5em 0 0 0.5em',
                                                padding: '1.5em',
                                                // backgroundImage: `url(${backgroundEvent})`,
                                                // backgroundSize: 'cover',
                                                // backgroundPosition: 'center', 
                                                // backgroundRepeat: 'no-repeat',
                                            }}
                                        >
                                            <Row >
                                                <Col lg={8} className="mb-3">
                                                    {
                                                        trxState === 'inactive' ?
                                                            <Button content="New Transaction" circular size="medium" color="blue" className="mb-3" onClick={handleClickNewTrx} labelPosition="right" icon="add to cart" />
                                                            :
                                                            <Button content="Cancel Transaction" circular size="medium" color="orange" className="mb-3" labelPosition="right" icon="close" onClick={handleCancelTrx} />
                                                    }
                                                </Col>
                                                <Col lg={4} className="text-center">
                                                    <div style={{ backgroundColor: 'rgb(232, 232, 232)', padding: '4px', borderRadius: '5px', borderBottom: '3px solid #2185d0' }}>
                                                        <div className="fw-bold fs-5 text-truncate" style={{ width: '100%', color: '#2185d0' }}>{openPos?.showroom_name}</div>
                                                        <div className="text-truncate" style={{ width: '100%' }}>{openPos?.address}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col lg={3} md={4} sm={2}>
                                                    <Input
                                                        className="fw-bold"
                                                        fluid
                                                        type="text"
                                                        icon={{ name: 'file alternate outline', color: 'orange' }}
                                                        iconPosition="left"
                                                        value={trxCode}
                                                        readOnly
                                                    />
                                                </Col>
                                                <Col lg={3} md={6}>
                                                    <Input
                                                        className="fw-bold"
                                                        // style={{ border: '2px solid #13a89e', borderRadius: '5px' }}
                                                        fluid
                                                        value={kasir}
                                                        icon={{ name: 'user', color: 'orange' }}
                                                        iconPosition="left"
                                                        readOnly
                                                    />
                                                </Col>
                                                <Col lg={3}>
                                                    <Input
                                                        className="fw-bold"
                                                        // style={{ border: '2px solid #13a89e', borderRadius: '5px' }}
                                                        fluid
                                                        type="date"
                                                        icon={{ name: 'calendar alternate outline', color: 'orange' }}
                                                        iconPosition="left"
                                                        value={trxDate}
                                                        disabled={!startTrx}
                                                    />
                                                </Col>
                                                <Col lg={3}>
                                                    <Dropdown
                                                        className="fw-bold icon"
                                                        basic
                                                        button
                                                        fluid
                                                        floating
                                                        labeled
                                                        icon={{ name: 'user outline', color: 'orange' }}
                                                        value={agentId}
                                                        options={agentOpt}
                                                        placeholder="Sales Agent"
                                                        disabled={!startTrx}
                                                        onChange={(e, { value }) => {
                                                            setAgent(agentOpt.find(i => i.value === value)?.text)
                                                            setAgentId(value)
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} style={{ zIndex: '0' }}>
                                        <div className="ms-2 me-2 mb-2" style={{ borderLeft: '4px solid #4183c4', background: 'white', borderRadius: '0.5em 0 0 0.5em', padding: '1.5em', overflowX: 'auto' }}>
                                            <Row className="mb-1">
                                                <Col xl={7} lg={12} md={12} sm={12} xs={12} className="mb-3">
                                                    <Input
                                                        type="text"
                                                        size="small"
                                                        style={{ fontWeight: 'bold', width: '15em' }}
                                                        // style={{ border: '2px solid #13a89e', borderRadius: '5px' }} 
                                                        icon={{ name: 'search', color: 'orange' }} value={searchValue}
                                                        onChange={(e) => handleChangeSearch(e.target.value)}
                                                        placeholder="Search Article | Name"
                                                    />

                                                    <Popup content="Select item using barcode scanner" size="tiny" trigger={
                                                        <Button
                                                            className="ms-3"
                                                            color="blue"
                                                            content="Scan Barcode"
                                                            icon={{ name: "barcode", color: 'blue' }}
                                                            basic size="small"
                                                            // style={{ border: '2px solid #13a89e', borderRadius: '5px' }} 
                                                            onClick={() => setModalBarcode(true)}
                                                            disabled={btnScan}
                                                        />
                                                    } />
                                                </Col>
                                                <Col xl={5} lg={12} md={12} sm={12} xs={12} className="d-flex justify-content-end mb-3">
                                                    <Stack direction="horizontal" gap={2}>
                                                        <Popup content="Show all items" size="tiny" trigger={
                                                            <Label icon={{ name: 'box', color: 'orange' }} style={{ cursor: 'pointer' }} content={sumItem.length} size="small" basic onClick={handleClickSumItem} />
                                                        } />
                                                        <Popup content="Show new items" size="tiny" trigger={
                                                            <Label icon={{ name: 'bookmark', color: 'orange' }} style={{ cursor: 'pointer' }} content={sumNewItem.length} size="small" basic onClick={handleClickNewItem} />
                                                        } />
                                                        <Popup content="Show discounted items" size="tiny" trigger={
                                                            <Label icon={{ name: 'tags', color: 'orange' }} style={{ cursor: 'pointer' }} content={sumDscItem.length} size="small" basic onClick={handleClickDscItem} />
                                                        } />
                                                        <Popup content="Show out of stock items" size="tiny" trigger={
                                                            <Label icon={{ name: 'ban', color: 'orange' }} style={{ cursor: 'pointer' }} content={sumOos.length} size="small" basic onClick={handleClickOutItem} />
                                                        } />
                                                    </Stack>
                                                </Col>
                                            </Row>
                                            <Table className="table-hover responsive">
                                                <thead style={{ fontSize: '0.9em' }}>
                                                    <tr style={{ borderBottom: '2px solid #2185d0' }}>
                                                        <th>New</th>
                                                        <th>Article</th>
                                                        <th>Description</th>
                                                        <th>Size</th>
                                                        <th>Qty</th>
                                                        <th>Discount</th>
                                                        <th className="text-end">Base Price</th>
                                                        <th className="text-end">Nett Price</th>
                                                        <th className="text-end">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ fontSize: '0.9em', fontWeight: 'bolder' }} className="align-middle">
                                                    {
                                                        currentData?.map((item, i) => {
                                                            const current = moment()
                                                            const newArrivalThreshold = current.clone().subtract(7, 'days')
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        {
                                                                            moment(item.createAt).isAfter(newArrivalThreshold) ? <Icon name="bookmark" color="orange" /> : <Icon name="bookmark outline" color="grey" />
                                                                        }
                                                                    </td>
                                                                    <td style={{ width: '50px' }}>
                                                                        <Popup content="Click here to search this product image at google.com" size="tiny" trigger={
                                                                            <div
                                                                                className="text-truncate"
                                                                                onClick={() => handleClickArt(item.article)}
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    transition: 'transform 0.3s ease, color 0.3s ease',
                                                                                }}
                                                                                onMouseOver={(e) => {
                                                                                    e.currentTarget.style.transform = 'scale(1.1)';
                                                                                    e.currentTarget.style.color = '#2185d0';
                                                                                }}
                                                                                onMouseOut={(e) => {
                                                                                    e.currentTarget.style.transform = 'scale(1)';
                                                                                    e.currentTarget.style.color = ''; // Kembali ke warna semula
                                                                                }}
                                                                            >
                                                                                {item.article}
                                                                            </div>
                                                                        } />
                                                                    </td>
                                                                    <td>
                                                                        <div style={{ width: '200px' }}>
                                                                            <Popup content={item.description} size="tiny" trigger={
                                                                                <div className="text-truncate">{item.description}</div>
                                                                            } />
                                                                        </div>
                                                                    </td>
                                                                    <td><div className="fw-bold">{item.size}</div></td>
                                                                    <td>{item.qty}</td>
                                                                    <td>
                                                                        {
                                                                            item.start_date <= currentDate && item.end_date >= currentDate && (item.discount_state !== 'null' && item.discount_state !== '0') ?
                                                                                <>
                                                                                    <span><Icon name="tags" color="orange" size="small" /></span>
                                                                                    <span className="ms-1">{item.discount_value}%</span>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <span><Icon name="tags" color="grey" size="small" /></span>
                                                                                    <span className="text-muted ms-1">0%</span>
                                                                                </>
                                                                        }
                                                                    </td>
                                                                    <td className="text-end">{curencyNoSym.format(item.price)}</td>
                                                                    <td className="text-end">
                                                                        <span className="justify-content-end">{curencyNoSym.format(item.final_price)}</span>
                                                                    </td>
                                                                    <td className="text-end">
                                                                        <Button.Group size="mini" color={item.qty > 0 ? 'blue' : 'grey'}>
                                                                            <Popup content="Add to cart" trigger={
                                                                                <Button
                                                                                    icon="plus"
                                                                                    onClick={() => handleSelectItem(item)}
                                                                                    disabled={item.qty > 0 ? false : true}
                                                                                />
                                                                            } />
                                                                        </Button.Group>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            <div>
                                                <Button className="me-3" icon="backward" size="medium" color="blue" compact onClick={prevPage} disabled={currentPage === 0 || !startTrx} />
                                                <span>Page {currentPage + 1} of {totalPages}</span>
                                                <Button className="ms-3" color="blue" icon="forward" size="medium" compact onClick={nextPage} disabled={currentPage === totalPages - 1 || !startTrx} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={12} className="ms-2">
                                {/* <div style={{ position: 'absolute', bottom: '2.5em' }}>
                                    <Stack direction="horizontal" gap={3}>
                                        <div className="fw-bold">(F11)</div>
                                        <div>Fullscreen Mode</div>
                                    </Stack>
                                </div> */}
                                <div className="text-end pe-3"><small>&copy;{currentYear}. Developed By <span><a href="https://www.instagram.com/satriaharri/" target="_blank">HS.</a></span></small></div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={4} lg={5} style={{ background: '#ffff', minHeight: '100%' }} className="d-flex flex-column">
                        <Row>
                            <Col lg={3} className="mb-4 mt-3 fs-5">
                                <span className="me-1"><Icon name="cart" color="orange" /></span>
                                <span className="fw-bold">Cart</span>
                            </Col>
                            <Col lg={9} className="mb-4 mt-2 fs-5 text-end">
                                {
                                    usedDiscount === '' && usedDiscount !== 'item' ?
                                        <Popup content="Adding discount for this transaction" trigger={
                                            <Button
                                                content="Additional Discount"
                                                icon="plus"
                                                color="blue"
                                                labelPosition="right"
                                                onClick={handleAddDiscount}
                                                disabled={selectedItems?.length === 0 ? true : false}
                                            />
                                        } />
                                        :
                                        <Popup content="Remove added discount from this transaction" trigger={
                                            <Button
                                                content="Remove Additional Discount"
                                                icon="trash alternte"
                                                labelPosition="right"
                                                style={{ border: '2px solid blue', background: '#15D1C4' }}
                                                onClick={handleRemoveAddDiscount}
                                            />
                                        } />
                                }
                            </Col>
                            <Col lg={12} className="d-flex flex-column h-100" style={{ overflowY: 'auto', maxHeight: '26em' }}>
                                <Transition.Group duration={500} animation="swing up" >
                                    {selectedItems?.length > 0 ? (
                                        selectedItems.map((item, i) => (
                                            <div key={i}>
                                            <Stack direction="horizontal" gap={3} style={{ fontSize: '0.9em', background: 'whitesmoke', borderLeft: '4px solid #2185d0', padding: '10px', borderRadius: '5px' }} className="mb-3">
                                                    <div>{i + 1}. </div>
                                                    <Stack direction="vertical" gap={0}>
                                                        <div style={{ width: '180px' }}>
                                                            <div>
                                                                <span style={{ width: '50%' }} className="me-3">{item.article}</span>
                                                                <span>
                                                                    {
                                                                        item.additional_discount_value > 0 ?
                                                                            item.additional_discount_type === 'percent' ?
                                                                                <Popup content={`Additional Discount ${curencyNoSym.format(item.additional_discount)}% (${item.additional_discount_description})`} size="tiny" trigger={
                                                                                    <Label size="tiny" color="blue">
                                                                                        {curencyNoSym.format(item.additional_discount)}%
                                                                                        <Icon name="delete" onClick={() => handleRemoveDscItem(item)} />
                                                                                    </Label>
                                                                                } />
                                                                                :
                                                                                <Popup content={`Additional Discount ${curencyNoSym.format(item.additional_discount)}`} size="tiny" trigger={
                                                                                    <Label size="tiny" color="blue">
                                                                                        {curencyNoSym.format(item.additional_discount)}
                                                                                        <Icon name="delete" onClick={() => handleRemoveDscItem(item)} />
                                                                                    </Label>
                                                                                } />
                                                                            : <></>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <Popup content={item.description} size="tiny" trigger={
                                                                <div className="fw-bold text-truncate" style={{ color: '#2185d0' }}>{item.description}</div>
                                                            } />
                                                            <div className="fw-bold">
                                                                <span className="me-3">{curency.format(item.final_price)}</span>
                                                                {item.additional_discount_value > 0 ?
                                                                    <span className="me-3 text-decoration-line-through text-muted">{curency.format(item.before_add_dsc_price)}</span>
                                                                    :
                                                                    <></>
                                                                }
                                                                <div style={{ color: 'coral' }}>Size : {item.size}</div>
                                                            </div>
                                                        </div>
                                                    </Stack>
                                                    <div>
                                                        <QtyInput value={item.qty_order} onChange={(e) => handleQuantityChange(e, item)} max={item.qty} />
                                                    </div>
                                                    <div>
                                                        <Popup content="Adding discount for this item" size="tiny" trigger={
                                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }} className="mb-2">
                                                                <Button icon="tags" color="blue" onClick={() => handleClickAddDscItem(item)} disabled={item.additional_discount_value > 0 ? true : false} />
                                                                {/* <Icon name="tags" onClick={() => handleClickAddDscItem(item)} disabled={item.additional_discount_value > 0 ? true : false} /> */}
                                                            </div>
                                                        } />
                                                        <Popup content="Remove" size="tiny" trigger={
                                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}>
                                                                <Button icon="trash alternate" color="orange" onClick={() => handleRemoveSelected(item)} />
                                                                {/* <Icon name="close" onClick={() => handleRemoveSelected(item)} /> */}
                                                            </div>
                                                        } />
                                                    </div>
                                            </Stack>
                                            </div>
                                        ))
                                    ) : (
                                        <div style={{ textAlign: 'center', padding: '10px', borderRadius: '5px', border: '2px dashed coral' }}>
                                            <Icon name="exclamation circle" color="orange" />
                                            <span>No items selected.</span>
                                        </div>
                                    )}
                                </Transition.Group>
                            </Col>
                        </Row>
                        <Row className="mt-auto mb-5">
                            <Col>
                                <div style={{ border: '3px dashed #2185d0', borderRadius: '5px', minHeight: '200px', padding: '10px' }}>
                                    <Row>
                                        <Col lg={12} className="mb-3">
                                            <span className="me-1"><Icon name="file alternate outline" color="orange"/></span>
                                            <span className="fs-6 fw-bold">Summary</span>
                                        </Col>
                                        <Col lg={6} className="ms-4 me-4 fs-6">
                                            <div>Total</div>
                                            <div>Total Qty</div>
                                            <div className="mb-3">Additional Discount</div>
                                            <div className="fw-bold">Grand Total</div>
                                        </Col>
                                        <Col lg={4} className="text-end fw-bold mb-3">
                                            <div>{curencyNoSym.format(totalTrx)}</div>
                                            <div>{totalQty}</div>
                                            <div className="mb-3">{curencyNoSym.format(totalAdditionalDsc)}</div>
                                            <div className="fw-bold fs-5">{curencyNoSym.format(grandTotal)}</div>
                                        </Col>
                                        <Col lg={12} >
                                            <Button
                                                color="blue"
                                                size="big"
                                                fluid
                                                content="Continue to payment"
                                                icon="arrow alternate circle right outline"
                                                labelPosition="right"
                                                onClick={handleClickPayment}
                                                disabled={!startTrx}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAddDiscount} onClose={() => setModalAddDiscount(false)} overflow={false} size="xs" backdrop="static">
                <Modal.Header>
                    <Modal.Title>Additional Discount</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmDscTrx" onSubmit={handleSubmitDscTrx}>
                        <Form.Group>
                            <Form.Radio
                                label="Percent"
                                value="percent"
                                checked={discountType === 'percent'}
                                onChange={handleChangeDscType}
                            />
                            <Form.Radio
                                label="Nominal"
                                value="nominal"
                                checked={discountType === 'nominal'}
                                onChange={handleChangeDscType}
                            />
                        </Form.Group>
                        <Form.Field>
                            <label>Discount Value</label>
                            <Input type="text" max={discountType === 'percent' && 99} maxLength={discountType === 'percent' && 2} min={1} value={addDiscount} onChange={(e) => setAddDicount(e.target.value)} required />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input text="text" label="Description" value={addDesc} onChange={(e) => setAddDesc(e.target.value)} required />
                        </Form.Field>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="plus" form="frmDscTrx" type="submit" color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalDscPerItem} onClose={() => setModalDscPerItem(false)} overflow={false} size="xs" backdrop="static">
                <Modal.Header>
                    <Modal.Title>Additional Discount Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmAddDscItem" onSubmit={handleSubmitDscItem}>
                        <Form.Group>
                            <Form.Radio
                                label="Percent"
                                value="percent"
                                checked={discountType === 'percent'}
                                onChange={handleChangeDscType}
                            />
                            <Form.Radio
                                label="Nominal"
                                value="nominal"
                                checked={discountType === 'nominal'}
                                onChange={handleChangeDscType}
                            />
                        </Form.Group>
                        <Form.Field>
                            <label>Discount Value</label>
                            <Input type="text" max={discountType === 'percent' && 99} maxLength={discountType === 'percent' && 2} min={1} value={addDiscountItem} onChange={(e) => handleChangeAddDscItem(e.target.value)} />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input type="text" label="Description" onChange={(e) => setAddDescItem(e.target.value)} required />
                        </Form.Field>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="plus" form="frmAddDscItem" color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalPayment} onClose={handleClosePayment} overflow={false} backdrop="static" size="xs" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                <Modal.Header>
                    <Modal.Title>Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <Tabs defaultActiveKey="1" appearance="subtle" onSelect={handleTabSelect}>
                                <Tabs.Tab eventKey="1" title="Cash" icon={<BsCashCoin />}>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="">Grand Total :</div>
                                        </Col>
                                        <Col lg={12} className="text-end mb-2">
                                            <div className="fw-bold display-4">{curencyNoSym.format(totalTrx)}</div>
                                        </Col>
                                        <Col lg={12}>
                                            <div style={{ overflowX: 'auto' }}>
                                                <TagGroup className="mb-2" >
                                                    {
                                                        selectedVoucher?.map((item, i) => (
                                                            <Tag closable style={{ border: '1px dashed blue' }} onClick={() => handleRemoveVoucher(item)}><span style={{ color: 'blue' }} className="me-1">{item.desc}</span><span style={{ color: 'black' }}>({item.used})</span></Tag>
                                                        ))
                                                    }
                                                </TagGroup>
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <label className="mb-1">Voucher</label>
                                            <Input
                                                className="mb-3"
                                                type="text"
                                                iconPosition="left"
                                                icon={{ name: "certificate", color: 'blue' }}
                                                placeholder="Voucher"
                                                fluid
                                                labelPosition="right"
                                                readOnly
                                                value={curencyNoSym.format(payVoucher)}
                                                action={
                                                    <Button icon={{ name: 'ellipsis horizontal', color: 'blue' }} basic onClick={handleClickVoucher} />
                                                }
                                            />
                                            <Row>
                                                <Col lg={6}>
                                                    <label className="mb-1">Total Payment</label>
                                                    <Popup content="Jumlah yang harus di bayar" size="tiny" trigger={
                                                        <Input
                                                            className="mb-3"
                                                            type="text"
                                                            iconPosition="left"
                                                            icon={{ name: "money bill alternate outline", color: 'blue' }}
                                                            placeholder="Paid amount"
                                                            fluid
                                                            value={curencyNoSym.format(totalPayment)}
                                                            readOnly
                                                        />
                                                    } />
                                                </Col>
                                                <Col lg={6}>
                                                    <Stack direction="horizontal">
                                                        <label className="mb-1 me-2">Deposit</label>
                                                        <div style={{ width: '100%' }} className="d-flex justify-content-end mb-1">
                                                            <Popup content={isReturn === 'Y' ? "Uncheck ini untuk clear retur" : "Check ini terlebih dahulu untuk aktifkan tambah kode retur"} size="tiny" trigger={
                                                                <Checkbox onChange={(e, data) => handleChangeIsReturn(data)} checked={isReturn === 'Y' ? true : false} />}
                                                            />
                                                        </div>
                                                    </Stack>
                                                    <Popup content="Deposit dari transaksi yang diretur" size="tiny" trigger={
                                                        <Input
                                                            className="mb-3"
                                                            type="text"
                                                            iconPosition="left"
                                                            icon={{ name: "money bill alternate outline", color: 'blue' }}
                                                            placeholder="Deposit"
                                                            fluid
                                                            readOnly
                                                            value={curencyNoSym.format(returnDeposit)}
                                                            action={
                                                                <Button icon={{ name: 'retweet', color: 'blue' }} basic onClick={handleClickReturn} disabled={isReturn === 'Y' ? false : true} />
                                                            }
                                                        />
                                                    } />
                                                </Col>
                                            </Row>
                                            <label className="mb-1">Paid</label>
                                            <Popup content="Uang dari customer" size="tiny" trigger={
                                                <Input
                                                    className="mb-3"
                                                    type="text"
                                                    iconPosition="left"
                                                    icon={{ name: 'money', color: 'blue' }}
                                                    placeholder="Paid amount"
                                                    fluid
                                                    value={formatCurrency(paidAmount)}
                                                    onChange={(e) => handleChangePayAmount(e.target.value)}
                                                />
                                            } />
                                            <label className="mb-1">Change</label>
                                            <Popup content="Uang kembalian" size="tiny" trigger={
                                                <Input
                                                    className="mb-3"
                                                    fluid
                                                    type="text"
                                                    iconPosition="left"
                                                    icon={{ name: 'exchange', color: "blue" }}
                                                    placeholder="Change"
                                                    value={curencyNoSym.format(payChange)}
                                                    readOnly
                                                />
                                            } />
                                        </Col>
                                        <Col lg={12} className="mt-3">
                                            <Button
                                                content="Process Payment"
                                                size="large"
                                                fluid
                                                icon="arrow alternate circle right outline"
                                                labelPosition="right"
                                                color="blue"
                                                onClick={handleClickSubmitPaymentCash}
                                                loading={loadSubmitPay}
                                                disabled={loadSubmitPay}
                                            />
                                        </Col>
                                    </Row>
                                </Tabs.Tab>
                                <Tabs.Tab eventKey="2" title="Card" icon={<BsCreditCard />}>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="">Grand Total :</div>
                                        </Col>
                                        <Col lg={12} className="text-end mb-2">
                                            <div className="fw-bold display-4">{curencyNoSym.format(totalTrx)}</div>
                                        </Col>
                                        <Col lg={12}>
                                            <div style={{ overflowX: 'auto' }}>
                                                <TagGroup className="mb-2" >
                                                    {
                                                        selectedVoucher?.map((item, i) => (
                                                            <Tag key={i} closable style={{ border: '1px dashed blue' }} onClick={() => handleRemoveVoucher(item)}>{item.desc}</Tag>
                                                        ))
                                                    }
                                                </TagGroup>
                                            </div>
                                        </Col>
                                        <Col lg={12}>
                                            <label className="mb-1">Voucher</label>
                                            <Input
                                                className="mb-3"
                                                type="text"
                                                iconPosition="left"
                                                icon={{ name: 'certificate', color: 'blue' }}
                                                placeholder="Voucher"
                                                fluid
                                                labelPosition="right"
                                                readOnly
                                                value={curencyNoSym.format(payVoucher)}
                                                action={
                                                    <Button icon={{ name: 'ellipsis horizontal', color: "blue" }} basic onClick={handleClickVoucher} />
                                                }
                                            />
                                            <Row>
                                                <Col lg={6}>
                                                    <label className="mb-1">Total Payment</label>
                                                    <Popup content="Jumlah yang harus di bayar" size="tiny" trigger={
                                                        <Input
                                                            className="mb-3"
                                                            type="text"
                                                            iconPosition="left"
                                                            icon={{ name: 'money bill alternate outline', color: 'blue' }}
                                                            placeholder="Paid amount"
                                                            fluid
                                                            value={curencyNoSym.format(totalPayment)}
                                                            readOnly
                                                        />
                                                    } />
                                                </Col>
                                                <Col lg={6}>
                                                    <Stack direction="horizontal">
                                                        <label className="mb-1 me-2">Deposit</label>
                                                        <div style={{ width: '100%' }} className="d-flex justify-content-end mb-1">
                                                            <Popup content={isReturn === 'Y' ? "Uncheck ini untuk clear retur" : "Check ini terlebih dahulu untuk aktifkan tambah kode retur"} size="tiny" trigger={
                                                                <Checkbox onChange={(e, data) => handleChangeIsReturn(data)} checked={isReturn === 'Y' ? true : false} />}
                                                            />
                                                        </div>
                                                    </Stack>
                                                    <Popup content="Deposit dari transaksi yang diretur" size="tiny" trigger={
                                                        <Input
                                                            className="mb-3"
                                                            type="text"
                                                            iconPosition="left"
                                                            icon={{ name: 'money bill alternate outline', color: 'blue' }}
                                                            placeholder="Deposit"
                                                            fluid
                                                            readOnly
                                                            value={curencyNoSym.format(returnDeposit)}
                                                            action={
                                                                <Button icon={{ name: 'retweet', color: 'blue' }} basic onClick={handleClickReturn} disabled={isReturn === 'Y' ? false : true} />
                                                            }
                                                        />
                                                    } />
                                                </Col>
                                            </Row>
                                            {/* <label className="mb-1">EDC</label> */}
                                            {/* <Input
                                                className="mb-3"
                                                type="text"
                                                iconPosition="left"
                                                icon="mobile alternate"
                                                placeholder="EDC used"
                                                fluid
                                                labelPosition="right"
                                                readOnly
                                                value={selectedEdc?.desc}
                                                action={
                                                    <Button icon="ellipsis horizontal" basic onClick={handleClickEdc} />
                                                }
                                            /> */}
                                            <Form.Field className="mb-3">
                                                <label className="mb-1">Card</label>
                                                <Input
                                                    type="text"
                                                    iconPosition="left"
                                                    icon={{ name: 'payment', color: 'blue' }}
                                                    placeholder="Card"
                                                    fluid
                                                    labelPosition="right"
                                                    readOnly
                                                    value={cardList.length !== 0 ? cardList.length > 1 ? `(${cardList.map(i => i.description).join("), (")})` : cardList[0]?.description : ''}
                                                    action={
                                                        <Button icon={{ name: 'ellipsis horizontal', color: "blue" }} basic onClick={handleClickCard} />
                                                    }
                                                />
                                                <small className="text-muted">You can apply multiple card</small>
                                            </Form.Field>
                                            {/* <Row>
                                                <Col lg={6}>
                                                    <label className="mb-1">Last 4 Digit</label>
                                                    <Input
                                                        maxLength={4}
                                                        className="mb-3"
                                                        type="text"
                                                        iconPosition="left"
                                                        icon="payment outline"
                                                        placeholder="last 4 digit card number"
                                                        fluid
                                                        value={lastDigitCard}
                                                        onChange={(e) => setLastDigitCard(e.target.value)}
                                                    />
                                                </Col>
                                                <Col lg={6}>
                                                    <label className="mb-1">Reference</label>
                                                    <Input
                                                        maxLength={50}
                                                        className="mb-3"
                                                        type="text"
                                                        iconPosition="left"
                                                        icon="file alternate"
                                                        placeholder="6 digit reference number"
                                                        fluid
                                                        value={refNumber}
                                                        onChange={(e) => setRefNumber(e.target.value)}
                                                    />
                                                </Col>
                                            </Row> */}
                                        </Col>
                                        <Col lg={12} className="mt-3">
                                            <Button
                                                content="Process Card Payment"
                                                size="large"
                                                fluid
                                                icon="arrow alternate circle right outline"
                                                labelPosition="right"
                                                color="blue"
                                                onClick={handleClickSubmitPaymentCard}
                                                loading={loadSubmitPay}
                                                disabled={loadSubmitPay}
                                            />
                                        </Col>
                                    </Row>
                                </Tabs.Tab>
                                <Tabs.Tab eventKey="3" title="Split Payment" icon={<BsCurrencyExchange />} >
                                    <Row>
                                        <Col lg={12}>
                                            <div className="">Grand Total :</div>
                                        </Col>
                                        <Col lg={12} className="text-end mb-2">
                                            <div className="fw-bold display-4">{curencyNoSym.format(totalTrx)}</div>
                                        </Col>
                                        <Col lg={12}>
                                            <div style={{ overflowX: 'auto' }}>
                                                <TagGroup className="mb-2" >
                                                    {
                                                        selectedVoucher?.map((item, i) => (
                                                            <Tag key={i} closable style={{ border: '1px dashed blue' }} onClick={() => handleRemoveVoucher(item)}>{item.desc}</Tag>
                                                        ))
                                                    }
                                                </TagGroup>
                                            </div>
                                        </Col>
                                        <Col lg={12} className="mb-3">
                                            <label className="mb-1">Voucher</label>
                                            <Input
                                                className="mb-3"
                                                type="text"
                                                iconPosition="left"
                                                icon={{ name: 'certificate', color: 'blue' }}
                                                placeholder="Voucher"
                                                fluid
                                                labelPosition="right"
                                                readOnly
                                                value={curencyNoSym.format(payVoucher)}
                                                action={
                                                    <Button icon={{ name: 'ellipsis horizontal', color: 'blue' }} basic onClick={handleClickVoucher} />
                                                }
                                            />
                                            <Row>
                                                <Col lg={6}>
                                                    <label className="mb-1">Total Payment</label>
                                                    <Popup content="Jumlah yang harus di bayar" size="tiny" trigger={
                                                        <Input
                                                            className="mb-3"
                                                            type="text"
                                                            iconPosition="left"
                                                            icon={{ name: "money bill alternate outline", color: "blue" }}
                                                            placeholder="Paid amount"
                                                            fluid
                                                            value={curencyNoSym.format(totalPayment)}
                                                            readOnly
                                                        />
                                                    } />
                                                </Col>
                                                <Col lg={6}>
                                                    <Stack direction="horizontal">
                                                        <label className="mb-1 me-2">Deposit</label>
                                                        <div style={{ width: '100%' }} className="d-flex justify-content-end mb-1">
                                                            <Popup content={isReturn === 'Y' ? "Uncheck ini untuk clear retur" : "Check ini terlebih dahulu untuk aktifkan tambah kode retur"} size="tiny" trigger={
                                                                <Checkbox onChange={(e, data) => handleChangeIsReturn(data)} checked={isReturn === 'Y' ? true : false} />}
                                                            />
                                                        </div>
                                                    </Stack>
                                                    <Popup content="Deposit dari transaksi yang diretur" size="tiny" trigger={
                                                        <Input
                                                            className="mb-3"
                                                            type="text"
                                                            iconPosition="left"
                                                            icon={{ name: "money bill alternate outline", color: "blue" }}
                                                            placeholder="Deposit"
                                                            fluid
                                                            readOnly
                                                            value={curencyNoSym.format(returnDeposit)}
                                                            action={
                                                                <Button icon={{ name: "retweet", color: 'blue' }} basic onClick={handleClickReturn} disabled={isReturn === 'Y' ? false : true} />
                                                            }
                                                        />
                                                    } />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={6}>
                                                    <label className="mb-1">Payment Cash</label>
                                                    <Popup content="Bayar cash" size="tiny" trigger={
                                                        <Input
                                                            className="mb-3"
                                                            type="text"
                                                            iconPosition="left"
                                                            icon={{ name: 'money', color: 'blue' }}
                                                            placeholder="Paid amount"
                                                            fluid
                                                            value={payCash}
                                                            onChange={(e) => setPayCash(e.target.value)}

                                                        />
                                                    } />
                                                </Col>
                                                <Col lg={6}>
                                                    <label className="mb-1">Payment Card</label>
                                                    <Popup content="Bayar dari kartu" size="tiny" trigger={
                                                        <Input
                                                            className="mb-3"
                                                            type="text"
                                                            iconPosition="left"
                                                            icon={{ name: 'money outline', color: 'blue' }}
                                                            placeholder="Paid amount"
                                                            fluid
                                                            value={formatCurrency(payCard.toString())}
                                                            readOnly
                                                        />
                                                    } />
                                                </Col>
                                            </Row>
                                            <Row>
                                                {/* <Col lg={6}>
                                                    <label className="mb-1">EDC</label>
                                                    <Input
                                                        className="mb-3"
                                                        type="text"
                                                        iconPosition="left"
                                                        icon={{name:'mobile alternate', color:'blue'}}
                                                        placeholder="EDC used"
                                                        fluid
                                                        labelPosition="right"
                                                        readOnly
                                                        value={selectedEdc?.desc}
                                                        action={
                                                            <Button icon="ellipsis horizontal" basic onClick={handleClickEdc} />
                                                        }
                                                    />
                                                </Col> */}
                                                <Col lg={12}>
                                                    <label className="mb-1">Card</label>
                                                    <Input
                                                        className="mb-3"
                                                        type="text"
                                                        iconPosition="left"
                                                        icon={{ name: 'payment', color: 'blue' }}
                                                        placeholder="Card"
                                                        fluid
                                                        labelPosition="right"
                                                        readOnly
                                                        // value={selectedCard?.desc}
                                                        value={cardList.length !== 0 ? cardList.length > 1 ? `(${cardList.map(i => i.description).join("), (")})` : cardList[0]?.description : ''}
                                                        action={
                                                            <Button icon={{ name: 'ellipsis horizontal', color: 'blue' }} basic onClick={handleClickCard} />
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col lg={6}>
                                                    <label className="mb-1">Last 4 Digit</label>
                                                    <Input
                                                        maxLength={4}
                                                        className="mb-3"
                                                        type="text"
                                                        iconPosition="left"
                                                        icon="payment outline"
                                                        placeholder="last 4 digit card number"
                                                        fluid
                                                        value={lastDigitCard}
                                                        onChange={(e) => setLastDigitCard(e.target.value)}
                                                    />
                                                </Col>
                                                <Col lg={6}>
                                                    <label className="mb-1">Reference</label>
                                                    <Input
                                                        maxLength={50}
                                                        className="mb-3"
                                                        type="text"
                                                        iconPosition="left"
                                                        icon="file alternate"
                                                        placeholder="6 digit reference number"
                                                        fluid
                                                        value={refNumber}
                                                        onChange={(e) => setRefNumber(e.target.value)}
                                                    />
                                                </Col>
                                            </Row> */}
                                        </Col>
                                        <Col lg={12}>
                                            <Button
                                                content="Process Split Payment"
                                                size="large"
                                                fluid
                                                icon="arrow alternate circle right outline"
                                                labelPosition="right"
                                                color="blue"
                                                onClick={handleClickSubmitPaymentSplit}
                                                loading={loadSubmitPay}
                                                disabled={loadSubmitPay}
                                            />
                                        </Col>
                                    </Row>
                                </Tabs.Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal >

            <Modal open={modalVoucher} onClose={() => setModalVoucher(false)} overflow={false} size="lg" children>
                <Modal.Header>
                    <Modal.Title>Available Voucher</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable data={voucherData} columns={columnsVoucher} pageRow={10} noData={voucherData.length === 0 ? true : false} />
                </Modal.Body>
            </Modal>

            <Modal open={modalCard} onClose={() => setModalCard(false)} overflow={false} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Available Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable data={dataCc} columns={columnsCard} pageRow={6} noData={dataCc.length === 0 ? true : false} />
                </Modal.Body>
            </Modal>

            <Modal open={modalPosCard} onClose={() => setModalPosCard(false)} overflow={false} backdrop="static" size="md">
                <Modal.Header>
                    <Modal.Title>Card List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table className="table table-bordered" size="md" >
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Card No</th>
                                <th>Card</th>
                                <th>EDC</th>
                                <th>Amount</th>
                                <th>Trace No</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '0.9em' }}>
                            {
                                itemData.map((row, i) => {
                                    return (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td width="70">
                                                <input
                                                    type="text"
                                                    maxLength="4"
                                                    value={row.card_no}
                                                    ref={cardInputRef}
                                                    onChange={(e) => {
                                                        const rawValue = e.target.value.replace(/\D/g, ""); // Hanya angka yang diperbolehkan
                                                        handleEdit(i, 'card_no', rawValue, false);
                                                    }}
                                                    style={{ border: 'none', outline: 'none', padding: 2, width: '100%', borderRadius: '3px', fontFamily: 'inherit' }}
                                                    placeholder="Card Number"
                                                />
                                            </td>
                                            <td style={{ textWrap: 'nowrap' }}>
                                                {row.description}
                                                {row.description === '' ?
                                                    <Popup content="Add Card" size="tiny" trigger={
                                                        <Icon name="plus circle" style={{ cursor: 'pointer' }} color="blue" onClick={(e) => handleAddCard(i, 'description', e.target.value, false)} />
                                                    } />
                                                    : <></>
                                                }
                                            </td>
                                            <td style={{ textWrap: 'nowrap' }}>
                                                {row.bank_edc}
                                                {row.bank_edc === '' ?
                                                    <Popup content="Add EDC" size="tiny" trigger={
                                                        <Icon name="plus circle" style={{ cursor: 'pointer' }} color="blue" onClick={(e) => handleAddEdc(i, 'bank_edc', e.target.value, false)} />
                                                    } />
                                                    : <></>
                                                }
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    min="0"
                                                    value={curencyNoSym.format(row.amount)}
                                                    style={{ border: 'none', outline: 'none', padding: 2, width: '100%', borderRadius: '3px', fontFamily: 'inherit' }}
                                                    placeholder="Amount"
                                                    onChange={(e) => handleEdit(i, 'amount', e.target.value, true)}
                                                    onFocus={(e) => e.target.value = row.amount} // Saat fokus, tampilkan angka asli tanpa format
                                                    onBlur={(e) => e.target.value = curencyNoSym.format(row.amount)} // Saat blur, kembali ke format currency
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    maxLength="6"
                                                    value={row.trace_number}
                                                    style={{ border: 'none', outline: 'none', padding: 2, width: '100%', borderRadius: '3px', fontFamily: 'inherit' }}
                                                    placeholder="Trace Number"
                                                    onChange={(e) => {
                                                        const rawValue = e.target.value.replace(/\D/g, ""); // Hanya angka yang diperbolehkan
                                                        handleEdit(i, 'trace_number', rawValue, false);
                                                    }}
                                                />
                                            </td>
                                            <td className="text-center" style={{ width: '50px' }}>
                                                <Popup content="Remove this item" size="tiny" trigger={
                                                    <Icon name="close" color="orange" style={{ cursor: 'pointer' }} onClick={() => handleRemoveRow(i)} />}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td colSpan={7} onClick={handleAddRow} style={{ cursor: 'pointer' }} className="text-center fw-bold">
                                    <span><Icon name="add" color="blue" /></span>
                                    <span>Add Card</span>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="fw-bold">Total : {curencyNoSym.format(itemData.reduce((total, i) => total + i.amount, 0))}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit Card" color="blue" onClick={handleClickSubmitCard} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdc} onClose={() => setModalEdc(false)} overflow={false} backdrop="static">
                <Modal.Header>
                    <Modal.Title>EDC List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable data={edcData.filter(e => e.id_showroom === openPos.id_showroom)} columns={columnsEdc} pageRow={5} noData={edcData.length === 0 ? true : false} />
                </Modal.Body>
            </Modal>

            <Modal open={modalReturnCode} onClose={() => setModalReturnCode(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Return Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmRetur" onSubmit={handleSubmitReturnCode}>
                        <Form.Input fluid type="text" value={returnCode} onChange={(e) => setReturnCode(e.target.value)} label="Return Code" required />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" type="submit" form="frmRetur" icon="save" color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalBarcode} onClose={() => setModalBarcode(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Scan EAN/UPC Barcode</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmitBarcode}>
                        <Input value={barcode} ref={inputRef} onChange={(e) => handleChangeBarcode(e.target.value)} iconPosition="left" icon={{ name: 'barcode', color: 'orange' }} fluid required />
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal open={modalSpvPwd} onClose={() => setModalSpvPwd(false)} backdrop="static" overflow={false} size="xs">
                <Modal.Header>
                    <Modal.Title>Supervisor Passkey</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="spvPwd" onSubmit={handleSubmitPasskey}>
                        <Form.Input type="password" label="Enter SPV Passkey" value={spvPasskey} onChange={(e) => setSpvPasskey(e.target.value)} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" color="blue" form="spvPwd" />
                </Modal.Footer>
            </Modal>
        </>
    );
}

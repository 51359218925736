import React, { useEffect, useRef, useState, forwardRef } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import { Button, Dimmer, Divider, Loader } from "semantic-ui-react";
import jfsLogo from "../../asset/javafootwear.png"
import { curencyNoSym, currentDateTime } from "../../component/utils";
import { useMyGlobal } from "../../component/Global";


export const DeliveryPrint = () => {
    const data = useLocation().state
    const navigate = useNavigate()
    const componentRef = useRef()
    const [groupDetails, setGroupDetails] = useState(null)
    const [loaderPage, setLoaderPage] = useState(false)
    const [totalQty, setTotalQty] = useState(0)

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const formatDateLong = (dateString) => {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('id-ID', options);
    };

    const ComponentToPrint = forwardRef((props, ref) => {
        return (
            <div ref={ref} style={{ position: 'relative', minHeight: '87em', paddingBottom: '3rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem', borderBottom: 'solid black 2px' }}>
                    <div style={{ fontWeight: 'bolder', fontSize: '35px', paddingTop: '2.5em' }}>DELIVERY NOTE</div>
                    <div style={{ justifyItems: 'end', marginBottom: '5px' }}>
                        <img src={jfsLogo} width={100} style={{ marginBottom: '5px' }} />
                        <div style={{ fontWeight: 'bolder' }}>PT. JAVA FOOTWEAR SPORTINDO</div>
                        <div>Jl. Surapati No.61, Sadang Serang, Coblong, Kota Bandung</div>
                        <div>Phone : (022) 2506999</div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem' }}>
                    <table>
                        <tr>
                            <td style={{ paddingRight: '20px' }}>Customer</td>
                            <td style={{ paddingRight: '20px' }}>:</td>
                            <td>{data.header.id_ds_customer} - {data?.header.customer_name}</td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top', paddingRight: '20px' }}>Alamat Pengiriman</td>
                            <td style={{ paddingRight: '20px', verticalAlign: 'top' }}>:</td>
                            <td style={{ width: '300px', overflow: 'clip', verticalAlign: 'top' }}>{data?.header.deliver_to} - {data?.header.address}</td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: '20px' }}>Gudang</td>
                            <td style={{ paddingRight: '20px' }}>:</td>
                            <td>{data?.header.warehouse_name}</td>
                        </tr>
                    </table>
                    <table>
                        <tr style={{ fontWeight: 'bold' }}>
                            <td style={{ paddingRight: '20px' }}>Nomor DO</td>
                            <td style={{ paddingRight: '20px' }}>:</td>
                            <td>{data?.header.do_number}</td>
                        </tr>
                        <tr>
                            <td style={{ verticalAlign: 'top' }}>Tgl. DO</td>
                            <td style={{ paddingRight: '20px' }}>:</td>
                            <td>{formatDateLong(data?.header.do_date)}</td>
                        </tr>
                        <tr>
                            <td style={{ paddingRight: '20px' }}>Reference</td>
                            <td style={{ paddingRight: '20px' }}>:</td>
                            <td>{data?.header.reference ? data?.header.reference : '-' }</td>
                        </tr>
                    </table>
                </div>
                <div className="mb-4">
                    <table className="table table-bordered">
                        <thead >
                            <tr>
                                <th style={{ backgroundColor: 'whitesmoke' }}>No.</th>
                                <th style={{ backgroundColor: 'whitesmoke' }}>Artikel</th>
                                <th style={{ backgroundColor: 'whitesmoke' }}>Deskripsi</th>
                                <th style={{ backgroundColor: 'whitesmoke' }}>Size/Qty</th>
                                <th style={{ backgroundColor: 'whitesmoke', textAlign: 'end' }}>QTY</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '0.9em' }}>
                            {groupDetails?.map((item, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{item.article}</td>
                                    <td>{item.description}</td>
                                    <td>{item.sizes}</td>
                                    <td style={{ textAlign: 'end' }}>{item.qty}</td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={4}>Total</td>
                                <td className="text-end">{totalQty}</td>
                            </tr>
                        </tfoot>
                    </table>
                    <table>
                        <tr style={{border:'1px solid lightgrey'}}>
                            <td style={{padding:'0px 50px 0px 50px'}}>Dibuat oleh</td>
                            <td style={{padding:'0px 50px 0px 50px'}}>Disetujui oleh</td>
                            <td style={{padding:'0px 50px 0px 50px'}}>Diterima oleh</td>
                        </tr>
                        <tr style={{border:'1px solid lightgrey'}}>
                            <td style={{textAlign:'center', padding:'80px 0px 10px 0px'}}>(............................)</td>
                            <td style={{textAlign:'center', padding:'80px 0px 10px 0px'}}>(............................)</td>
                            <td style={{textAlign:'center', padding:'80px 0px 10px 0px'}}>(............................)</td>
                        </tr>
                    </table>
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    borderTop: '1px solid grey',
                }}>
                    <div style={{display:'flex'}}>
                        <small style={{ color: 'black', fontStyle: 'italic', width:'50%' }}>Berkas ini dibuat oleh sistem.</small>
                        <small style={{ color: 'black', fontStyle: 'italic', width:'50%', textAlign:'end' }}>Waktu cetak : {currentDateTime}</small>
                    </div>
                </div>               
            </div>

        )
    })

    useEffect(() => {
        const groupedDet = data.detail.reduce((acc, item) => {
            if (!acc[item.article]) {
                acc[item.article] = {
                    article: item.article,
                    description: item.description,
                    sizes: [],
                    qty: 0,
                    price: item.price,
                    discount: parseInt(item.price) * parseInt(item.dsc_percent) / 100,
                    total_discount: 0,
                    total: 0,
                };
            }
            acc[item.article].sizes.push(`${item.size}/${item.qty_pickup}`)
            acc[item.article].qty += parseInt(item.qty_pickup)
            acc[item.article].total += parseInt(item.total)
            acc[item.article].total_discount += parseInt(item.total_discount)
            return acc;
        }, {});

        Object.keys(groupedDet).forEach(key => {
            groupedDet[key].sizes = groupedDet[key].sizes.join(", ");
        });

        setGroupDetails(Object.values(groupedDet))
    }, [])

    useEffect(() => {
        if (data) {
            setLoaderPage(false)
        } else {
            setLoaderPage(true)
        }
        const total_qty = data.detail.reduce((total, item) => total + parseInt(item.qty_pickup), 0)
        setTotalQty(total_qty)
    }, [data])
    
    return (
        <>
            <Container className="pt-5" style={{ width: '70.6em' }}>
                <Dimmer active={loaderPage} inverted>
                    <Loader inverted>Loading...</Loader>
                </Dimmer>
                <Row className="d-flex justify-content-center pt-3 pb-5">
                    <Col lg={12}>
                        <Button content="Print" onClick={handlePrint} icon="print" color="blue" circular className="me-3" />
                        <Button content="Back" icon="reply" onClick={() => navigate('/directsales/delivery_order')} circular basic />
                    </Col>
                    <Col lg={12}>
                        <ComponentToPrint ref={componentRef} data={groupDetails} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}
import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useMyGlobal } from "../../component/Global";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Stack from "react-bootstrap/Stack"
import { Button, Checkbox, Form, FormGroup, FormInput, FormRadio, FormSelect, Icon, Label, Popup } from "semantic-ui-react";
import Modal from "rsuite/Modal";
import { capitalize } from "../../component/utils";
import { Toaster } from "../../component/Toaster";
import { Reacttable } from "../../component/Reacttable";


export const CustomerDeliveryAddress = () => {
    const [modalCreate, setModalCreate] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const { host, userProfile, cityOpt, provOpt } = useMyGlobal();
    const [deliveryAddrData, setDeliveryAddrData] = useState([])
    const [customerData, setCustomerData] = useState([])
    const [customer, setCustomer] = useState('')
    const [deliveryTo, setDeliveryTo] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [cp, setCp] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [remark, setRemark] = useState('')
    const [idDeliveryAddr, setIdDelliveryAddr] = useState('')

    const columnsDeliveryAddr = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_delivery_addr"
        },
        {
            Header: "Customer",
            accessor: "customer_name"
        },
        {
            Header: "Deliver To",
            accessor: "deliver_to"
        },
        {
            Header: "Address",
            accessor: "address",
            Cell: ({ cell }) => (
                <div style={{ width: '180px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "City",
            accessor: "city"
        },
        {
            Header: "Zip Code",
            accessor: "zip_code"
        },
        {
            Header: "CP",
            accessor: "cp"
        },
        {
            Header: "Phone",
            accessor: "phone"
        },
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header: "User",
            accessor: "user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '0.8em' }}>{cell.row.original.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Create At",
            accessor: "createAt"
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup size="tiny" content="Edit" trigger={
                        <Button icon={{ name: 'edit', color: 'blue' }} onClick={() => handleClickEdit(cell.row.original)} />
                    } />
                    <Popup size="tiny" content="Delete" trigger={
                        <Button icon={{ name: 'trash alternate', color: 'blue' }} onClick={() => handleClickDelete(cell.row.original.id_delivery_addr)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const handleClickEdit = (row) => {
        setModalEdit(true)

        setIdDelliveryAddr(row.id_delivery_addr)
        setCustomer(row.id_ds_customer)
        setDeliveryTo(row.deliver_to)
        setAddress(row.address)
        setCity(cityOpt.find(i => i.text === row.city).value)
        setZipCode(row.zip_code)
        setCp(row.cp)
        setPhone(row.phone)
        setEmail(row.email)
        setRemark(row.remarks)
    }

    const handleClickDelete = async (id) => {
        const cfm = window.confirm("Are you sure?")

        if (cfm) {
            await axios({
                method: "DELETE",
                url: `${host}/directsales/index.php?action=deleteDeliveryAddr`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: {
                    id_delivery_addr: id
                }
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: res.data.msg,
                        position: "top-right",
                    })
                    deliveryAddr()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: "top-right",
                    })
                }
            })
        }
    }

    const handleClickAdd = () => {
        setModalCreate(true)
        clearForm()
    }

    const clearForm = () => {
        setCustomer('')
        setDeliveryTo('')
        setAddress('')
        setCity('')
        setZipCode('')
        setCp('')
        setPhone('')
        setEmail('')
        setRemark('')
    }

    const handleSubmitEdit = () => {
        const data = {
            id_delivery_addr: idDeliveryAddr,
            id_ds_customer: customer,
            id_user: userProfile.profile.id_user,
            deliver_to: deliveryTo,
            address: address,
            city: cityOpt.find(i => i.value === city).text,
            zip_code: zipCode,
            phone: phone,
            cp: cp,
            email: email,
            remarks: remark,
        }

        axios({
            method: "PUT",
            url: `${host}/directsales/index.php?action=editDeliveryAddr`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: data
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: res.data.msg,
                    position: 'top-right'
                })
                clearForm()
                setModalEdit(false)
                deliveryAddr()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const handleSubmit = () => {
        const param = new FormData()

        param.append('id_ds_customer', customer)
        param.append('id_user', userProfile.profile.id_user)
        param.append('deliver_to', deliveryTo)
        param.append('address', address)
        param.append('city', cityOpt.find(i => i.value === city).text)
        param.append('zip_code', zipCode)
        param.append('cp', cp)
        param.append('phone', phone)
        param.append('email', email)
        param.append('remarks', remark)

        axios({
            method: "POST",
            url: `${host}/directsales/index.php?action=createDeliveryAddr`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: res.data.msg,
                    position: 'top-right',
                })
                clearForm()
                setModalCreate(false)
                deliveryAddr()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right',
                })
            }
        })
    }

    const deliveryAddr = async () => {
        await axios({
            method: "GET",
            url: `${host}/directsales/index.php?action=deliveryAddr`,
            headers:{
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDeliveryAddrData(res.data.data)
            }
        })
    }

    useEffect(() => {
        const customer = () => {
            axios({
                method: "GET",
                url: `${host}/directsales/index.php?action=customer`,
                headers:{
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                }
            }).then(res => {
                if (res.data.status === 200) {
                    let customerOpt = res.data.data.map((item, i) => (
                        {
                            key: item.id_ds_customer,
                            text: item.customer_name,
                            value: item.id_ds_customer,
                        }
                    ))
                    setCustomerData(customerOpt)
                }
            })
        }
        customer()
        deliveryAddr()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Customer Delivery Address</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={12} lg={12} md={12} className="mb-3">
                        <Button content="Add New Delivery Address" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={12} lg={12} md={12}>
                        <Reacttable data={deliveryAddrData} columns={columnsDeliveryAddr} pageRow={10} />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalCreate} onClose={() => setModalCreate(false)} backdrop="static" overflow={false} size="sm">
                <Modal.Header>
                    <Modal.Title>Create New Delivery Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmCreate" onSubmit={handleSubmit}>
                        <Form.Group>
                            <FormSelect
                                label="Customer"
                                options={customerData}
                                search
                                value={customer}
                                onChange={(e, { value }) => setCustomer(value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group grouped>
                            <FormInput type="text" label="Delivery To" maxLength="35" value={deliveryTo} onChange={(e, { value }) => setDeliveryTo(value)} required />
                            <FormInput type="text" label="Address" maxLength="100" value={address} onChange={(e, { value }) => setAddress(value)} required />
                        </Form.Group>
                        <FormGroup>
                            <FormSelect label="City" options={cityOpt} width={11} search value={city} onChange={(e, { value }) => setCity(value)} required />
                            <FormInput type="text" label="Zip Code" maxLength="6" value={zipCode} onChange={(e, { value }) => setZipCode(value)} />
                        </FormGroup>
                        <FormGroup>
                            <FormInput type="text" label="Contact Person" maxLength="35" width={5} value={cp} onChange={(e, { value }) => setCp(value)} />
                            <FormInput type="text" label="Phone" maxLength="15" width={5} value={phone} onChange={(e, { value }) => setPhone(value)} />
                            <FormInput type="email" label="Email" maxLength="35" width={7} value={email} onChange={(e, { value }) => setEmail(value)} />
                        </FormGroup>
                        <FormGroup grouped>
                            <FormInput type="text" label="Remarks" maxLength="50" value={remark} onChange={(e, { value }) => setRemark(value)} />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit New Address" color="blue" type="submit" form="frmCreate" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} backdrop="static" overflow={false} size="sm">
                <Modal.Header>
                    <Modal.Title>Edit Delivery Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                        <Form.Group>
                            <FormSelect
                                label="Customer"
                                options={customerData}
                                search
                                value={customer}
                                onChange={(e, { value }) => setCustomer(value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group grouped>
                            <FormInput type="text" label="Delivery To" maxLength="35" value={deliveryTo} onChange={(e, { value }) => setDeliveryTo(value)} required />
                            <FormInput type="text" label="Address" maxLength="100" value={address} onChange={(e, { value }) => setAddress(value)} required />
                        </Form.Group>
                        <FormGroup>
                            <FormSelect label="City" options={cityOpt} width={11} search value={city} onChange={(e, { value }) => setCity(value)} required />
                            <FormInput type="text" label="Zip Code" maxLength="6" value={zipCode} onChange={(e, { value }) => setZipCode(value)} />
                        </FormGroup>
                        <FormGroup>
                            <FormInput type="text" label="Contact Person" maxLength="35" width={5} value={cp} onChange={(e, { value }) => setCp(value)} />
                            <FormInput type="text" label="Phone" maxLength="15" width={5} value={phone} onChange={(e, { value }) => setPhone(value)} />
                            <FormInput type="email" label="Email" maxLength="35" width={7} value={email} onChange={(e, { value }) => setEmail(value)} />
                        </FormGroup>
                        <FormGroup grouped>
                            <FormInput type="text" label="Remarks" maxLength="50" value={remark} onChange={(e, { value }) => setRemark(value)} />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Confirm Edit Address" color="blue" type="submit" form="frmEdit" />
                </Modal.Footer>
            </Modal>

        </>
    )
}
import React, { useEffect, useState, useRef, useMemo } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "rsuite/Modal";
import { Button, Dimmer, Form, Header, Icon, Loader, Popup } from "semantic-ui-react";
import { useMyGlobal } from "../component/Global";
import { capitalize, curencyNoSym, currentDate, removeDuplicatesArray } from "../component/utils";
import AutoComplete from 'rsuite/AutoComplete';
import InputGroup from "rsuite/InputGroup";
import SearchIcon from '@rsuite/icons/Search';
import axios from "axios";
import Table from "react-bootstrap/Table";
import { Toaster } from "../component/Toaster";
import InlineEdit from 'rsuite/InlineEdit';
import { Reacttable } from "../component/Reacttable";
import Stack from "react-bootstrap/Stack";
import DateRangePicker from 'rsuite/DateRangePicker';
import moment from "moment";

export const StockAdjustment = () => {
    const [modalCreateAdj, setModalCreteAdj] = useState(false)
    const { warehouseOpt, userProfile, host } = useMyGlobal()
    const [modalArtList, setModalArtList] = useState(false)
    const [modalDetail, setModalDetail] = useState(false)
    const [adjType, setAdjType] = useState('IN')
    const [warehouse, setWarehouse] = useState('')
    const [adjDate, setAdjDate] = useState(currentDate)
    const [description, setDescription] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [dataStock, setDataStock] = useState([])
    const [dataStockAc, setDataStockAc] = useState([])
    const [selectedArt, setSelectedArt] = useState(null)
    const [tempQty, setTempQty] = useState(0)
    const [artToAdjust, setArtToAdjust] = useState(null)
    const [adjustedArt, setAdjustedArt] = useState([])
    const previousWarehouseRef = useRef();
    const [stockAdjData, setStockAdjData] = useState([])
    const [selectedAdj, setSelectedAdj] = useState(null)
    const [dataState, setDataState] = useState(false)
    const [noData, setNoData] = useState(false)
    const [loadGenerate, setLoadGenerate] = useState(false)

    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5

    const startIndex = currentPage * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    const currentData = adjustedArt?.slice(startIndex, endIndex)

    const totalPages = Math.ceil(adjustedArt?.length / itemsPerPage)

    const columnsHeader = useMemo(() => [
        {
            Header: 'Adjust Number',
            accessor: 'header.adjust_number'
        },
        {
            Header: 'Adjust Date',
            accessor: 'header.adjust_date'
        },
        {
            Header: 'Type',
            accessor: 'header.adjust_type',
            Cell: ({ cell }) => (
                <Stack direction="horizontal" gap={1}>
                    <div>
                        <Icon
                            name={cell.value === 'IN' ? 'arrow alternate circle down outline' : 'arrow alternate circle up outline'}
                            color={cell.value === 'IN' ? 'green' : 'pink'}
                        />
                    </div>
                    <div>{capitalize(cell.value)}</div>
                </Stack>
            )
        },
        {
            Header: 'Warehouse',
            accessor: 'header.warehouse_name'
        },
        {
            Header: 'Description',
            accessor: 'header.adjust_desc'
        },
        {
            Header: 'Total Qty',
            accessor: 'header.total_qty',
        },
        {
            Header: 'User',
            accessor: 'header.user_name',
            Cell: ({ cell }) => (
                <Stack direction="vertical" style={{ lineHeight: '1.3em' }}>
                    <div>{cell.value}</div>
                    <div className="text-muted">{cell.row.original.header.role_name}</div>
                </Stack>
            )
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group size="mini" basic>
                    <Popup content="Detail" size="tiny" trigger={
                        <Button icon={{ name: 'list layout', color: 'blue' }} onClick={() => handleClickDetail(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const columnsDetail = useMemo(() => [
        {
            Header: 'Brand',
            accessor: 'brand'
        },
        {
            Header: 'Article',
            accessor: 'article'
        },
        {
            Header: 'Description',
            accessor: 'description'
        },
        {
            Header: 'Size',
            accessor: 'size'
        },
        {
            Header: 'Qty Before',
            accessor: 'qty_before'
        },
        {
            Header: 'Qty Adjusted',
            accessor: 'qty_adjust'
        },
    ])

    const handleClickDetail = (data) => {
        console.log(data)
        setSelectedAdj(data)
        setModalDetail(true)
    }

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    }

    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    }


    const handleClickAddAdjustment = () => {
        setModalCreteAdj(true)
    }

    const getDataArticle = async (idWh) => {
        await axios({
            method: "GET",
            url: `${host}/stock/index.php?action=stockByWarehouse&id_warehouse=${idWh}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        })
            .then(res => {
                setDataStock(res.data.data)
                setDataStockAc(removeDuplicatesArray(res.data.data, 'article')?.map(i => i.article))
            })
    }

    const handleSelectArt = (article) => {
        const selected = dataStock.filter(i => i.article === article)
        setSelectedArt(selected.map(item => ({ ...item, adj_qty: 0 })))
    }

    const handleClickArticle = (data) => {
        setModalArtList(true)
    }

    const handleChangeWarehouse = (idWarehouse) => {
        getDataArticle(idWarehouse)
        setWarehouse(idWarehouse)
    }

    const getStockAdjustmentData = () => {
        setLoadGenerate(true)
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        axios({
            method: "GET",
            url: `${host}/stock/index.php`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                range: dateRange,
                action: 'stockAdjustment'
            }
        }).then(res => {
            if (res.data.status === 200) {
                if (res.data.data.length === 0) {
                    setNoData(true)
                }
                setStockAdjData(res.data.data)
                if (res.data.data.length === 0) {
                    setDataState(true)
                }
            }
        }).finally(() => {
            setLoadGenerate(false)
        })
    }

    const handleSubmit = () => {
        if (adjustedArt.length > 0) {
            const param = new FormData()

            const header = JSON.stringify({
                adj_type: adjType,
                warehouse: warehouse,
                adj_date: adjDate,
                description: description,
                selected_art: searchValue,
                total_qty: adjustedArt?.reduce((a, b) => a + b.adj_qty, 0),
                id_user: userProfile.profile.id_user,
            })

            const detail = JSON.stringify(adjustedArt)

            param.append('header', header)
            param.append('detail', detail)

            axios({
                method: "POST",
                url: `${host}/stock/index.php?action=createStockAdjust`,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: param
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: 'Success Create Adjustment Stock',
                        position: 'top-right'
                    })
                    setModalCreteAdj(false)
                    clearForm()
                    getStockAdjustmentData()
                } else (
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                )
            })
        } else {
            Toaster.fire({
                icon: 'warning',
                text: 'No item is selected',
                position: 'top-right'
            })
        }
    }

    const clearForm = () => {
        setAdjType('')
        setAdjDate(currentDate)
        setWarehouse('')
        setDescription('')
        setSearchValue('')
        setSelectedArt([])
        setAdjustedArt([])
    }

    const handleSaveQty = (data) => {
        if (adjType === 'IN') {
            setAdjustedArt((prevAdjustedArt) => {
                const exists = prevAdjustedArt.some(item => item.article === data.article && item.size === data.size)
                if (exists) {
                    return prevAdjustedArt.map(item =>
                        item.article === data.article && item.size === data.size
                            ? { ...item, adj_qty: parseInt(tempQty) }
                            : item
                    )
                } else {
                    return [...prevAdjustedArt, { ...data, adj_qty: parseInt(tempQty) }]
                }
            })
        } else if (adjType === 'OUT') {
            const diff = parseInt(data.qty) - parseInt(tempQty)

            if (diff >= 0) {
                setAdjustedArt((prevAdjustedArt) => {
                    const exists = prevAdjustedArt.some(item => item.article === data.article && item.size === data.size)
                    if (exists) {
                        return prevAdjustedArt.map(item =>
                            item.article === data.article && item.size === data.size
                                ? { ...item, adj_qty: -parseInt(tempQty) }
                                : item
                        )
                    } else {
                        return [...prevAdjustedArt, { ...data, adj_qty: -parseInt(tempQty) }]
                    }
                })
            } else {
                Toaster.fire({
                    icon: 'warning',
                    title: 'Minus Quantity',
                    text: 'Quantity adjustment makes the item minus, please check again',
                    position: 'top-right'
                })
            }
        }
    }

    const handleRemoveItem = (item, index) => {
        setAdjustedArt((prevAdjustedArt) => {
            const newAdjustedArt = [...prevAdjustedArt]
            newAdjustedArt.splice(index, 1)
            return newAdjustedArt
        })
    }

    const handleGenerateData = () => {
        getStockAdjustmentData(dateRange)
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }


    useEffect(() => {
        setAdjustedArt(prevAdjustedArt =>
            prevAdjustedArt.map(i => ({
                ...i,
                adj_qty: adjType === 'OUT' ? -Math.abs(i.adj_qty) : Math.abs(i.adj_qty)
            }))
        )
    }, [adjType])

    useEffect(() => {
        if (previousWarehouseRef.current !== warehouse) {
            setAdjustedArt([]);
        }
        previousWarehouseRef.current = warehouse;
    }, [warehouse])

    useEffect(() => {
        getStockAdjustmentData(dateRange)
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Stock Ajustment</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={5} lg={4} md={12} sm={12} className="mb-3">
                        <Button content="Create Stock Adjustment" icon="add" onClick={handleClickAddAdjustment} color="blue" />
                    </Col>
                    <Col xl={5} lg={8} md={8} xs={12} className="mb-3 text-end">
                        <DateRangePicker
                            style={{ width: '60%' }}
                            label="Date Range : "
                            format="yyyy-MM-dd"
                            placement="auto"
                            cleanable={true}
                            defaultValue={dateRange}
                            value={dateRange}
                            onChange={handleChangeDate}
                            onClean={() => setDateRange([
                                new Date(moment().startOf('month')),
                                new Date(moment()),
                            ])}
                        />
                        <Button content="Generate Data" loading={loadGenerate} disabled={loadGenerate} className="ms-2" style={{ paddingTop: '10px' }} color="blue" onClick={() => handleGenerateData()} />
                    </Col>
                    <Col xl={10} lg={12} md={12} sm={12}>
                        <Reacttable data={stockAdjData} columns={columnsHeader} pageRow={8} noData={dataState} />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalCreateAdj} onClose={() => setModalCreteAdj(false)} overflow={false} backdrop="static" size="lg">
                <Modal.Header>
                    <Modal.Title>Create Stock Adjustment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12} className="mb-3">
                            <Form id="frmAdj" onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Dropdown
                                        selection
                                        value={adjType}
                                        label="Adjustment Type"
                                        options={[{ key: '0', value: 'IN', text: 'Adjustment In' }, { key: '1', value: 'OUT', text: 'Adjustment Out' }]}
                                        onChange={(e, { value }) => setAdjType(value)}
                                        required
                                    />
                                    <Form.Dropdown
                                        search
                                        selection
                                        label="Warehouse"
                                        value={warehouse}
                                        options={warehouseOpt}
                                        onChange={(e, { value }) => handleChangeWarehouse(value)}
                                        required
                                    />
                                    <Form.Input type="date" value={adjDate} label="Date" onChange={(e) => setAdjDate(e.target.value)} required />
                                    <Form.Input type="text" value={description} label="Description" onChange={(e) => setDescription(e.target.value)} width="6" />
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col lg={12} className="mb-2">
                            <InputGroup style={{ width: 195 }}>
                                <AutoComplete
                                    data={dataStockAc}
                                    placeholder="Search by Article"
                                    value={searchValue}
                                    size="md"
                                    onSelect={handleSelectArt}
                                    onChange={(value) => setSearchValue(value)}
                                />
                                <InputGroup.Button tabIndex={-1} onClick={handleClickArticle}>
                                    <SearchIcon color="blue" />
                                </InputGroup.Button>
                            </InputGroup>
                        </Col>
                        <Col lg={12}>
                            <Table className="table">
                                <thead style={{ fontSize: '0.9em' }}>
                                    <tr>
                                        <th>#</th>
                                        <th>Article</th>
                                        <th>Description</th>
                                        <th>Size</th>
                                        <th>Qty</th>
                                        <th>Adjust</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: '0.8em' }} className="align-middle">
                                    {
                                        currentData?.map((item, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{item.article}</td>
                                                <td>{item.description}</td>
                                                <td>{item.size}</td>
                                                <td>{item.qty}</td>
                                                <td>{item.adj_qty}</td>
                                                <td>
                                                    <Button.Group size="mini" basic>
                                                        <Popup size="tiny" content="Remove this item" trigger={
                                                            <Button icon="close" onClick={() => handleRemoveItem(item, i)} />
                                                        } />
                                                    </Button.Group>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot style={{ fontSize: '0.9em', fontWeight: 'bold' }}>
                                    <tr>
                                        <td colSpan={4}>Total</td>
                                        <td>{adjustedArt?.reduce((a, b) => a + parseInt(b.qty), 0)}</td>
                                        <td>{adjustedArt?.reduce((a, b) => a + parseInt(b.adj_qty), 0)}</td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                        <Col>
                            <div>
                                <Button className="me-3" icon="backward" size="mini" compact onClick={prevPage} disabled={currentPage === 0} />
                                <span>Page {currentPage + 1} of {totalPages}</span>
                                <Button className="ms-3" icon="forward" size="mini" compact onClick={nextPage} disabled={currentPage === totalPages - 1} />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Create Adjustment" icon="arrow alternate circle right outline" labelPosition="right" type="submit" form="frmAdj" color="blue" disabled={currentData.length > 0 ? false : true} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalArtList} onClose={() => setModalArtList(false)} overflow={false} backdrop="static" children>
                <Modal.Header>
                    <Modal.Title>Article List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="d-flex justify-content-center">
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <Table className="table table-hover">
                                <thead style={{ fontSize: '0.9em' }}>
                                    <tr>
                                        <th>#</th>
                                        <th>Article</th>
                                        <th>Description</th>
                                        <th>Size</th>
                                        <th>Price</th>
                                        <th>Qty</th>
                                        <th>Adjust</th>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: '0.8em' }} className="align-middle">
                                    {
                                        selectedArt?.map((item, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{item.article}</td>
                                                <td className="text-truncate" style={{ maxWidth: 200 }}>{item.description}</td>
                                                <td>{item.size}</td>
                                                <td>{curencyNoSym.format(item.price)}</td>
                                                <td>{item.qty}</td>
                                                <td>
                                                    <InlineEdit
                                                        defaultValue="0"
                                                        value={adjustedArt.adj_qty}
                                                        size="xs"
                                                        style={{ width: '5em' }}
                                                        onChange={(val) => setTempQty(val)}
                                                        onSave={() => handleSaveQty(item)}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot style={{ fontSize: '0.9em', fontWeight: 'bold' }}>
                                    <tr>
                                        <td>Total</td>
                                        <td colSpan={4}></td>
                                        <td>{selectedArt?.reduce((a, b) => a + parseInt(b.qty), 0)}</td>
                                        <td style={{ paddingLeft: '1.4em' }}>{adjustedArt?.reduce((a, b) => a + parseInt(b.adj_qty), 0)}</td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Close" icon="close" onClick={() => setModalArtList(false)} color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalDetail} onClose={() => setModalDetail(false)} overflow={false}>
                <Modal.Header>
                    <Modal.Title>Adjustment Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: '0.9em' }}>
                    <Row>
                        <Col lg={12} className="mb-3">
                            <Stack direction="horizontal" gap={0}>
                                <Stack direction="vertical">
                                    <div>Adjust Number</div>
                                    <div>Warehouse</div>
                                    <div>Type</div>
                                </Stack>
                                <Stack direction="vertical" className="fw-bold">
                                    <div>: {selectedAdj?.header?.adjust_number}</div>
                                    <div>: {selectedAdj?.header?.warehouse_name}</div>
                                    <div>: <span><Icon name="arrow alternate circle down outline" color="green" /></span><span>{selectedAdj?.header?.adjust_type}</span></div>
                                </Stack>
                            </Stack>
                        </Col>
                        <Col lg={12}>
                            <Reacttable data={selectedAdj?.detail} columns={columnsDetail} pageRow={10} noData={selectedAdj?.detail.length === 0 ? true : false} />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}
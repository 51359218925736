import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import SelectPicker from 'rsuite/SelectPicker';
import AutoComplete from 'rsuite/AutoComplete';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, Divider, Form, Label, Popup, Input, Icon, Header, Dimmer, Loader, Message, Confirm, FormSelect, FormField, Select } from "semantic-ui-react";
import Modal from "rsuite/Modal";
import InputRS from "rsuite/Input";
import { useMyGlobal } from "../component/Global";
import axios from "axios";
import Table from "react-bootstrap/Table";
import { badgeColor, curency, curencyNoSym, currentDate, futureDate, removeDuplicatesArray, tableBodyStyle } from "../component/utils";
import { Toaster } from "../component/Toaster";
import { Reacttable } from "../component/Reacttable";
import Badge from 'rsuite/Badge';
import DateRangePicker from 'rsuite/DateRangePicker';
import Stack from "react-bootstrap/Stack";
import moment from "moment";
import Tabs from 'rsuite/Tabs';
import { useNavigate } from "react-router-dom";

export const PurchaseOrder = () => {
    const [dataPo, setDataPo] = useState([])
    const navigate = useNavigate()
    const [modalAdd, setModalAdd] = useState(false)
    const [modalPoDetail, setModalPoDetail] = useState(false)
    const [poDate, setPoDate] = useState(currentDate)
    const [poDelDate, setPoDelDate] = useState('')
    const [poType, setPoType] = useState('Pembelian')
    const [paymentType, setPaymentType] = useState('Tunai')
    const [supplier, setSupplier] = useState('')
    const [warehouse, setWarehouse] = useState('')
    const [ppn, setPpn] = useState(11)
    const [desc, setDesc] = useState('')
    const [dataVendorOpt, setDataVendorOpt] = useState([])
    const [dataWarehouseOpt, setDataWarehouseOpt] = useState([])
    const { host, userProfile, supplierOpt } = useMyGlobal()
    const [poDetail, setPoDetail] = useState([])
    const [dataProduct, setDataProduct] = useState([])
    const [productOpt, setProductOpt] = useState([])
    const [selectedProduct, setSelectedProduct] = useState([])
    const [sizeOpt, setSizeOpt] = useState([])
    const [inputDisable, setInputDisable] = useState(true)
    const [totalQty, setTotalQty] = useState(0)
    const [grandTotal, setGrandTotal] = useState(0)
    const [loaderCreatePo, setLoaderCreatePo] = useState(false)
    const [selectedPo, setSelectedPo] = useState(null)
    const [loaderDeletePo, setLoaderDeletePo] = useState('')
    const [stockProductByWh, setStockProductByWh] = useState([])
    const [poAmount, setPoAmount] = useState(0)
    const [poQty, setPoQty] = useState(0)
    const [totalPo, setTotalPo] = useState(0)
    const [poPartial, setPoPartial] = useState(0)
    const [excelFile, setExcelFile] = useState(null)
    const [sheetList, setSheetList] = useState([])
    const [disableSheetList, setDisableSheetList] = useState(true)
    const [dataSheet, setDataSheet] = useState([])
    const [selectedSheet, setSelectedSheet] = useState(null)
    const urlTemplate = `${host}/import_template/po_detail.xlsx`
    const [loadSheet, setLoadSheet] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [initData, setInitData] = useState([])
    const [groupSize, setGroupSize] = useState([])
    const [isPoCreated, setIsPoCreated] = useState(false)
    const [modalExpFilter, setModalExpFilter] = useState(false)
    const [filterSupplier, setFilterSupplier] = useState('')


    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])

    const handleFile = (event) => {
        const file = event.target.files[0];
        setExcelFile(file);
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const [tempRow, setTempRow] = useState(null)

    const columns = useMemo(() => [
        {
            Header: "PO Number",
            accessor: "header.po_number"
        },
        {
            Header: "PO Date",
            accessor: "header.date_po"
        },
        {
            Header: "Deliver Date",
            accessor: "header.date_delivery"
        },
        {
            Header: "PO Type",
            accessor: "header.type"
        },
        {
            Header: "Vendor",
            accessor: "header.vendor_name"
        },
        {
            Header: "Warehouse",
            accessor: "header.warehouse_desc"
        },
        {
            Header: "Total",
            accessor: "header.grand_total",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Status",
            accessor: "header.po_status",
            Cell: ({ cell }) => (
                <>
                    <span className="me-2"><Badge style={{ background: badgeColor(cell.value) }} /></span>
                    <span className="text-capitalize">{cell.value}</span>
                </>
            )
        },
        {
            Header: "User",
            accessor: "header.user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div style={{ lineHeight: '0.8em' }} className="text-muted">{cell.row.original.header.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Action",
            accessor: '',
            Cell: ({ cell }) => (
                <Button.Group size="mini" basic>
                    <Popup content="Detail" size="small" trigger={
                        <Button icon={{ name: 'list layout', color: 'blue' }} onClick={() => handleClickPoDetail(cell.row.original)} />
                    } />
                    {/* <Popup content="Remove Purchase Order" size="small" trigger={
                        <Button icon="trash alternate" onClick={() => deletePO(cell.row.original.header.po_number)} loading={loaderDeletePo === cell.row.original.po_number ? true : false} disabled={loaderDeletePo === cell.row.original.po_number ? true : false}/>
                    } /> */}
                </Button.Group>
            )
        },
    ])

    const columnsPoDetails = useMemo(() => [
        {
            Header: "Brand",
            accessor: "brand_code"
        },
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '100px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Barcode",
            accessor: "barcode"
        },
        {
            Header: "Qty",
            accessor: "qty"
        },
        {
            Header: "Cogs",
            accessor: "cogs",
            Cell: ({ cell }) => (
                <div>{curencyNoSym.format(cell.value)}</div>
            )
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (
                <div>{curencyNoSym.format(cell.value)}</div>
            )
        },
        {
            Header: "Total Cogs",
            accessor: null,
            Cell: ({ cell }) => (
                <div>{curencyNoSym.format(cell.row.original.qty * cell.row.original.cogs)}</div>
            )
        },
        {
            Header: "Rec",
            accessor: "qty_receipt"
        },
        {
            Header: 'Status',
            accessor: 'item_status',
            Cell: ({ cell }) => (
                <Stack direction="horizontal" gap={0}>
                    <div className="me-2"><Badge style={{ background: badgeColor(cell.value) }} /></div>
                    <div className="text-capitalize">{cell.value}</div>
                </Stack>
            )
        }
    ])

    const handleClickPoDetail = (data) => {
        setSelectedPo(data)
        setModalPoDetail(true)
    }

    const handleClickAdd = () => {
        getVendorData()
        getWarehouseData()
        getProduct()
        setModalAdd(true)
    }

    const handleChangeType = (e, { value }) => {
        if (warehouse) {
            setPoType(value)
            if (value === 'Retur Pembelian') {
                getStockByWarehouse(warehouse)
                qtyByWh(warehouse)
            } else if (value === 'Pembelian') {
                getProduct()
            }
            setTempRow(null)
            setSizeOpt([])
            setPoDetail([])
            setSelectedSheet(null)
        } else {
            Toaster.fire({
                icon: 'warning',
                text: 'Wareshouse not selected yet',
                position: 'top-right'
            })
        }
    }

    const handleChangePay = (e, { value }) => setPaymentType(value)

    const qtyByWh = async (idWh) => {
        await axios({
            method: 'GET',
            url: `${host}/stock/index.php?action=stockByWarehouse&id_warehouse=${idWh}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            }
        }).then(res => {
            if (res.data.status === 200) {
                if (warehouse !== '') {
                    setStockProductByWh(res.data.data)
                }
            }
        })
    }

    const handleSelectArt = async (article) => {
        const temp = dataProduct?.find(i => i.article == article)

        temp.qty = "0"
        temp.total = parseFloat(temp.price) * parseInt(temp.qty)
        temp.selected_size = temp.size
        setGroupSize(dataProduct.filter(i => i.article === article))
        setSizeOpt(dataProduct?.filter(i => i.article === article).map(s => { return { label: s.size, value: s.size, key: s.barcode } }))
        setTempRow(temp)
        setInputDisable(false)
    }

    const handleSelectSize = (value) => {
        const barcode = groupSize?.find(i => i.size === value)
        setTempRow({ ...tempRow, selected_size: value, barcode: barcode.barcode })
    }

    const handleChangeQty = (value) => {
        let orderedQty = parseInt(tempRow.qty) + 1
        let qtyOfStock = (parseInt(dataProduct.find(i => i.article === tempRow.article && i.size === tempRow.selected_size)?.qty))
        if (poType === 'Retur Pembelian') {
            if (qtyOfStock >= orderedQty) {
                setTempRow({ ...tempRow, qty: value, total: parseFloat(tempRow.cogs) * value })
            } else if (qtyOfStock < orderedQty) {
                setTempRow({ ...tempRow, qty: value, total: parseFloat(tempRow.cogs) * value })
                Toaster.fire({
                    icon: 'warning',
                    text: "Insufficient stock, current stock is " + qtyOfStock
                })
            }
        } else if (poType === 'Pembelian') {
            setTempRow({ ...tempRow, qty: value, total: parseFloat(tempRow.cogs) * value })
        }
    }

    const clearTempRow = () => {
        setTempRow(null)
        setInputDisable(true)
        setSizeOpt([])
    }

    const handleClickAddRow = (data) => {
        let orderedQty = parseInt(tempRow.qty)
        let qtyOfStock = (parseInt(dataProduct.find(i => i.article === tempRow.article && i.size === tempRow.selected_size)?.qty))

        if (parseInt(data.qty) <= 0) {
            Toaster.fire({
                icon: 'warning',
                text: 'Quantity cannot be 0',
                position: 'top-right'
            })
        } else {
            if (poType === 'Retur Pembelian') {
                if (data && parseInt(data.qty) > 0) {
                    if (qtyOfStock >= orderedQty) {
                        const exist = poDetail.some(i => i.article === data.article && i.selected_size === data.selected_size)
                        if (!exist) {
                            const modifiedData = {
                                ...data,
                                qty: poType === "Retur Pembelian" ? `-${data.qty}` : data.qty,
                                total: poType === "Retur Pembelian" ? `-${data.total}` : data.total,
                            }
                            setPoDetail([...poDetail, modifiedData].reverse())
                            clearTempRow()
                        } else {
                            Toaster.fire({
                                icon: 'warning',
                                text: 'Data has been added previously',
                                position: 'top-right'
                            })
                        }
                    } else if (qtyOfStock < orderedQty) {
                        Toaster.fire({
                            icon: 'warning',
                            text: "Insufficient stock, current stock is " + qtyOfStock
                        })
                    }
                } else {
                    Toaster.fire({
                        icon: 'warning',
                        text: 'No articles have been selected yet or Cek your quantity, quantity cannot set to 0',
                        position: 'top-right'
                    })
                }
            } else if (poType === 'Pembelian') {
                const modifiedData = {
                    ...data,
                    qty: poType === "Retur Pembelian" ? `-${data.qty}` : data.qty,
                    total: poType === "Retur Pembelian" ? `-${data.total}` : data.total,
                }
                setPoDetail([...poDetail, modifiedData].reverse())
                clearTempRow()
            }
        }
    }

    const handleSubmit = () => {
        setLoaderCreatePo(true)
        const cfm = window.confirm("Have you checked everything?")
        if (cfm) {
            const param = new FormData()
            if (poDetail.length > 0) {
                const header = {
                    po_date: poDate,
                    po_delivery: poDelDate,
                    supplier: supplier,
                    id_warehouse: warehouse,
                    ppn: ppn,
                    desc: desc,
                    payment_type: paymentType,
                    po_type: poType,
                    grand_total: grandTotal,
                    total_qty: totalQty,
                    id_user: userProfile.profile.id_user,
                    ppn: ppn,
                }

                const detail = poDetail

                param.append('po_header', JSON.stringify(header))
                param.append('po_detail', JSON.stringify(detail))
                axios({
                    method: "POST",
                    url: `${host}/purchasing/index.php?action=createPo`,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                    },
                    data: param,
                }).then(res => {
                    if (res.data.status === 200) {
                        Toaster.fire({
                            icon: 'success',
                            text: 'Success create Purchase Order\n',
                            position: 'top-right',
                            timer: false
                        })
                        getPo()
                        setModalAdd(false)
                        setPoDetail([])
                    } else {
                        Toaster.fire({
                            icon: 'error',
                            text: res.data.msg,
                            position: 'top-right'
                        })
                    }
                }).finally(() => {
                    setLoaderCreatePo(false)
                })
                setIsPoCreated(true)
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: 'Product detail cannot be empty',
                    position: 'top-right'
                })
                setLoaderCreatePo(false)
            }
        }
    }

    const handleExport = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRange.map(date => formatDate(date));
        const date_range = [startDate, endDate];
        const param = {
            date: date_range,
            supplier: filterSupplier
        }

        await axios({
            method: "POST",
            url: `${host}/purchasing/index.php?action=exportPo`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param,
            responseType: 'blob'
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export_po.xlsx'); // Nama file yang akan diunduh
            document.body.appendChild(link);
            link.click();
            // Bersihkan URL Blob setelah file diunduh
            window.URL.revokeObjectURL(url);
        })
    }

    const getPo = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRange.map(date => formatDate(date));
        const date_range = [startDate, endDate];

        await axios({
            method: "GET",
            url: `${host}/purchasing/index.php?action=po`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                range: date_range
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataPo(res.data.data)
                setPoAmount(curencyNoSym.format(res.data.data.reduce((total, cur) => total + parseFloat(cur.header.grand_total), 0)))
                setPoQty(res.data.data.reduce((acc, item) => {
                    let total = item.detail.reduce((total, cur) => total + parseInt(cur.qty), 0)
                    return acc + total
                }, 0))
                setTotalPo(res.data.data.length)
                setPoPartial(res.data.data.filter(item => item.header.po_status !== 'complete').length)
            }
        })
    }

    const getVendorData = async () => {
        await axios({
            method: "GET",
            url: `${host}/purchasing/index.php?action=vendorMaster`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataVendorOpt(res.data.data.map(i => {
                    return { key: i.id_vendor, text: i.name, value: i.id_vendor }
                }))
            }
        })
    }

    const getWarehouseData = async () => {
        await axios({
            method: "GET",
            url: `${host}/showroom/index.php?action=warehouse`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataWarehouseOpt(res.data.data.map(i => {
                    return { key: i.id_warehouse, text: i.desc, value: i.id_warehouse }
                }))
            }
        })
    }

    const getProduct = async () => {
        await axios({
            method: 'GET',
            url: `${host}/product/index.php?action=productMaster`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            }
        }).then(res => {
            if (res.data.status === 200) {
                if (warehouse !== '') {
                    setDataProduct(res.data.data)
                    setProductOpt(removeDuplicatesArray(res.data.data, 'article')?.map(i => i.article))
                }
            }
        })
    }

    const getStockByWarehouse = async (idWh) => {
        await axios({
            method: 'GET',
            url: `${host}/stock/index.php?action=stockByWarehouse&id_warehouse=${idWh}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            }
        }).then(res => {
            if (res.data.status === 200) {
                if (warehouse !== '') {
                    setDataProduct(res.data.data)
                    setProductOpt(removeDuplicatesArray(res.data.data, 'article')?.map(i => i.article))
                }
            }
        })
    }

    const deletePO = (poNumber) => {
        setLoaderDeletePo(poNumber)
        axios({
            method: 'DELETE',
            url: `${host}/purchasing/index.php?action=deletePurchaseOrder&id=${poNumber}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            }
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success delete PO',
                    position: 'top-right'
                })
                getPo()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: 'Failed delete PO',
                    position: 'top-right'
                })
            }
        }).finally(() => setLoaderDeletePo(poNumber))
    }

    const handleChangeSearch = (keyword) => {
        setSearchValue(keyword)
        const key = keyword ? keyword.toLowerCase() : '';

        if (key) {
            const flt = poDetail.filter(item =>
                item.article.toLowerCase().includes(key.toLowerCase())
            )
            setInitData(flt.length > 0 ? flt : initData);
        } else {
            setInitData(poDetail);
        }
    }

    const handleRemoveProduct = (article, size, barcode) => {
        const filtered = poDetail?.filter(i => i.barcode !== barcode)
        setPoDetail(filtered)
    }

    const handleChangeWh = (value) => { // ga bisa dapetin Value
        setWarehouse(value)
        qtyByWh(value)
    }

    const handleChangeSheet = (sheetName) => {
        setSelectedSheet(sheetName)
        importBySheet(sheetName)
    }

    const importBySheet = async (sheetName) => {
        const formData = new FormData()
        formData.append('excel_file', excelFile)
        formData.append('sheet_name', sheetName)

        await axios({
            method: "POST",
            url: `${host}/purchasing/index.php?action=importBySheet`,
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: formData
        }).then(res => {
            setDataSheet(res.data)
        })
    }

    const handleImport = async () => {
        if (supplier !== '' && warehouse !== '') {
            const formData = new FormData();
            formData.append('excelFile', excelFile)
            setLoadSheet(true)

            await axios({
                method: "POST",
                url: `${host}/purchasing/index.php?action=sheetName`,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: formData
            }).then(res => {
                if (res.data.status === 200) {
                    const optSheet = res.data.data.map((item) => {
                        return {
                            key: item,
                            value: item,
                            text: item,
                        }
                    })
                    setSheetList(optSheet)
                    setDisableSheetList(false)
                } else {
                    Toaster.fire({
                        icon: "error",
                        text: res.data.msg,
                        position: 'top-right'
                    })
                }
                setLoadSheet(false)
            })
        } else {
            alert ("Supplier and Warehouse cannot be empty..")
        }
    }

    const handlePreExport = () => {
        setModalExpFilter(true)
    }

    const handleClearPoDetail = () => {
        setPoDetail([])
        setDataSheet([])
        setSelectedSheet(null)
    }

    useEffect(() => {
        const artCode = dataSheet.map(item => item[0])
        const artQty = dataSheet.map(item => item[1])
        const size = dataSheet.map(item => item[2])
        let unmatchedArticle = []

        if (poType === 'Retur Pembelian') {
            const filteredData = dataProduct
                .filter(item => dataSheet.some(sheetData => sheetData[0] == item.article && sheetData[2] == item.size))
                .map(item => {
                    const sheetItem = dataSheet.find(data => data[0] == item.article && data[2] == item.size);
                    if (sheetItem) {
                        return {
                            ...item,
                            selected_qty: `-${sheetItem[1]}`,
                            total: `-${sheetItem[1] * item.cogs}`,
                            selected_size: sheetItem[2]
                        }
                    }
                })

            const unmatchedArticle = dataSheet.filter(item => {
                // Cek apakah item memiliki pasangan di dataSheet
                return !dataProduct.some(prodData => prodData.article == item[0] && item[2] == prodData.size)
            })

            const formatedDataAlert = unmatchedArticle.map(row => {
                return `Article: ${row[0]}, Qty: ${row[1]}, Size: ${row[2]}`;
            }).join('\n')

            if (dataSheet.length == filteredData.length) {
                setPoDetail(filteredData)
                setInitData(filteredData)
            } else {
                alert(`There are differences between the data from Excel and the data from the master product\n\nDO : ${selectedSheet}\n\n${formatedDataAlert}\n\nThe above article is not in the product master`)
            }
        } else if (poType === 'Pembelian') {
            const filteredData = dataProduct
                .filter(item => dataSheet.some(sheetData => sheetData[0] === item.article && sheetData[2] == item.size))
                .map(item => {
                    const sheetItem = dataSheet.find(data => data[0] === item.article && data[2] == item.size)
                    if (sheetItem) {
                        return {
                            ...item,
                            qty: sheetItem[1],
                            total: sheetItem[1] * item.cogs,
                            selected_size: sheetItem[2]
                        }
                    }
                })

            const unmatchedArticle = dataSheet.filter((item, i) => {
                console.log('item:', item[2])
                // Cek apakah item memiliki pasangan di dataSheet
                return !dataProduct.some(prodData => prodData.article == item[0] && item[2] == prodData.size)
            })
            console.log('data Produk:', dataProduct)
            const formatedDataAlert = unmatchedArticle.map(row => {
                return `Article: ${row[0]}, Qty: ${row[1]}, Size: ${row[2]}`;
            }).join('\n')
            console.log("datasheet:", dataSheet)
            console.log("filtered:", filteredData)
            if (dataSheet.length == filteredData.length) {
                setPoDetail(filteredData)
                setInitData(filteredData)
            } else {
                alert(`There are differences between the data from Excel and the data from the master product\n\nDO : ${selectedSheet}\n\n${formatedDataAlert}\n\nThe above article is not in the product master`)
            }
        }
    }, [dataSheet])

    useEffect(() => {
        getPo()
    }, [dateRange])

    useEffect(() => {
        getPo()
    }, [])

    useEffect(() => {
        setTotalQty(poDetail.reduce((total, curent) => total + parseInt(curent.qty), 0))
        setGrandTotal(poDetail.reduce((total, curent) => total + parseFloat(curent.total), 0))
        console.log(poDetail)
        console.log('grand total :', poDetail.reduce((total, curent) => total + parseFloat(curent.total), 0))
    }, [poDetail])

    useEffect(() => {
        if (poType === 'Retur Pembelian') {
            getStockByWarehouse(warehouse)
        } else if (poType === 'Pembelian') {
            getProduct()
        }
    }, [warehouse])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Purchase Order</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={4} lg={4} md={4} sm={12} className="mb-5">
                        <Button content="Create New PO" icon="add" onClick={handleClickAdd} color="blue" className="me-3" />
                    </Col>
                    <Col xl={8} lg={8} md={8} sm={12} className="text-end mb-5">
                        <DateRangePicker
                            className="mb-2"
                            label="Date Range : "
                            format="yyyy-MM-dd"
                            placement="auto"
                            size="md"
                            cleanable={true}
                            defaultValue={dateRange}
                            value={dateRange}
                            onChange={handleChangeDate}
                            onClean={() => setDateRange([
                                new Date(moment().startOf('month')),
                                new Date(moment()),
                            ])}
                        />
                        <Button content="Export to Excel" icon="file excel" color="blue" onClick={handlePreExport} className="ms-3" />
                    </Col>
                    <Col xl={12} lg={12} md={12}>
                        <Reacttable data={dataPo} columns={columns} pageRow={6} noData={dataPo.length === 0 ? true : false} />
                    </Col>
                    <Col xl={12} lg={12} md={12}>
                        <Row>
                            <Col xl={12} className="mb-2">
                                <Popup content="PO" size="mini" trigger={
                                    <Label icon='file alternate' content={totalPo} />
                                } />
                                <Popup content="Grand Total Cogs" size="mini" trigger={
                                    <Label icon='money' content={poAmount} />
                                } />
                                <Popup content="Total Quantity" size="mini" trigger={
                                    <Label icon='box' content={poQty} />
                                } />
                                <Popup content="Incomplete PO" size="mini" trigger={
                                    <Label icon='exclamation triangle' content={poPartial} />
                                } />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAdd} backdrop='static' size="lg" onClose={() => setModalAdd(false)} overflow={false} >
                <Modal.Header>
                    <Modal.Title>Create Purchase Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="mb-3" id="formHeader" onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Input type="date" label="PO Date" value={poDate} onChange={(e) => setPoDate(e.target.value)} required />
                            <Form.Input type="date" label="Delivery Date" value={poDelDate} onChange={(e) => setPoDelDate(e.target.value)} required />
                            <FormSelect search width={5} options={dataVendorOpt} label="Supplier" value={supplier} onChange={(e, { value }) => setSupplier(value)} required />
                            <FormSelect search width={5} options={dataWarehouseOpt} label="Warehouse" value={warehouse} onChange={(e, { value }) => handleChangeWh(value)} required />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input type="number" width={2} label="PPN" value={ppn} onChange={(e) => setPpn(e.target.value)} required />
                            <Form.Input type="text" width={8} label="Description" value={desc} onChange={(e) => setDesc(e.target.value)} placeholder="Write description if needed" />
                        </Form.Group>
                        <Row>
                            <Col xl={3} lg={3} md={3} sm={6} xs={12}>
                                <Form.Group grouped>
                                    <label>PO Type</label>
                                    <Form.Radio
                                        label="Pembelian"
                                        value="Pembelian"
                                        checked={poType === 'Pembelian'}
                                        onChange={handleChangeType}
                                    />
                                    <Form.Radio
                                        label="Retur Pembelian"
                                        value="Retur Pembelian"
                                        checked={poType === 'Retur Pembelian'}
                                        onChange={handleChangeType}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xl={3} lg={3} md={3} sm={6} xs={12}>
                                <Form.Group grouped>
                                    <label>Payment Type</label>
                                    <Form.Radio
                                        label="Tunai"
                                        value="Tunai"
                                        checked={paymentType === 'Tunai'}
                                        onChange={handleChangePay}
                                    />
                                    <Form.Radio
                                        label="Kredit"
                                        value="Kredit"
                                        checked={paymentType === 'Kredit'}
                                        onChange={handleChangePay}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                    {/* <Divider horizontal>Product detail</Divider> */}
                    <Row>
                        <Col lg={12} className="mb-2">
                            <Tabs defaultActiveKey="1">
                                <Tabs.Tab eventKey="1" title="Input Manualy">
                                    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                        <Table>
                                            <thead style={{ fontSize: '0.9em' }}>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Article</th>
                                                    <th>Description</th>
                                                    <th>Size</th>
                                                    <th>Qty</th>
                                                    <th>Cogs</th>
                                                    <th>Sell. Price</th>
                                                    <th>Total Cogs</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody style={tableBodyStyle}>
                                                <tr className="align-middle">
                                                    <td colSpan={2}>
                                                        <AutoComplete size="sm" data={productOpt} style={{ width: 120 }} onSelect={handleSelectArt} />
                                                    </td>
                                                    <td>
                                                        <InputRS type="text" size="sm" value={tempRow ? tempRow.description : ''} style={{ width: 270 }} readOnly />
                                                    </td>
                                                    <td>
                                                        <SelectPicker
                                                            disabled={inputDisable}
                                                            size="sm"
                                                            value={tempRow?.selected_size}
                                                            data={sizeOpt}
                                                            searchable={false}
                                                            style={{ width: 70 }}
                                                            onChange={handleSelectSize}
                                                        />
                                                    </td>
                                                    <td>
                                                        <InputRS type="number" size="sm" style={{ width: 60 }} min={0} value={tempRow ? tempRow.qty : ''} onChange={handleChangeQty} disabled={inputDisable} />
                                                    </td>
                                                    <td>
                                                        <InputRS type="text" size="sm" style={{ width: 70 }} value={tempRow ? tempRow.cogs : ''} readOnly />
                                                    </td>
                                                    <td>
                                                        <InputRS type="text" size="sm" style={{ width: 70 }} value={tempRow ? tempRow.price : ''} readOnly />
                                                    </td>
                                                    <td>
                                                        <InputRS type="text" size="sm" style={{ width: 95 }} value={tempRow ? tempRow.total : ''} readOnly />
                                                    </td>
                                                    <td>
                                                        <Button icon="plus" size="mini" onClick={() => handleClickAddRow(tempRow)} color="blue" />
                                                    </td>
                                                </tr>
                                                {
                                                    poDetail?.map((item, i) => (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>{item.article}</td>
                                                            <td>{item.description}</td>
                                                            <td>{item.selected_size}</td>
                                                            <td>{item.qty}</td>
                                                            <td>{curencyNoSym.format(item.cogs)}</td>
                                                            <td>{curencyNoSym.format(item.price)}</td>
                                                            <td>{curencyNoSym.format(item.total)}</td>
                                                            <td>
                                                                <Popup content="Remove item" size="small" trigger={
                                                                    <Icon name="close" color="orange" onClick={() => handleRemoveProduct(item.article, item.size, item.barcode)} style={{ cursor: 'pointer' }} />
                                                                } />
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                            <tfoot style={{ fontSize: '0.9em', fontWeight: 'bold' }}>
                                                <tr>
                                                    <td colSpan={4}>Total : </td>
                                                    <td>{totalQty}</td>
                                                    <td>{curencyNoSym.format(poDetail.reduce((total, cur) => total + parseFloat(cur.cogs), 0))}</td>
                                                    <td>{curencyNoSym.format(poDetail.reduce((total, cur) => total + parseFloat(cur.price), 0))}</td>
                                                    <td>{curencyNoSym.format(grandTotal)}</td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                </Tabs.Tab>
                                <Tabs.Tab eventKey="2" title="Data From Excel">
                                    <Row>
                                        <Col lg={8}>
                                            <Form onSubmit={handleImport} id="frmImport">
                                                <Form.Group>
                                                    <Form.Input type="file" icon={{ name: 'file excel', color: 'green' }} required iconPosition="left" size="mini" onChange={handleFile} action={{ type: "submit", form: 'frmImport', color: 'blue', loading: loadSheet, disabled: loadSheet, icon: "arrow alternate circle right outline" }} />
                                                    <Form.Dropdown
                                                        clearable
                                                        disabled={disableSheetList}
                                                        placeholder="Select Sheet"
                                                        options={sheetList}
                                                        value={selectedSheet}
                                                        onChange={(e, { value }) => handleChangeSheet(value)}
                                                        selection
                                                    />
                                                    <Form.Input
                                                        placeholder="Search by Article"
                                                        icon="search"
                                                        value={searchValue}
                                                        disabled={disableSheetList}
                                                        onChange={(e) => handleChangeSearch(e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                        <Col lg={4} className="text-end">
                                            <Button.Group basic size="tiny">
                                                <Popup content="Clear Detail" size="tiny" trigger={
                                                    <Button icon={{ name: "undo alternate", color: 'blue' }} onClick={handleClearPoDetail} disabled={disableSheetList} />
                                                } />
                                                <Popup content="Download Template" size="tiny" trigger={
                                                    <Button icon={{ name: "download", color: 'blue' }} as="a" href={urlTemplate} download />
                                                } />
                                            </Button.Group>
                                        </Col>
                                        <Col lg={12}>
                                            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                <Table>
                                                    <thead style={{ fontSize: '0.9em' }}>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Article</th>
                                                            <th>Description</th>
                                                            <th>Size</th>
                                                            <th>Qty</th>
                                                            <th>Cogs</th>
                                                            <th>Sell. Price</th>
                                                            <th>Total Cogs</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={tableBodyStyle}>
                                                        {
                                                            initData.length === 0 ?
                                                                <tr>
                                                                    <td colSpan={8} className="text-center"><Icon name="exclamation circle" color="orange" />No data</td>
                                                                </tr>
                                                                :
                                                                initData.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td>{i + 1}</td>
                                                                        <td>{item.article}</td>
                                                                        <td>{item.description}</td>
                                                                        <td>{item.size}</td>
                                                                        <td>{item.qty}</td>
                                                                        <td>{curencyNoSym.format(item.cogs)}</td>
                                                                        <td>{curencyNoSym.format(item.price)}</td>
                                                                        <td>{curencyNoSym.format(item.total)}</td>
                                                                    </tr>
                                                                ))
                                                        }
                                                    </tbody>
                                                    <tfoot style={{ fontSize: '0.9em' }} className="fw-bold">
                                                        <tr>
                                                            <td colSpan={4}>Total : </td>
                                                            <td>{totalQty}</td>
                                                            <td>{curencyNoSym.format(poDetail.reduce((total, cur) => total + parseFloat(cur.cogs), 0))}</td>
                                                            <td>{curencyNoSym.format(poDetail.reduce((total, cur) => total + parseFloat(cur.price), 0))}</td>
                                                            <td>{curencyNoSym.format(grandTotal)}</td>
                                                        </tr>
                                                    </tfoot>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tabs.Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-end ">
                        <Button
                            content="Create"
                            type="submit"
                            icon="arrow alternate circle right outline"
                            labelPosition="right"
                            form="formHeader"
                            loading={loaderCreatePo}
                            disabled={loaderCreatePo}
                            color="blue"
                        />
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal open={modalPoDetail} onClose={() => setModalPoDetail(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Purchase Order Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row >
                        <Col lg={6} className="mb-3" style={{ fontSize: '0.9em' }} >
                            <div className="fw-bold">{selectedPo?.header.type}</div>
                            <div>Po Number : {selectedPo?.header.po_number}</div>
                            <div>Warehouse : {selectedPo?.header.warehouse_desc}</div>
                        </Col>
                        <Col lg={12}>
                            {/* <Table className="table table-no-wrap">
                                <thead style={{ fontSize: '0.9em' }}>
                                    <tr>
                                        <th>#</th>
                                        <th>Brand</th>
                                        <th>Article</th>
                                        <th>Description</th>
                                        <th>Size</th>
                                        <th>Qty</th>
                                        <th>Cogs</th>
                                        <th>Price</th>
                                        <th>Total Cogs</th>
                                        <th>Rec</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody style={{ fontSize: '0.8em' }}>
                                    {
                                        selectedPo?.detail.map((item, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{item.brand_code}</td>
                                                <td>{item.article}</td>
                                                <td style={{ maxWidth: '100px' }} className="text-truncate">{item.description}</td>
                                                <td>{item.size}</td>
                                                <td>{item.qty}</td>
                                                <td>{curencyNoSym.format(item.cogs)}</td>
                                                <td>{curencyNoSym.format(item.price)}</td>
                                                <td>{curencyNoSym.format(item.qty * item.cogs)}</td>
                                                <td>{item.qty_receipt}</td>
                                                <td>
                                                    <Stack direction="horizontal" gap={0}>
                                                        <div className="me-2"><Badge style={{ background: badgeColor(item.item_status) }} /></div>
                                                        <div className="text-capitalize">{item.item_status}</div>
                                                    </Stack>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot style={{ fontSize: '0.8em', fontWeight: 'bold' }}>
                                    <tr>
                                        <td colSpan={5}>Total :</td>
                                        <td>{selectedPo?.detail?.reduce((total, cur) => total + parseFloat(cur.qty), 0)}</td>
                                        <td>{curencyNoSym.format(selectedPo?.detail?.reduce((total, cur) => total + parseFloat(cur.cogs), 0))}</td>
                                        <td>{curencyNoSym.format(selectedPo?.detail?.reduce((total, cur) => total + parseFloat(cur.price), 0))}</td>
                                        <td>{curencyNoSym.format(selectedPo?.detail?.reduce((total, cur) => total + (parseFloat(cur.cogs) * parseInt(cur.qty)), 0))}</td>
                                        <td colSpan={2}>{selectedPo?.detail?.reduce((total, cur) => total + parseInt(cur.qty_receipt), 0)}</td>
                                    </tr>
                                </tfoot>
                            </Table> */}
                            <Reacttable data={selectedPo?.detail} columns={columnsPoDetails} pageRow={6} totals={['qty', 'cogs', 'price', 'qty_receipt']} />
                        </Col>
                        <Col lg={12} style={{ fontSize: '0.9em' }}>
                            <span className="fw-bold me-4"><span style={{ color: 'coral' }}>Total Qty</span> : {selectedPo?.header.total_qty} pcs</span>
                            <span className="fw-bold"><span style={{ color: 'coral' }}>Total PO</span>  : {curency.format(selectedPo?.header.grand_total)}</span>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal open={modalExpFilter} onClose={() => setModalExpFilter(false)} overflow={false} size="xs">
                <Modal.Header>
                    <Modal.Title>Filter By</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmFilter">
                        <FormField
                            label="Supplier"
                            control={Select}
                            options={supplierOpt}
                            selection
                            clearable
                            search
                            placeholder='All'
                            onChange={(e, { value }) => setFilterSupplier(value)}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit Export" color="blue" form="frmFilter" onClick={() => handleExport()} />
                </Modal.Footer>
            </Modal>
        </>
    )
}
import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import axios from "axios";
import Stack from "react-bootstrap/Stack"
import { useMyGlobal } from "../component/Global";
import Modal from "rsuite/Modal"
import { Button, Form, Icon, Popup } from "semantic-ui-react";
import { Reacttable } from "../component/Reacttable";
import { Toaster } from "../component/Toaster";
import moment from "moment";
import DateRangePicker from 'rsuite/DateRangePicker';


export const StockOpnameRelease = () => {
    const { userProfile, host } = useMyGlobal()
    const [modalRelease, setModalRelease] = useState(false)
    const [soEntryData, setSoEntryData] = useState([])
    const [modalEntryDetail, setModalEntryDetail] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedRelease, setSelectedRelease] = useState(null)
    const [soRelaseData, setSoReleaseData] = useState([])
    const [modalDetail, setModalDetail] = useState(false)
    const [loadGenerate, setLoadGenerate] = useState(false)

    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])

    const columnRelease = useMemo(() => [
        {
            Header: 'Release Number',
            accessor: 'header.release_number'
        },
        {
            Header: 'SO Number',
            accessor: 'header.so_number'
        },
        {
            Header: 'Release Time',
            accessor: 'header.release_time'
        },
        {
            Header: 'Total Qty',
            accessor: 'header.total_qty'
        },
        {
            Header: 'Qty Loss',
            accessor: 'header.total_qty_loss'
        },
        {
            Header: 'Created By',
            accessor: 'header.user_name',
            Cell: ({ cell }) => (
                <Stack direction="vertical" style={{ lineHeight: '1em' }}>
                    <div >{cell.value}</div>
                    <div className="text-muted text-capitalize">{cell.row.original.header.role_name}</div>
                </Stack>
            )
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group size="mini" basic>
                    <Popup content="View Detail" size="tiny" trigger={
                        <Button icon={{ name: 'list layout', color: 'blue' }} onClick={() => handleClickDetail(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const columnsSoEntry = useMemo(() => [
        {
            Header: 'SO Number',
            accessor: 'header.so_number'
        },
        {
            Header: 'SO Date',
            accessor: 'header.so_date'
        },
        {
            Header: 'Description',
            accessor: 'header.description',
            Cell: ({ cell }) => (
                <div style={{ width: '125px' }}>
                    <div className="text-truncate">{cell.value}</div>
                </div>
            )
        },
        {
            Header: 'Warehouse',
            accessor: 'header.wh_desc'
        },
        {
            Header: 'Brand',
            accessor: 'header.brand'
        },
        {
            Header: 'Total Loss',
            accessor: 'header.total_loss'
        },
        {
            Header: 'Variance',
            accessor: 'header.is_variance'
        },
        {
            Header: 'Validation Time',
            accessor: 'header.validation_time'
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => {
                return (
                    <Button.Group size="mini" basic>
                        <Popup content="View Detail" size="tiny" trigger={
                            <Button icon={{ name: 'list layout', color: 'blue' }} onClick={() => handleViewDetail(cell.row.original)} />
                        } />
                        <Popup content="Create Release" size="tiny" trigger={
                            <Button icon={{ name: 'share', color: 'blue' }} onClick={() => handleClickRelease(cell.row.original)} />
                        } />
                    </Button.Group>
                )
            }
        },
    ])

    const columnDetail = useMemo(() => [
        {
            Header: 'Article',
            accessor: 'article'
        },
        {
            Header: 'Barcode',
            accessor: 'barcode'
        },
        {
            Header: 'Size',
            accessor: 'size'
        },
        {
            Header: 'Description',
            accessor: 'description',
            Cell: ({ cell }) => (
                <div style={{ width: '120px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: 'Qty',
            accessor: 'qty'
        },
        {
            Header: 'SO Qty',
            accessor: 'so_qty'
        },
        {
            Header: 'Qty Loss',
            accessor: 'qty_loss',
        },
        {
            Header: 'SO Matching',
            accessor: 'so_matching'
        },
        {
            Header: 'Diff',
            accessor: 'difference'
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ cell }) => (
                <div>
                    {
                        cell.value === "Difference" ?
                            <>
                                <span><Icon name="exclamation circle" color="orange" /></span>
                                <span>{cell.value}</span>
                            </>
                            :
                            <>
                                <span><Icon name="check circle" color="blue" /></span>
                                <span>{cell.value}</span>
                            </>
                    }
                </div>
            )
        },
    ])

    const handleClickDetail = (data) => {
        setSelectedRelease(data)
        setModalDetail(true)
    }

    const handleClickRelease = (data) => {
        const cfm = window.confirm("Are you sure you want to continue this?\nStock will be adjusted according to (so qty) or (so matching)")
        if (cfm) {
            axios({
                method: 'POST',
                url: `${host}/stock/index.php?action=createRelease`,
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: data
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: 'Success create SO release',
                        position: 'top-right'
                    })
                    getSoEntry()
                    getSoRelease()
                    setModalEntryDetail(false)
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                }
            })
        }
    }

    const handleViewDetail = (data) => {
        setSelectedRow(data)
        setModalEntryDetail(true)
    }

    const handleClickCreate = () => {
        setModalRelease(true)
    }

    const getSoRelease = async () => {
        setLoadGenerate(true)
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: 'GET',
            url: `${host}/stock/index.php`,
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params:{
                action:'soRelease',
                range:date_range
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSoReleaseData(res.data.data)
            }
        }).finally(() => { setLoadGenerate(false)})
    }

    const getSoEntry = async () => {
        await axios({
            method: 'GET',
            url: `${host}/stock/index.php?action=soEntry`,
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                const filterSoEntry = res.data.data.filter(item => item.header.validate === 'Y' && item.header.is_release === 'N')
                setSoEntryData(filterSoEntry)
            }
        })
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const handleGenerateData = () => {
        getSoRelease(dateRange)
    }

    useEffect(() => {
        getSoEntry()
        getSoRelease(dateRange)
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Stock Opname Release</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={4} lg={4} md={4} sm={12} className="mb-3">
                        <Button content="Create Stock Opname Release" icon="add" color="blue" onClick={handleClickCreate} />
                    </Col>
                    <Col xl={6} lg={8} md={8} xs={12} className="mb-3 text-end">
                        <DateRangePicker
                            style={{ width: '60%' }}
                            label="Date Range : "
                            format="yyyy-MM-dd"
                            placement="auto"
                            cleanable={true}
                            defaultValue={dateRange}
                            value={dateRange}
                            onChange={handleChangeDate}
                            onClean={() => setDateRange([
                                new Date(moment().startOf('month')),
                                new Date(moment()),
                            ])}
                        />
                        <Button content="Generate Data" loading={loadGenerate} disabled={loadGenerate} className="ms-2" style={{ paddingTop: '10px' }} color="blue" onClick={() => handleGenerateData()} />
                    </Col>
                    <Col xl={10} lg={12} md={12} sm={12}>
                        <Reacttable
                            data={soRelaseData}
                            columns={columnRelease}
                            pageRow={10}
                            noData={soRelaseData.length === 0 ? true : false}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalRelease} onClose={() => setModalRelease(false)} backdrop="static" overflow={false} size="lg">
                <Modal.Header>
                    <Modal.Title>Create Stock Opname Release</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable
                        data={soEntryData}
                        columns={columnsSoEntry}
                        pageRow={5}
                        noData={soEntryData.length === 0 ? true : false}
                    />
                </Modal.Body>
            </Modal>

            <Modal open={modalEntryDetail} onClose={() => setModalEntryDetail(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>SO Entry Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable
                        data={selectedRow?.detail}
                        columns={columnDetail}
                        pageRow={10}
                        totals={['qty', 'so_qty', 'qty_loss', 'so_matching']}
                    />
                </Modal.Body>
            </Modal>

            <Modal open={modalDetail} onClose={() => setModalDetail(false)} overflow={false} backdrop='static' size="md">
                <Modal.Header>
                    <Modal.Title>SO Release Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable
                        data={selectedRelease?.detail}
                        columns={columnDetail}
                        pageRow={10}
                        totals={['qty', 'so_qty', 'qty_loss', 'so_matching']}
                    />
                </Modal.Body>
            </Modal>

        </>
    )
}
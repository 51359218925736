import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, Form, Popup } from "semantic-ui-react";
import Modal from "rsuite/Modal";
import { useMyGlobal } from "../component/Global";
import axios from "axios";
import { Toaster } from "../component/Toaster";
import { Reacttable } from "../component/Reacttable";
import Badge from "rsuite/Badge";

export const Warehouse = () => {
    const [modalAdd, setModalAdd] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const { cityOpt, provOpt, host } = useMyGlobal()
    const [city, setCity] = useState('')
    const [province, setProvince] = useState('')
    const [desc, setDesc] = useState('')
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [pic, setPic] = useState('')
    const [type, setType] = useState('Normal')
    const [idWarehouse, setIdwarehouse] = useState('')
    const [dataWarehouse, setDataWarehouse] = useState([])

    const columns = useMemo (() => [
        {
            Header: "ID",
            accessor: "id_warehouse"
        },
        {
            Header: "Description",
            accessor: "desc"
        },
        {
            Header: "PIC",
            accessor: "pic"
        },
        {
            Header: "Phone",
            accessor: "phone"
        },
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header: "City",
            accessor: "city"
        },
        {
            Header: "Type",
            accessor: "type",
            Cell:({cell}) => (
                <div><Badge color={cell.value === 'Normal' ? 'green' : cell.value === 'Transit' ? 'orange' : 'grey'} className="me-2"/>{cell.value}</div>
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell : ({cell}) => (
                <ButtonGroup basic size="mini">
                    <Popup content="Edit" size="mini" trigger={
                        <Button icon={{name:'edit outline', color:'blue'}} onClick={() => handleClickEdit(cell.row.original)}/>
                    }/>
                    <Popup content="Delete" size="mini" trigger={
                            <Button icon={{name:'trash alternate', color:'blue'}} onClick={() => handleDelete(cell.row.original.id_warehouse)}/>
                    }/>
                </ButtonGroup>
            )
        },
    ])

    const handleClickEdit = (row) => { 
        setIdwarehouse(row.id_warehouse)
        setDesc(row.desc)
        setCity(cityOpt?.find(i => i.text === row.city).value)
        setProvince(row.prov)
        setAddress(row.address)
        setPhone(row.phone)
        setEmail(row.email)
        setPic(row.pic)
        setType(row.type)
        setModalEdit(true)
    }

    const handleDelete = (id) => {
        axios({
            method: "DELETE",
            url: `${host}/showroom/index.php?action=deleteWarehouse&id=${id}`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then (res => {
            if (res.data.status === 200){
                Toaster.fire({
                    icon:'success',
                    text:'Success delete selected Warehouse',
                    position:'top-right'
                })
                getDataWarehouse()
            } else {
                Toaster.fire({
                    icon:'error',
                    text:'Failed delete selected Warehouse',
                    position:'top-right'
                })
            }
        })
    }

    const handleEdit = () => {
        const param = {
            id_warehouse:idWarehouse,
            prov: province,
            city: cityOpt?.find(item => item.value === city).text,
            address: address,
            desc: desc,
            phone: phone,
            email: email,
            pic: pic,
            type: type,
            action: 'editWarehouse',
        }

        axios({
            method: "PUT",
            url: `${host}/showroom/index.php?action=editWarehouse`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data:param
        }).then ( res => {
            if (res.data.status === 200){
                Toaster.fire({
                    icon:'success',
                    text:'Success updated',
                    position:'top-right'
                })
                setModalEdit(false)
                getDataWarehouse()
            } else {
                Toaster.fire({
                    icon:'error',
                    text:res.data.msg,
                    position:'top-right'
                })
            }
        })
    }

    const getDataWarehouse  = async () => {
        await axios({
            method: "GET",
            url: `${host}/showroom/index.php?action=warehouse`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataWarehouse(res.data.data)
            }
        })
    }

    const handleChangeType = (e, { value }) => setType( value )

    const handleClickAdd = () => {
        setModalAdd(true)
    }

    const handleChangeCity = (id) => {
        setCity(id)
        setProvince(provOpt?.find(item => item.value === id).text)
    }

    const handleSubmit = () => {
        const param = new FormData()
        param.append('prov', province)
        param.append('city', cityOpt?.find(item => item.value === city).text)
        param.append('address', address)
        param.append('desc', desc)
        param.append('phone', phone)
        param.append('email', email)
        param.append('pic', pic)
        param.append('type', type)
        param.append('action', 'addWarehouse')

        axios ({
            method: 'POST',
            url: `${host}/showroom/index.php?action=addWarehouse`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then (res => {
            if (res.data.status === 200){
                Toaster.fire({
                    icon:'success',
                    text:'Success adding new warehouse',
                    position:'top-right'
                })
                setModalAdd(false)
                getDataWarehouse()
            } else {
                Toaster.fire({
                    icon:'error',
                    text:res.data.msg,
                    position:'top-right'
                })
            }
        })
    }

    useEffect(() => {
        getDataWarehouse()
    }, [])
 
    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Warehouse</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={10} lg={10} md={12} sm={12} className="mb-3">
                        <Button content="Add New Warehouse" icon="plus" onClick={handleClickAdd} color="blue"/>
                    </Col>
                    <Col xl={10} lg={10} md={12} sm={12}>
                        <Reacttable data={dataWarehouse} columns={columns} page={10} noData={dataWarehouse.length === 0 ? true : false}/>
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAdd} backdrop="static" onClose={() => setModalAdd(false)} overflow={false}>
                <Modal.Header>
                    <Modal.Title>Add New Warehouse</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form widths="equal" onSubmit={handleSubmit}>
                        <Form.Input label="Description" value={desc} type="text" fluid required onChange={(e) => setDesc(e.target.value)} />
                        <Form.Group>
                            <Form.Select
                                search
                                options={cityOpt}
                                label="City"
                                value={city}
                                onChange={(e, { value }) => handleChangeCity(value)}
                            />
                            <Form.Input type="text" label="Province" value={province} onChange={(e) => setProvince(e.target.value)} readOnly />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input type="text" label="Address" value={address} onChange={(e) => setAddress(e.target.value)}/>
                        </Form.Group>
                        <Form.Group widths={4}>
                            <Form.Input type="phone" label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                            <Form.Input type="email" label="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                            <Form.Input type="text" label="PIC Name" value={pic} onChange={(e) => setPic(e.target.value)}/>
                        </Form.Group>
                        <Form.Group grouped>
                            <label>Warehouse Type</label>
                            <Form.Radio
                                label='Normal'
                                value='Normal'
                                checked={type === 'Normal'}
                                onChange={handleChangeType}
                            />
                            <Form.Radio
                                label='Transit'
                                value='Transit'
                                checked={type === 'Transit'}
                                onChange={handleChangeType}
                            />
                            <Form.Radio
                                label='Damage'
                                value='Damage'
                                checked={type === 'Damage'}
                                onChange={handleChangeType}
                            />
                        </Form.Group>
                        <div className="text-end mt-5">
                            <Button content="Save" icon="save outline" />
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal open={modalEdit} backdrop="static" onClose={() => setModalEdit(false)} overflow={false}>
                <Modal.Header>
                    <Modal.Title>Add New Warehouse</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form widths="equal" onSubmit={handleEdit}>
                        <Form.Input label="Description" value={desc} type="text" fluid required onChange={(e) => setDesc(e.target.value)} />
                        <Form.Group>
                            <Form.Select
                                search
                                options={cityOpt}
                                label="City"
                                value={city}
                                onChange={(e, { value }) => handleChangeCity(value)}
                            />
                            <Form.Input type="text" label="Province" value={province} onChange={(e) => setProvince(e.target.value)} readOnly />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input type="text" label="Address" value={address} onChange={(e) => setAddress(e.target.value)}/>
                        </Form.Group>
                        <Form.Group widths={4}>
                            <Form.Input type="phone" label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                            <Form.Input type="email" label="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                            <Form.Input type="text" label="PIC Name" value={pic} onChange={(e) => setPic(e.target.value)}/>
                        </Form.Group>
                        <Form.Group grouped>
                            <label>Warehouse Type</label>
                            <Form.Radio
                                label='Normal'
                                value='Normal'
                                checked={type === 'Normal'}
                                onChange={handleChangeType}
                            />
                            <Form.Radio
                                label='Transit'
                                value='Transit'
                                checked={type === 'Transit'}
                                onChange={handleChangeType}
                            />
                            <Form.Radio
                                label='Damage'
                                value='Damage'
                                checked={type === 'Damage'}
                                onChange={handleChangeType}
                            />
                        </Form.Group>
                        <div className="text-end mt-5">
                            <Button content="Update" icon="edit outline" color="blue"/>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

        </>
    )
}
import React, { useEffect, useMemo, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Button, Divider, Form, FormField, Icon, Loader, Popup, Select } from "semantic-ui-react";
import Modal from "rsuite/Modal";
import { useMyGlobal } from "../component/Global";
import { Reacttable } from "../component/Reacttable";
import axios from "axios";
import { badgeColor, curency, curencyNoSym, currentDate } from "../component/utils";
import { Toaster } from "../component/Toaster";
import Badge from 'rsuite/Badge';
import Stack from "react-bootstrap/Stack";
import DateRangePicker from 'rsuite/DateRangePicker';
import moment from "moment";


export const GoodsReceipt = () => {
    const [modalAddGoods, setModalAddGoods] = useState(false)
    const [modalPoList, setModalPoList] = useState(false)
    const [modalAdjust, setModalAdjust] = useState(false)
    const [modalGrDetail, setModalGrDetail] = useState(false)
    const [modalDetailPo, setModalDetailPo] = useState(false)
    const [selectedPo, setSelectedPo] = useState(null)
    const [doNumber, setDoNumber] = useState('')
    const [doDate, setDoDate] = useState(currentDate)
    const [dataPo, setDataPo] = useState([])
    const [dataGr, setDataGr] = useState([])
    const [reference, setReference] = useState('')
    const { host, userProfile, supplierOpt } = useMyGlobal()
    const [tempQty, setTempQty] = useState(0)
    const [selectedDataGr, setSelectedDataGr] = useState(null)
    const [selectedRowDetail, setSelectedRowDetail] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const [poDetailView, setPoDetailView] = useState(null)
    const [totalCogs, setTotalCogs] = useState(0)
    const [loader, setLoader] = useState(false)
    const [modalExpFilter, setModalExpFilter] = useState(false)
    const [filterSupplier, setFilterSupplier] = useState('')

    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])

    const columnsDetail = useMemo(() => [
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Qty",
            accessor: "qty"
        },
        {
            Header: "Qty Rec.",
            accessor: "qty_receipt"
        },
        {
            Header: "Status",
            accessor: "item_status",
            Cell: ({ cell }) => (
                <>
                    <span className="me-2"><Badge style={{ background: badgeColor(cell.value) }} /></span>
                    <span className="text-capitalize">{cell.value}</span>
                </>
            )
        },
    ])

    const columns = useMemo(() => [
        {
            Header: "PO Number",
            accessor: "header.po_number"
        },
        {
            Header: "PO Date",
            accessor: "header.date_po"
        },
        {
            Header: "PO Type",
            accessor: "header.type"
        },
        {
            Header: "Vendor",
            accessor: "header.vendor_name"
        },
        {
            Header: "Warehouse",
            accessor: "header.warehouse_desc"
        },
        {
            Header: "Qty",
            accessor: "header.total_qty"
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => {
                const updatedDetail = cell.row.original.detail.map(item => {
                    const qty = parseInt(item.qty);
                    const qty_receipt = parseInt(item.qty_receipt);

                    if (qty_receipt === 0) {
                        return { ...item, qty_receipt: qty };
                    }
                    return item;
                });

                cell.row.original.detail = updatedDetail

                return (
                    <Button.Group basic size="mini">
                        <Popup content="Select" size="small" trigger={
                            <Button icon={{name:'hand point up outline', color:'blue'}} size="mini" onClick={() => handleSelectPo(cell.row.original)} />
                        } />
                        <Popup content="View Detail" size="small" trigger={
                            <Button icon={{name:'list layout', color:'blue'}} size="mini" onClick={() => handleViewDetail(cell.row.original)} />
                        } />
                    </Button.Group>
                )
            }
        }
    ])

    const columnsDataGR = useMemo(() => [
        {
            Header: "GR Number",
            accessor: "header.gr_number"
        },
        {
            Header: "DO Number",
            accessor: "header.do_number"
        },
        {
            Header: "PO Number",
            accessor: "header.po_number"
        },
        {
            Header: "Vendor",
            accessor: "header.vendor_name"
        },
        {
            Header: "Warehouse",
            accessor: "header.warehouse_desc"
        },
        {
            Header: "GR Date",
            accessor: "header.gr_date"
        },
        {
            Header: "PO Type",
            accessor: "header.po_type"
        },
        {
            Header: "Ordered",
            accessor: "header.total_ordered"
        },
        {
            Header: "Receipt",
            accessor: "header.total_receipt"
        },
        {
            Header: "Status",
            accessor: "header.status",
            Cell: ({ cell }) => (
                <>
                    <span className="me-2"><Badge style={{ background: badgeColor(cell.value) }} /></span>
                    <span className="text-capitalize">{cell.value}</span>
                </>
            )
        },
        {
            Header: "User",
            accessor: "header.user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '0.8em' }}>{cell.row.original.header.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => {
                return (
                    <Button.Group size="mini" basic>
                        <Popup content="Detail" trigger={
                            <Button icon={{name:'list layout', color:'blue'}} onClick={() => handleClickModalGrDetail(cell.row.original)} />
                        } />
                        <Popup content="Delete this GR" trigger={
                            <Button icon={{name:'trash alternate', color:'blue'}} onClick={() => handleDeleteGr(cell.row.original)}/>
                        }/>
                    </Button.Group>
                )
            }
        },
    ])

    const columnsGrItem = useMemo(() => [
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Brand",
            accessor: "brand_code"
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Barcode",
            accessor: "barcode"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <Popup content={cell.value} size="tiny" trigger={
                    <div style={{ width: '100px' }} className="text-truncate">
                        {cell.value}
                    </div>
                }/>
            )
        },
        {
            Header: "Cogs",
            accessor: "cogs",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Qty",
            accessor: "qty_receipt"
        },
        {
            Header: "Total Cogs",
            accessor: 'total_cogs',
            Cell: ({ cell }) => (
                curencyNoSym.format(cell.row.original.qty_receipt * cell.row.original.cogs)
            )
        },
        {
            Header: "Status",
            accessor: "item_state",
            Cell: ({ cell }) => (
                <>
                    <span className="me-2"><Badge style={{ background: badgeColor(cell.value) }} /></span>
                    <span className="text-capitalize">{cell.value}</span>
                </>
            )
        }
    ])

    const columnProdDet = useMemo(() => [
        {
            Header: "Brand",
            accessor: "brand_code"
        },
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description"
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Cogs",
            accessor: "cogs",
            Cell: ({ cell }) => (
                curencyNoSym.format(cell.value)
            )
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (
                curencyNoSym.format(cell.value)
            )
        },
        {
            Header: "T.Cogs",
            accessor: "total_cogs",
            Cell: ({ cell }) => (
                curencyNoSym.format(cell.value)
            )
        },
        {
            Header: "T.Price",
            accessor: "total_price",
            Cell: ({ cell }) => (
                curencyNoSym.format(cell.value)
            )
        },
        {
            Header: "Order",
            accessor: "qty"
        },
        {
            Header: "Receipt",
            accessor: "qty_receipt"
        },
        {
            Header: "Status",
            accessor: "item_status",
            Cell: ({ cell }) => (
                <>
                    <span className="me-2"><Badge style={{ background: badgeColor(cell.value) }} /></span>
                    <span className="text-capitalize">{cell.value}</span>
                </>
            )
        },
        {
            Header: "Action",
            accessor: "",
            Cell: ({ cell }) => (
                <>
                    <Button.Group size="mini" basic>
                        <Popup content="Change Quantity" size="tiny" trigger={
                            <Button icon={{name:'edit outline', color:'blue'}} onClick={() => handleAdjustQty(cell.row.original)} disabled={cell.row.original.item_status === 'complete'} />
                        } />
                    </Button.Group>
                </>
            )
        },
    ])
 
    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const handleDeleteGr = async (data) => { 
        data.header.id_user = userProfile.profile.id_user
        const cfm = window.confirm("Are you sure?")
        if (cfm){
            try {
            await axios({
                method: "DELETE",
                url: `${host}/purchasing/index.php?action=deleteGoodReceipt&id=null`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data:data
            }).then (res => {
                if (res.data.status === 200){
                    Toaster.fire({
                        icon:'success',
                        text:'Success delete good receipt, data in stock will be back',
                        position:"top-right"
                    })
                    getDataGoodReceipt()
                } else {
                    Toaster.fire({
                        icon:'error',
                        text:res.data.msg,
                        position:"top-right"
                    })
                }
            })
            }catch(error){
                console.log(error)
            }
        }
    }

    const handleViewDetail = (data) => {
        setPoDetailView(data)
        setModalDetailPo(true)
    }

    const handleClickModalGrDetail = (data) => {
        setSelectedDataGr(dataGr?.find(i => i.header.gr_number === data.header.gr_number))
        setModalGrDetail(true)
    }
    
    const handleClickAdd = () => {
        setModalAddGoods(true)
    }

    const handleClickBrowse = () => {
        getPo()
        setModalPoList(true)
    }

    const handleSelectPo = (selected) => {
        const updatedDetail = selected.detail.map(item => ({ ...item, adjust: 0, adjust_type: 'manual' }))
        const updatedSelectedPo = {
            ...selected,
            detail: updatedDetail
        }

        setSelectedPo(updatedSelectedPo)
        setModalPoList(false)
    }

    const getPo = async () => {
        await axios({
            method: "GET",
            url: `${host}/purchasing/index.php?action=po`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataPo(res.data.data)
            }
        })
    }

    const handleSetQty = (data, qty) => {
        const maxQty = parseInt(data.qty) > data.qty_receipt ? parseInt(qty) + parseInt(data.qty_receipt) : parseInt(data.qty_receipt)
        console.log('max qty :', maxQty)
        console.log('data qty:', data.qty)
        console.log('data qty receipt:', data.qty_receipt)
        console.log('adjust:', qty)
        if (!isNaN(qty) && qty !== '') {
            if (parseInt(data.qty) >= maxQty && parseInt(qty) <= maxQty) {
                const updatedSelectedPo = selectedPo.detail.map(item => {
                    if (item.article === data.article && item.size === data.size) {
                        return {
                            ...item,
                            qty_receipt: parseInt(data.qty_receipt) === parseInt(data.qty) && data.item_status != 'complete' ? 0 + parseInt(qty) : parseInt(data.qty_receipt) + parseInt(qty),
                            item_status: qty === data.qty && qty != 0 ? 'complete' : qty < data.qty && qty != 0 ? 'partial' : '',
                            adjust: parseInt(qty)
                        }
                    }
                    return item;
                });
                setSelectedPo(prevState => ({
                    ...prevState,
                    detail: updatedSelectedPo
                }));
                setModalAdjust(false)
            } else {
                Toaster.fire({
                    icon: 'warning',
                    text: 'Adjust quantity exceeds the quantity ordered',
                    position: 'top-right'
                })
            }
        } else {
            Toaster.fire({
                icon: 'warning',
                text: 'Value must be a number',
                position: 'top-right'
            })
        }
    }

    const handleKeyDown = (event) => {
        // Mendeteksi apakah tombol yang ditekan adalah tombol backspace
        if (event.key === 'Backspace') {
            // Mencegah default action dari event tombol backspace
            event.preventDefault()
            // Menampilkan pesan atau melakukan tindakan lain jika diperlukan
            console.log('Pengguna mencoba menggunakan tombol backspace')
        }
    }

    const clearForm = () => {
        setSelectedPo(null)
        setDoNumber('')
        setDoDate(currentDate)
        setReference('')
        setTempQty('')
    }

    const handleSubmit = () => {
        const total_adjust = selectedPo?.detail?.reduce((a, b) => a + parseInt(b.qty_receipt), 0)
        let detail = []
        const cfm = window.confirm("Are you sure, have you checked everything?")
        if (cfm) {
            if (total_adjust > 0 || total_adjust < 0) {
                const header = JSON.stringify({
                    ...selectedPo.header,
                    do_number: doNumber,
                    id_user: userProfile.profile.id_user,
                    gr_date: doDate,
                    po_type: selectedPo.header.type,
                    reference: reference,
                    total_ordered: selectedPo.header.total_qty,
                    total_receipt: selectedPo?.detail?.reduce((a, b) => a + parseInt(b.qty_receipt), 0),
                    total_price: selectedPo?.detail?.reduce((a, b) => a + (parseFloat(b.price) * b.qty), 0),
                    total_cogs: selectedPo?.detail?.reduce((a, b) => a + (parseFloat(b.cogs) * b.qty), 0),
                })

                const updatedDetails = selectedPo.detail.map(item => {
                    if ((item.item_status === null || item.item_status === '') &&
                        parseInt(item.qty) === item.qty_receipt &&
                        (parseInt(item.qty) === item.qty_receipt && item.adjust === 0)) {
                        return { ...item, item_status: 'complete', adjust: item.qty_receipt, adjust_type: 'auto' }
                    }
                    return item
                })

                setSelectedPo(prevData => ({
                    ...prevData,
                    detail: updatedDetails
                }))

                if (selectedPo.header.type === 'Pembelian') {
                    detail = updatedDetails.filter(i => i.qty_receipt > 0)
                } else if (selectedPo.header.type === 'Retur Pembelian') {
                    detail = updatedDetails
                }

                const param = new FormData()
                param.append('header', header)
                param.append('detail', JSON.stringify(detail))
                axios({
                    method: "POST",
                    url: `${host}/purchasing/index.php?action=createGr`,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                    },
                    data: param
                }).then(res => {
                    if (res.data.status === 200) {
                        Toaster.fire({
                            icon: 'success',
                            text: 'Success create Good Receipt',
                            position: 'top-right'
                        })
                        clearForm()
                        setModalAddGoods(false)
                        getDataGoodReceipt()
                    } else {
                        Toaster.fire({
                            icon: 'error',
                            text: res.data.msg,
                            position: 'top-right'
                        })
                    }
                })
            } else {
                Toaster.fire({
                    icon: "warning",
                    text: "No adjusted items",
                    position: "top-right"
                })
            }
        }
    }

    const handleSubmitSearch = () => {
        const filtered = dataPo.filter(po => po.detail.some(item => item.article === searchValue))

        if (filtered.length > 0) {
            setDataPo(filtered)
        } else {
            console.log(filtered)
            Toaster.fire({
                icon: 'warning',
                title: 'Article not found on PO list',
                text: 'Make sure there are no white spaces or illegal characters',
                position: 'top-right'
            })
        }
    }

    const getDataGoodReceipt = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRange.map(date => formatDate(date));
        const date_range = [startDate, endDate];

        await axios({
            method: "GET",
            url: `${host}/purchasing/index.php?action=gr`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                range: date_range
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataGr(res.data.data)
                // const totalCogs = res.data.data.reduce((acc, current) => {
                //     const detailTotal = current.detail.reduce((detailAcc, item) => {
                //         const qtyReceipt = parseInt(item.qty_receipt, 10);
                //         const cogs = parseInt(item.cogs, 10);

                //         if (!isNaN(qtyReceipt) && !isNaN(cogs)) {
                //             return detailAcc + (qtyReceipt * cogs);
                //         }

                //         return detailAcc;
                //     }, 0);
                //     return acc + detailTotal;
                // }, 0);
                // setTotalCogs(totalCogs)
            }
        }).finally(setLoader(false))
    }

    const handleCloseModalCreateRG = () => {
        clearForm()
        setModalAddGoods(false)
    }

    const handleAdjustQty = (data) => {
        setSelectedRowDetail(data)
        setModalAdjust(true)
    }

    const handleSubmitChangeQty = () => {
        handleSetQty(selectedRowDetail, tempQty)
    }

    const handleExport = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD');
        const [startDate, endDate] = dateRange.map(date => formatDate(date));
        const date_range = [startDate, endDate];
        const param = {
            date: date_range,
            supplier : filterSupplier
        }

        await axios({
            method: "POST",
            url: `${host}/purchasing/index.php?action=exportGr`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param,
            responseType: 'blob'
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export_gr.xlsx'); 
            document.body.appendChild(link);
            link.click();
            // Bersihkan URL Blob setelah file diunduh
            window.URL.revokeObjectURL(url);
        })
    }

    const handlePreExport = () => {
        setModalExpFilter(true)
    }
    
    useEffect(() => {
        const detailTotal = selectedDataGr?.detail.reduce((detailAcc, item) => {
            const qtyReceipt = parseInt(item.qty_receipt, 10);
            const cogs = parseInt(item.cogs, 10);

            if (!isNaN(qtyReceipt) && !isNaN(cogs)) {
                return detailAcc + (qtyReceipt * cogs);
            }

            return detailAcc;
        }, 0);
        setTotalCogs(detailTotal)
    }, [selectedDataGr])

    useEffect(() => {
        getDataGoodReceipt()
    }, [dateRange])
    
    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Good Receipt</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={6} lg={6} md={6} sm={12} className="mb-5">
                        <Button type="button" content="Create Receipt of Goods" icon="add" onClick={handleClickAdd} color="blue"/>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} className="text-end mb-5">
                        <DateRangePicker
                            className="mb-2"
                            label="Date Range : "
                            format="yyyy-MM-dd"
                            placement="auto"
                            size="md"
                            cleanable={true}
                            defaultValue={dateRange}
                            value={dateRange}
                            onChange={handleChangeDate}
                            onClean={() => setDateRange([
                                new Date(moment().startOf('month')),
                                new Date(moment()),
                            ])}
                        />
                        <Button content="Export to Excel" icon="file excel" color="blue" onClick={handlePreExport} className="ms-3" />
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <Reacttable 
                            data={dataGr} 
                            columns={columnsDataGR} 
                            pageRow={8} 
                            totals={['header.total_ordered', 'header.total_receipt']} 
                            noData={dataGr.length === 0 ? true : false}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalAddGoods} onClose={() => handleCloseModalCreateRG()} backdrop="static" overflow={false} size="lg">
                <Modal.Header>
                    <Modal.Title>Create Receipt of Goods</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <Form id="header_form" onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Input
                                        size="small"
                                        width={4}
                                        action={{
                                            type: 'button',
                                            icon: 'zoom',
                                            content: 'Browse',
                                            color:'blue',
                                            onClick: () => handleClickBrowse()
                                        }}
                                        actionPosition='left'
                                        placeholder='PO Number'
                                        value={selectedPo?.header?.po_number || ''}
                                        readOnly
                                    />
                                    <Form.Input size="small" icon="warehouse" value={selectedPo ? selectedPo.header.warehouse_desc : ''} width={4} readOnly />
                                    <Form.Input size="small" icon="shipping" value={selectedPo ? selectedPo.header.vendor_name : ''} width={4} readOnly />
                                    <Form.Input size="small" icon={selectedPo ? selectedPo.header?.type === 'Pembelian' ? 'arrow alternate circle down outline' : 'arrow alternate circle up outline' : ''} value={selectedPo ? selectedPo.header?.type : ''} width={4} readOnly />
                                </Form.Group>
                                <Form.Group >
                                    <Form.Input size="small" type="text" label="DO Number" value={doNumber} onChange={(e) => setDoNumber(e.target.value)} required />
                                    <Form.Input size="small" type="date" label="Goods receipt date" value={doDate} onChange={(e) => setDoDate(e.target.value)} required />
                                    <Form.Input size="small" type="text" label="Reference" width={11} value={reference} onChange={(e) => setReference(e.target.value)} />
                                </Form.Group>
                            </Form>

                        </Col>
                        <Col lg={12}>
                            <Reacttable 
                                data={selectedPo ? selectedPo?.detail : []} 
                                columns={columnProdDet} 
                                pageRow={5} 
                                totals={['total_cogs', 'total_price', 'qty' ]}
                                noData={!selectedPo ? true : false}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <Button
                            content="Create"
                            icon="arrow alternate circle right outline"
                            form="header_form"
                            type="submit"
                            labelPosition="right"
                            color="blue"
                        />
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal open={modalPoList} onClose={() => setModalPoList(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>List of Created PO</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <Form onSubmit={handleSubmitSearch}>
                                <Form.Group>
                                    <Form.Input
                                        label="Search by Article"
                                        type="text"
                                        value={searchValue}
                                        size="mini"
                                        placeholder="Article"
                                        action={{
                                            type: 'button',
                                            icon: 'search',
                                            color:'blue',
                                            size: 'mini',
                                            type: 'submit'
                                        }}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    />

                                </Form.Group>
                            </Form>
                            <Reacttable data={dataPo.filter(i => i.header.po_status !== 'complete')} columns={columns} pageRow={5} noData={dataPo.filter(i => i.header.po_status !== 'complete').length === 0 ? true : false}/>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal open={modalGrDetail} onClose={() => setModalGrDetail(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Good Receipt Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={6} className="mb-3" style={{ fontSize: '0.9em' }} >
                            <div className="fw-bold mb-3">{selectedDataGr?.header.po_type}</div>
                            <Stack direction="horizontal">
                                <Stack direction="vertical">
                                    <div>PO Number</div>
                                    <div>DO Number</div>
                                    <div>Warehouse</div>
                                    <div>Grand Total</div>
                                </Stack>
                                <Stack direction="vertical">
                                    <div>: {selectedDataGr?.header.po_number}</div>
                                    <div>: {selectedDataGr?.header.do_number}</div>
                                    <div>: {selectedDataGr?.header.warehouse_desc}</div>
                                    <div>: {curency.format(totalCogs)}</div>
                                </Stack>
                            </Stack>
                        </Col>
                        <Col lg={12}>
                            <Reacttable 
                                data={selectedDataGr?.detail} 
                                columns={columnsGrItem} 
                                pageRow={6} 
                                totals={['cogs', 'price', 'qty_receipt']} 
                                noData={selectedDataGr?.detail.length === 0 ? true : false}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal open={modalAdjust} onClose={() => setModalAdjust(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Quantity Adjustment</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: '0.9em' }}>
                    <Row>
                        <Col lg={12}>
                            <Stack direction="horizontal" gap={0}>
                                <Stack direction="vertical">
                                    <div>Article</div>
                                    <div>Description</div>
                                    <div>Size</div>
                                </Stack>
                                <Stack direction="vertical" className="fw-bold">
                                    <div>: {selectedRowDetail?.article}</div>
                                    <div>: {selectedRowDetail?.description}</div>
                                    <div>: {selectedRowDetail?.size}</div>
                                </Stack>
                            </Stack>
                        </Col>
                        <Col lg={12}>
                            <Form id="formAdjust" onSubmit={handleSubmitChangeQty}>
                                <Form.Group grouped>
                                    <Form.Input label="Received Quantity" type="number" value={tempQty} onChange={(e) => setTempQty(e.target.value)} />
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" type="submit" form="formAdjust" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalDetailPo} onClose={() => setModalDetailPo(false)} overflow={false} children>
                <Modal.Header>
                    <Modal.Title>List Article On Selected PO</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable data={poDetailView?.detail} columns={columnsDetail} pageRow={5} />
                </Modal.Body>
            </Modal>

            <Modal open={modalExpFilter} onClose={() => setModalExpFilter(false)} overflow={false} size="xs">
                <Modal.Header>
                    <Modal.Title>Filter By</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmFilter">
                        <FormField 
                            label="Supplier"
                            control={Select}
                            options={supplierOpt}
                            selection
                            clearable
                            search
                            placeholder='All'
                            onChange={(e,{value}) => setFilterSupplier(value)}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit Export" color="blue" form="frmFilter" onClick={() => handleExport()}/>
                </Modal.Footer>
            </Modal>
        </>
    )
}
import axios from "axios";
import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { useMyGlobal } from "../component/Global"
import Modal from "rsuite/Modal"
import { Button, Form, Icon } from "semantic-ui-react";
import { Toaster } from "../component/Toaster";

export const Announc = () => {
    const { host, userProfile } = useMyGlobal()
    const [modalAnn, setModalAnn] = useState(false)
    const [text, setText] = useState('')
    const [annData, setAnnData] = useState([])
    const [modalEdit, setModalEdit] = useState(false)
    const [selItem, setSelItem] = useState(null)

    const handleAddAnn = () => {
        setModalAnn(true)
    }

    const handleClickEdit = (data) => {
        setSelItem(data)
        setText(data.text)
        setModalEdit(true)
    }

    const submitEdit = () => {
        const param = {
            id : selItem.id,
            text : text,
        }
        axios({
            method: "PUT",
            url: `${host}/log/index.php?action=editAnn`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then (res => {
            if (res.data.status === 200){
                Toaster.fire({
                    icon:'success',
                    text:"Success update text",
                    position:'top-right'
                })
                setModalEdit(false)
                setSelItem(null)
                setText('')
                getAnn()
            } else {
                Toaster.fire({
                    icon:'error',
                    text:res.data.msg,
                    position:'top-right'
                })
            }
        })
    }

    const createAnn = () => {
        const param = new FormData()
        param.append("text", text)
        param.append("id_user", userProfile.profile.id_user)
        // param.append("action", "createAnn")
        axios({
            method: "POST",
            url: `${host}/log/index.php?action=createAnn`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success adding new Announcement text',
                    position: 'top-right'
                })
                setModalAnn(false)
                getAnn()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }
    
    const getAnn = async () => {
        await axios({
            method: "GET",
            url: `${host}/log/index.php?action=announcement`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setAnnData(res.data.data)
            }
        })
    }

    const handleDelete = (id) => {
        const param = {
            id:id
        }
        const msg = window.confirm("Are you sure?")

        if (msg){
            axios({
                method: "DELETE",
                url: `${host}/log/index.php?action=deleteAnn`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data:param
            }).then (res => {
                if (res.data.status === 200){
                    Toaster.fire({
                        icon:'success',
                        text:'Success remove selected text',
                        position: "top-right",
                    })
                    getAnn()
                } else {
                    Toaster.fire({
                        icon:'error',
                        text:res.data.msg,
                        position: "top-right",
                    })
                }
            })
        }
    }

    useEffect(() => {
        getAnn()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-5 mt-2">
                    <div className="h4">Annoucement / Running Text</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={9} lg={12} md={12} className="mb-4">
                        <div className="fw-bold">List of created announcemet text</div>
                    </Col>
                    <Col xl={9} lg={12} md={12}>
                        <Row className="d-flex justify-content-center">
                            {
                                annData?.map((item, i) => (
                                    <Col xl={2} lg={3} md={4} sm={4} xs={12} className="mb-3">
                                        <div key={i} className="hover-component">
                                            <div style={{color:'coral'}}>{item.user_name} : </div><div className="fst-italic">"{item.text}</div>"
                                            <div className="hover-content text-center">
                                                <Icon name="trash alternate" className="me-3" style={{cursor:'pointer'}} onClick={() => handleDelete(item.id)}/>
                                                <Icon name="edit outline" style={{cursor:'pointer'}} onClick={() => handleClickEdit(item)}/>
                                            </div>
                                        </div>

                                    </Col>
                                ))
                            }
                            <Col xl={2} lg={3} md={4} sm={4} xs={12} className="mb-3">
                                <div style={{
                                    background: 'whitesmoke',
                                    borderRadius: '5px',
                                    minHeight: "250px",
                                    minWidth: "100%",
                                    padding: "10px",
                                    fontSize: '0.9em',
                                    alignContent: 'center',
                                    textAlign: 'center',
                                    border: '2px dashed lightgrey',
                                    cursor: 'pointer'
                                }}
                                    onClick={handleAddAnn}
                                >
                                    <Icon name="plus" size="big" className="text-muted"/>
                                    <div className="text-muted">Add new text</div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Modal open={modalAnn} onClose={() => setModalAnn(false)} overflow="false" size="xs" backdrop="static">
                <Modal.Header>
                    <Modal.Title>New Announcement Text</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmAnn" onSubmit={createAnn}>
                        <Form.TextArea label="Input Text Here" value={text} onChange={(e) => setText(e.target.value)} maxLength={125} required />
                        <small className="text-muted">Maximum 125 char</small>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" form="frmAnn" content="Save" icon="save" />
                </Modal.Footer>
            </Modal>
            
            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflo={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Text</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={submitEdit}>
                        <Form.TextArea label="Input Text Here" value={text} onChange={(e) => setText(e.target.value)} maxLength={125} required />
                        <small className="text-muted">Maximum 125 char</small>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" form="frmEdit" content="Save" icon="save" />
                </Modal.Footer>
            </Modal>
        </>
    )
}
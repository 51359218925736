import React, { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { useMyGlobal } from "../../component/Global";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Stack from "react-bootstrap/Stack"
import { Button, Checkbox, Dimmer, Form, FormCheckbox, FormField, FormGroup, FormInput, FormSelect, FormTextArea, Icon, Input, Loader, Popup, TextArea } from "semantic-ui-react";
import Modal from "rsuite/Modal";
import { capitalize, curency, curencyNoSym, currentDate } from "../../component/utils";
import { Toaster } from "../../component/Toaster";
import { Reacttable } from "../../component/Reacttable";
import moment from "moment";
import { DateRangePicker, InputGroup } from "rsuite";
import { useNavigate } from "react-router-dom";


export const SoInvoice = () => {
    const navigate = useNavigate()
    const [modalCreate, setModalCreate] = useState(false)
    const [modalInvDetail, setModalInvDetail] = useState(false)
    const { host, userProfile, cityOpt, provOpt, warehouseOpt } = useMyGlobal()
    const [invoiceData, setInvoiceData] = useState([])
    const [deliveryOrderData, setDeliveryOrderData] = useState([])
    const [doOption, setDoOption] = useState([])
    const [invType, setInvType] = useState('IAR')
    const [customer, setCustomer] = useState('')
    const [typeTax, setTypeTax] = useState('0100')
    const [usePpn, setUsePpn] = useState(true)
    const [useFakturPajak, setUseFakturPajak] = useState(false)
    const [invDate, setInvDate] = useState(currentDate)
    const [top, setTop] = useState(0)
    const [doNumber, setDoNumber] = useState('')
    const [noFakturPajak, setNoFakturPajak] = useState('')
    const [reference, setReference] = useState('')
    const [note, setNote] = useState('')
    const [totalGross, setTotalGross] = useState(0)
    const [discount1, setDiscount1] = useState(0)
    const [discount2, setDiscount2] = useState(0)
    const [discount3, setDiscount3] = useState(0)
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [grossProduct, setGrossProduct] = useState(0)
    const [discProduct, setDiscProduct] = useState(0)
    const ppn = 12
    const [customerOpt, setCustomerOpt] = useState([])
    const [selectedDoData, setSelectedDoData] = useState(null)
    const [selectedDoDetail, setSelectedDoDetail] = useState([])
    const [totalDpp, setTotalDpp] = useState(0)
    const [totalDppOth, setTotalDppOth] = useState(0)
    const [totalPpn, setTotalPpn] = useState(0)
    const [totalNetto, setTotalNetto] = useState(0)
    const [totalQty, setTotalQty] = useState(0)
    const [selectedInv, setSelectedInv] = useState(null)
    const [dscPerItem, setDscPerItem] = useState(0)
    const [customerData, setCustomerData] = useState([])
    const tableRef = useRef()
    const [checkInv, setCheckInv] = useState([])

    const invTypeOpt = [
        { key: '0', text: 'IAR - AR INVOICE', value: 'IAR' },
        { key: '1', text: 'RAR - AR INVOICE RETURN', value: 'RAR' },
    ]

    const taxTypeOpt = [
        { key: 0, text: '0100', value: '0100' },
        { key: 1, text: '0200', value: '0200' },
        { key: 2, text: '0300', value: '0300' },
        { key: 3, text: '0400', value: '0400' },
    ]

    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])

    const columnDoProduct = useMemo(() => [
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '150px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Barcode",
            accessor: "barcode"
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Dsc %",
            accessor: "dsc_percent",
            Cell: ({ cell }) => cell.value + ' %'
        },
        {
            Header: "Dsc Oth",
            accessor: "dsc_nominal",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Discount",
            accessor: "total_discount",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Qty",
            accessor: "qty_pickup"
        },
        {
            Header: "Total",
            accessor: "total",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
    ])

    const columnInvoice = useMemo(() => [
        {
            Header: "No Invoice",
            accessor: "header.no_invoice"
        },
        {
            Header: "Date",
            accessor: "header.inv_date"
        },
        {
            Header: "Due Date",
            accessor: "header.due_date"
        },
        {
            Header: "Do Number",
            accessor: "header.do_number"
        },
        {
            Header: "Type",
            accessor: "header.inv_type"
        },
        {
            Header: "Customer",
            accessor: "header.customer_name",
            Cell: ({ cell }) => (
                <div style={{ width: '100' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Gross",
            accessor: "header.total_gross",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Disc",
            accessor: "header.discount",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "DPP",
            accessor: "header.total_dpp",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "PPN",
            accessor: "header.total_ppn",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Nett",
            accessor: "header.total_nett",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Paid",
            accessor: "header.total_paid",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Outstanding",
            accessor: "header.outstanding",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup size="tiny" content="Product Detail" trigger={
                        <Button icon={{ name: 'list layout', color: 'blue' }} onClick={() => handleViewDetail(cell.row.original)} />
                    } />
                    <Popup size="tiny" content="Cancel Invoice" trigger={
                        <Button icon={{ name: 'ban', color: 'blue' }} onClick={() => handleCancelInv(cell.row.original.header)} />
                    } />
                    <Popup size="tiny" content="Print Invoice" trigger={
                        <Button icon={{ name: 'print', color: 'blue' }} onClick={() => handlePrintInv(cell.row.original)} />
                    } />
                    <Popup size="tiny" content="Export to Efaktur" trigger={
                        <Button icon={{ name: 'file excel', color: 'blue' }} onClick={() => handleExport(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const columnInvDetail = useMemo(() => [
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '150px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Barcode",
            accessor: "barcode"
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Qty",
            accessor: "qty_pickup"
        },
        {
            Header: "Dsc %",
            accessor: "dsc_percent",
            Cell: ({ cell }) => cell.value + " %"
        },
        {
            Header: "Dsc Oth",
            accessor: "dsc_nominal",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Total",
            accessor: "total",
            Cell: ({ cell }) => curencyNoSym.format(cell.value)
        },
    ])

    const handleExport = (row) => {

        let arrayData = null
        if (Array.isArray(row)){
            arrayData = row
        } else {
            arrayData = [{ header: row.header, detail: row.detail }];
        }
        
        axios({
            method: "POST",
            url: `${host}/directsales/index.php?action=exportToEfaktur`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: arrayData,
            responseType: 'blob'
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'efaktur.xlsx');
            document.body.appendChild(link)
            link.click()
            // Bersihkan URL Blob setelah file diunduh
            window.URL.revokeObjectURL(url);
        })
    }

    const handlePrintInv = (row) => { console.log(row)
        navigate('so_invoice_print', { state: row })
    }

    const handleViewDetail = (row) => { console.log(row)
        setSelectedInv(row)
        setModalInvDetail(true)
    }

    const handleCancelInv = (row) => {
        const cfm = window.confirm("Are you sure ?")

        if (cfm) {
            axios({
                method: "DELETE",
                url: `${host}/directsales/index.php?action=cancelInvoice`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: row
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                    invoice(dateRange)
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right'
                    })
                }
            })
        }
    }

    const handleClickAdd = () => {
        setModalCreate(true)
    }

    const handleChangeCustomer = (value) => {
        setCustomer(value)
        const doOption = deliveryOrderData.filter(item => item.header.id_ds_customer === value).map((item, i) => ({
            key: i,
            text: `${item.header.do_number} - ${item.header.customer_name}`,
            value: item.header.do_number,
        }))
        setDoOption(doOption)
        const customerTop = customerData.find(i => i.id_ds_customer === value).top
        setTop(customerTop)
    }

    const handleSelectDo = (value) => {
        if (value !== '') {
            setDoNumber(value)
            const filteredDo = deliveryOrderData.find(item => item.header.do_number === value)
            setSelectedDoData(filteredDo)
            setSelectedDoDetail(filteredDo.detail)
        } else {
            setDoNumber('')
            setSelectedDoData(null)
            setSelectedDoDetail([])
        }
    }

    const getCustomer = () => {
        axios({
            method: "GET",
            url: `${host}/directsales/index.php?action=customer`,
            headers:{
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setCustomerOpt(
                    res.data.data.map((item, i) => (
                        {
                            key: i,
                            text: item.customer_name,
                            value: item.id_ds_customer,
                        }
                    ))
                )
                setCustomerData(res.data.data)
            }
        })
    }

    const deliveryOrder = async () => {
        await axios({
            method: "GET",
            url: `${host}/directsales/index.php?action=deliveryOrder`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            if (res.data.status === 200) {
                const data = res.data.data
                const updateResData = data.map(data => {
                    const updatedDetail = data.detail.map(item => {
                        const price = parseInt(item.price) || 0;
                        const dsc_percent = parseInt(item.dsc_percent) || 0;
                        const dsc_nominal = parseInt(item.dsc_nominal) || 0;

                        const total_discount = (price * dsc_percent / 100) + dsc_nominal

                        return { ...item, total_discount };
                    })
                    return { ...data, detail: updatedDetail }
                })
                setDeliveryOrderData(updateResData)
            }
        })
    }

    const clearForm = () => {
        setInvType('')
        setCustomer('')
        setTypeTax('')
        setInvDate(currentDate)
        setTop(30)
        setDoNumber('')
        setReference('')
        setNoFakturPajak('')
        setUseFakturPajak('')
        setTotalGross(0)
        setDiscount1(0)
        setDiscount2(0)
        setDiscount3(0)
        setTotalDiscount(0)
        setTotalDpp(0)
        setTotalDppOth(0)
        setTotalPpn(0)
        setTotalNetto(0)
        setNote('')
    }

    const handleSubmit = () => {
        const totalQty = selectedDoDetail.reduce((total, item) => total + parseInt(item.qty_pickup), 0)
        const data = {
            header: {
                id_user: userProfile.profile.id_user,
                inv_type: invType,
                id_ds_customer: customer,
                tax_type: typeTax,
                inv_date: invDate,
                top: top,
                do_number: doNumber,
                reference: reference,
                no_faktur: noFakturPajak,
                use_ppn: usePpn,
                use_faktur: useFakturPajak,
                ppn: ppn,
                total_gross: totalGross,
                discount1: discount1,
                discount2: discount2,
                discount3: discount3,
                total_discount: totalDiscount + dscPerItem,
                total_dpp: totalDpp,
                total_dpp_other: totalDppOth,
                total_ppn: totalPpn,
                total_nett: totalNetto,
                note: note,
            },
            detail: selectedDoDetail
        }
      
        axios({
            method: "POST",
            url: `${host}/directsales/index.php?action=createInvoice`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: data
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: res.data.msg,
                    position: 'top-right'
                })
                clearForm()
                setModalCreate(false)
                invoice(dateRange)
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const invoice = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate];

        await axios({
            method: "GET",
            url: `${host}/directsales/index.php?action=invoice`,
            headers:{
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            params: {
                range: date_range
            }
        }).then(res => {
            if (res.data.status === 200) {
                setInvoiceData(res.data.data)
            }
        })
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const handleGetSelected = () => {
        if (tableRef.current) {
            const selected = tableRef.current.getSelectedRows()
            setCheckInv(selected)
            handleExport(selected)
        }
    }
    
    useEffect(() => {
        if (usePpn) {
            const totQty = selectedDoDetail.reduce((total, item) => total + parseInt(item.qty_pickup), 0)
            const totGro = selectedDoDetail.reduce((total, item) => total + parseFloat(item.total), 0)

            const totDscPerItem = selectedDoDetail.reduce((total, item) => total + parseInt(item.total_discount) * parseInt(item.qty_pickup), 0)

            const discountStep1 = totGro * (discount1 / 100)
            const priceAfterDiscount1 = totGro - discountStep1
            const discountStep2 = priceAfterDiscount1 * (discount2 / 100)
            const priceAfterDiscount2 = priceAfterDiscount1 - discountStep2
            const discountStep3 = priceAfterDiscount2 * (discount3 / 100)
            const newTotalDiscount = parseFloat(discountStep1 + discountStep2 + discountStep3)
            const totAfterDsc = parseFloat(totGro - newTotalDiscount)

            const totDpp = totAfterDsc / 1.11
            const totDppOth = totDpp * (11 / 12)
            const totPpn = totDppOth * (12 / 100)
            const totNet = parseFloat(totDpp + totPpn)

            setDscPerItem(Math.ceil(totDscPerItem))
            setTotalDiscount(Math.ceil(newTotalDiscount))
            setTotalNetto(Math.ceil(totNet))
            setTotalGross(Math.ceil(totGro))
            setTotalQty(totQty)
            setTotalDpp(Math.ceil(totDpp))
            setTotalDppOth(Math.ceil(totDppOth))
            setTotalPpn(Math.ceil(totPpn))
        }
    }, [selectedDoDetail, discount1, discount2, discount3, usePpn])

    useEffect(() => {
        getCustomer()
        deliveryOrder()
        invoice(dateRange)
    }, [dateRange])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Direct Sales Invoice</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={6} lg={6} md={6} className="mb-3">
                        <Button content="Create Invoice" icon="add" onClick={handleClickAdd} color="blue" />
                        <Button content="Export to e-Faktur" size="medium" className="ms-2 mb-3" icon="excel file" onClick={handleGetSelected} color="orange"/>
                    </Col>
                    <Col xl={6} lg={6} md={6} className="text-end">
                        <DateRangePicker
                            className="mb-2"
                            label="Date Range : "
                            format="yyyy-MM-dd"
                            placement="auto"
                            size="md"
                            cleanable={true}
                            defaultValue={dateRange}
                            value={dateRange}
                            onChange={handleChangeDate}
                            onClean={() => setDateRange([
                                new Date(moment().startOf('month')),
                                new Date(moment()),
                            ])}
                        />
                    </Col>
                    <Col xl={12} lg={12} md={12}>
                        <Reacttable
                            data={invoiceData}
                            columns={columnInvoice}
                            pageRow={10}
                            noData={invoiceData.length === 0}
                            checkbox
                            ref={tableRef}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalCreate} onClose={() => setModalCreate(false)} overflow={false} backdrop="static" size="lg">
                <Modal.Header>
                    <Modal.Title>Create Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <Form id="frmCreate" onSubmit={handleSubmit}>
                                <FormGroup>
                                    <FormSelect options={invTypeOpt} className="small" width={4} label="Invoice Type" value={invType} onChange={(e, { value }) => setInvType(value)} required />
                                    <FormSelect options={customerOpt} label="Customer" search width={6} className="small" value={customer} onChange={(e, { value }) => handleChangeCustomer(value)} required />
                                    <FormSelect options={taxTypeOpt} className="small" label="Tax Type" value={typeTax} onChange={(e, { value }) => setTypeTax(value)} required />
                                    <FormInput type="date" className="small" label="Invoice Date" value={invDate} onChange={(e, { value }) => setInvDate(value)} required />
                                    <FormInput type="text" className="small" label="TOP" width={1} max="999" min="1" value={top} onChange={(e, { value }) => setTop(value)} required readOnly />
                                </FormGroup>
                                <FormGroup>
                                    <FormSelect options={doOption} className="small" label="Do Number" search clearable value={doNumber} width={5} onChange={(e, { value }) => handleSelectDo(value)} required />
                                    <FormInput type="text" className="small" label="Reference" value={reference} onChange={(e, { value }) => setReference(value)} />
                                    <FormInput type="text" className="small" label="No Faktur Pajak" value={noFakturPajak} maxLength="20" onChange={(e, { value }) => setNoFakturPajak(value)} disabled={!useFakturPajak} required />
                                    <FormCheckbox label="PPN" style={{ marginTop: '30px', marginLeft: '10px' }} checked={usePpn} onChange={(e, data) => setUsePpn(data.checked)} readOnly />
                                    <FormCheckbox label="Faktur Pajak" style={{ marginTop: '30px' }} checked={useFakturPajak} onChange={(e, data) => setUseFakturPajak(data.checked)} />
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <Reacttable data={selectedDoDetail} columns={columnDoProduct} pageRow={10} noData={selectedDoDetail.length === 0} totals={['qty_pickup']}/>
                        </Col>
                        <Col lg={4} md={4}>
                            <Form>
                                <FormField>
                                    <label>Note</label>
                                    <TextArea maxLength="100" value={note} onChange={(e, { value }) => setNote(value)} />
                                </FormField>
                            </Form>
                        </Col>
                        <Col lg={4} md={4} className="text-end">
                            <div className="mb-2">
                                <label style={{ fontSize: '0.9em', marginRight: '10px' }}>Total Gross</label>
                                <Input size="small" style={{ width: '16em' }} value={curencyNoSym.format(totalGross)} readOnly />
                            </div>
                            <div className="mb-2">
                                <label style={{ fontSize: '0.9em', marginRight: '10px' }}>Discount</label>
                                <Input className="me-2" size="small" style={{ width: '5em' }} value={discount1} onChange={(e, { value }) => setDiscount1(value)} label={{ icon: 'percent' }} labelPosition='right corner' />
                                <Input className="me-2" size="small" style={{ width: '5em' }} value={discount2} onChange={(e, { value }) => setDiscount2(value)} label={{ icon: 'percent' }} labelPosition='right corner' />
                                <Input size="small" style={{ width: '5em' }} value={discount3} onChange={(e, { value }) => setDiscount3(value)} label={{ icon: 'percent' }} labelPosition='right corner' />
                            </div>
                            <div className="mb-2">
                                <Input size="small" style={{ width: '16em' }} value={curencyNoSym.format(totalDiscount)} readOnly />
                            </div>
                        </Col>
                        <Col lg={4} md={4} className="text-end">
                            <div className="mb-2">
                                <label style={{ fontSize: '0.9em', marginRight: '10px' }} >DPP</label>
                                <Input size="small" value={curencyNoSym.format(totalDpp)} readOnly />
                            </div>
                            <div className="mb-2">
                                <label style={{ fontSize: '0.9em', marginRight: '10px' }} >DPP Other</label>
                                <Input size="small" value={curencyNoSym.format(totalDppOth)} readOnly />
                            </div>
                            <div className="mb-2">
                                <label style={{ fontSize: '0.9em', marginRight: '10px' }} >PPN</label>
                                <Input size="small" label="12%" value={curencyNoSym.format(totalPpn)} readOnly />
                            </div>
                            <div className="mb-2">
                                <label style={{ fontSize: '0.9em', marginRight: '10px' }} >Total Netto</label>
                                <Input size="small" value={curencyNoSym.format(totalNetto)} readOnly />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Create Invoice" color="blue" type="submit" form="frmCreate" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalInvDetail} onClose={() => setModalInvDetail(false)} overflow={false} size="lg">
                <Modal.Header>
                    <Modal.Title>Invoice Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-2 fw-bold">No Invoice : {selectedInv?.header.no_invoice}</div>
                    <Reacttable data={selectedInv?.detail} columns={columnInvDetail} pageRow={10} noData={selectedInv?.detail.length === 0} totals={['qty_pickup','total']} />
                </Modal.Body>
            </Modal>
        </>
    )
}
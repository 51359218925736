import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import axios from "axios";
import { Button, Form, Icon, Popup } from "semantic-ui-react";
import { useMyGlobal } from "../component/Global";
import { Reacttable } from "../component/Reacttable";
import Modal from "rsuite/Modal";
import Stack from "react-bootstrap/Stack";
import { capitalize } from "../component/utils";
import { Toaster } from "../component/Toaster";
import Toggle from "rsuite/Toggle";
import { useTable } from "react-table";
import Badge from "rsuite/Badge";

export const User = () => {
    const [dataUser, setDataUser] = useState([])
    const [dataRole, setDataRole] = useState([])
    const [roleOpt, setRoleOpt] = useState([])
    const [modalRole, setModalRole] = useState(false)
    const [modalAddUser, setModalAddUser] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const [modalChangePwd, setModalChangePwd] = useState(false)
    const { host, userProfile, showroomOpt } = useMyGlobal()
    const [selectedRow, setSelectedRow] = useState(null)
    const [role, setRole] = useState('')
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [pwd, setPwd] = useState('')
    const [conPwd, setConPwd] = useState('')
    const [selectedUser, setSelectedUser] = useState(null)
    const [visible, setVisible] = useState(false)
    const [loadAdd, setLoadAdd] = useState(false)
    const [showroom, setShowroom] = useState([])
    const [dataShowroom, setDataShowroom] = useState([])
    const [selShowroom, setSelShowroom] = useState([])


    const columnsHeader = useMemo(() => [
        {
            Header: 'User',
            accessor: "header.user_name",
        },
        {
            Header: 'Email',
            accessor: "header.email",
        },
        {
            Header: 'Role',
            accessor: "header.role_name",
        },
        {
            Header: 'Active',
            accessor: "header.is_active",
            Cell: ({ cell }) => (
                <Toggle size="sm" defaultChecked={cell.value === 'Y' ? true : false} onChange={() => handleChangeStatus(cell.value, cell.row.original.header.id_user)} />
            )
        },
        {
            Header: 'Registration',
            accessor: "header.reg_date",
        },
        {
            Header: 'Last Login',
            accessor: "header.lastUpdate",
        },
        {
            Header: 'Status',
            accessor: "header.status_log",
            Cell:({cell}) => (
                <div>{cell.value === 'online' ? <span><Badge color="green" className="me-2"/>Online</span> : cell.value === 'offline' ? <span><Badge color="yellow" className="me-2"/>Offline</span> : <></>}</div>
            )
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group size="mini" basic>
                    <Popup content="Group Role" size="tiny" trigger={
                        <Button icon="list layout" onClick={() => handleClickRow(cell.row.original)} />
                    } />
                    <Popup content="Edit User" size="tiny" trigger={
                        <Button icon="edit" onClick={() => handleClickEditUser(cell.row.original)} />
                    } />
                    <Popup content="Change Password" size="tiny" trigger={
                        <Button icon="key" onClick={() => handleClickChangePwd(cell.row.original)} />
                    } />
                </Button.Group>
            )
        }
    ])

    const handleClickChangePwd = (data) => {
        setSelectedUser(data)
        setModalChangePwd(true)
    }

    const handleClickEditUser = (data) => {
        setName(data.header.user_name)
        setEmail(data.header.email)
        setRole(data.header.id_role)
        setSelectedUser(data)
        setModalEdit(true)
    }

    const handleChangeStatus = (value, idUser) => {
        const status = value === 'Y' ? 'N' : 'Y'
        axios({
            method: "PUT",
            url: `${host}/user/index.php?action=setStatusUser`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: { id_user: idUser, status: status },
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success change status',
                    position: 'top-right'
                })
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const columnGroup = useMemo(() => [
        {
            Header: 'Showroom',
            accessor: "showroom_name",
        },
        {
            Header: 'Warehouse',
            accessor: "warehouse_name",
        },
        {
            Header: 'Category',
            accessor: "category",
        },
        {
            Header: 'Supervisor',
            accessor: "supervisor",
        },
        {
            Header: 'Manager',
            accessor: "manager",
        },
    ])

    const handleVis = () => (setVisible(!visible))

    const handleClickRow = (data) => {
        setSelectedRow(data)
        setModalRole(true)
    }

    const getUserData = async () => {
        axios({
            method: "GET",
            url: `${host}/user/index.php?action=user`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => {
            if (res.data.status === 200) {
                setDataUser(res.data.data)
            }
        })
    }

    const clearForm = () => {
        setRole('')
        setEmail('')
        setName('')
        setPwd('')
        setShowroom([])
    }

    const getRoleData = async () => {
        axios({
            method: "GET",
            url: `${host}/user/index.php?action=role`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
        }).then(res => { 
            if (res.data.status === 200) {
                setDataRole(res.data.data)
                setRoleOpt(res.data.data.map(item => {
                    return {
                        key: item.header.id_role,
                        text: capitalize(item.header.role_name),
                        value: item.header.id_role,
                    }
                }))
            }
        })
    }

    const handleSelectRole = (value) => {
        setRole(value)
    }

    const handleClickAddUser = () => {
        setModalAddUser(true)
    }

    const handleSubmitCreate = () => {
        setLoadAdd(true)
        const param = new FormData()

        param.append('role', role)
        param.append('email', email)
        param.append('name', name)
        param.append('pwd', pwd)
        param.append('isCashier', dataRole.find(r => r.header.id_role === role).header.isCashier)
        param.append('showroom', JSON.stringify(selShowroom))
        
        axios({
            method: "POST",
            url: `${host}/user/index.php?action=createUser`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success create user',
                    position: 'top-right'
                })
                getUserData()
                setModalAddUser(false)
                clearForm()
            } else if (res.data.status === 500) {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            } else if (res.data.status === 400) {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
            setLoadAdd(false)
        })
    }

    const handleSelectShowroom = (value) => { 
        const completeSr = value.map(val => dataShowroom.find(sr => val === sr.id_showroom))
        setShowroom(value)
        setSelShowroom(completeSr)
    }

    const handleSubmitEdit = () => {
        axios({
            method: "PUT",
            url: `${host}/user/index.php?action=editUser`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: { 
                id_user:selectedUser?.header.id_user,
                id_role:role,
                name:name,
                email:email,
            },
        }).then (res => {
            if (res.data.status === 200){
                Toaster.fire({
                    icon:'success',
                    text:'Success edit user',
                    position:'top-right'
                })
                getUserData()
            } else {
                Toaster.fire({
                    icon:'error',
                    text:res.data.msg,
                    position:'top-right'
                })
            }
        })
    }

    const handleSubmitChangePwd = () => {
        if (pwd === conPwd){
            if (conPwd !== ''){
                axios({
                    method: "PUT",
                    url: `${host}/user/index.php?action=changePwd`,
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                    },
                    data: { 
                        id_user:selectedUser?.header.id_user,
                        pwd:conPwd
                    },
                }).then(res => {
                    if (res.data.status === 200){
                        Toaster.fire({
                            icon:'success',
                            text:'Success change password',
                            position:'top-right'
                        })
                        setPwd('')
                        setConPwd('')
                        setModalChangePwd(false)
                    }
                })
            }
        } else {
            Toaster.fire({
                icon:'error',
                text:'Password not match',
                position:'top-right'
            })
        }
    }

    const getDataShowroom = async () => {
        await axios({
            method: "GET",
            url: `${host}/showroom/index.php?action=showroom`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataShowroom(res.data.data)
            }
        })
    }

    useEffect(() => {
        getUserData()
        getRoleData()
        getDataShowroom()
    }, [])
   
    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">User</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={10} lg={10} className="mb-3">
                        <Button content="Add New User" icon="add" onClick={handleClickAddUser} color="blue"/>
                    </Col>
                    <Col xl={10} lg={10} md={12} sm={12}>
                        <Reacttable data={dataUser} columns={columnsHeader} pageRow={8} noData={dataUser.length === 0 ? true : false}/>
                    </Col>
                </Row>
            </Container>

            <Modal open={modalRole} onClose={() => setModalRole(false)} overflow={false}>
                <Modal.Header>
                    <Modal.Title>User Group Role</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Row>
                        <Col lg={6} className="mb-3" style={{ fontSize: '0.9em' }}>
                            <Stack direction="horizontal">
                                <Stack direction="vertical">
                                    <div>User</div>
                                    <div>Email</div>
                                    <div>Role</div>
                                </Stack>
                                <Stack direction="vertical" className="fw-bold">
                                    <div>: {selectedRow?.header.user_name}</div>
                                    <div>: {selectedRow?.header.email}</div>
                                    <div>: {selectedRow?.header.role_name}</div>
                                </Stack>
                            </Stack>
                        </Col>
                        <Col lg={12}>
                            <Reacttable data={selectedRow?.detail} columns={columnGroup} pageRow={10} />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal open={modalAddUser} onClose={() => setModalAddUser(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Add New User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmAddUser" onSubmit={handleSubmitCreate}>
                        <Form.Group grouped>
                            <Form.Dropdown
                                options={roleOpt}
                                selection
                                value={role}
                                label="Role"
                                search
                                onChange={(e, { value }) => handleSelectRole(value)}
                            />
                            {/* <Form.Dropdown
                                options={showroomOpt}
                                selection
                                multiple
                                value={showroom}
                                label="Showroom"
                                onChange={(e, { value }) => handleSelectShowroom(value)}
                            /> */}
                            <Form.Input type="email" value={email} label="Email" onChange={(e) => setEmail(e.target.value)} required />
                            <Form.Input type="text" value={name} label="Name" onChange={(e) => setName(e.target.value)} required />
                            <Form.Input icon={<Icon name={visible ? 'eye slash' : 'eye'} link onClick={handleVis} />} onChange={(e) => setPwd(e.target.value)} label="Password" type={visible ? 'text' : 'password'} required />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" icon="save" form="frmAddUser" disabled={loadAdd} loading={loadAdd} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                        <Form.Group grouped>
                            <Form.Dropdown
                                options={roleOpt}
                                selection
                                value={role}
                                label="Role"
                                onChange={(e, { value }) => handleSelectRole(value)}
                            />
                            <Form.Input type="email" value={email} label="Email" onChange={(e) => setEmail(e.target.value)} required />
                            <Form.Input type="text" value={name} label="Name" onChange={(e) => setName(e.target.value)} required />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Save Change" icon="save" form="frmEdit"/>
                </Modal.Footer>
            </Modal>

            <Modal open={modalChangePwd} onClose={()=>setModalChangePwd(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <div>User : {selectedUser?.header.user_name}</div>
                        <div>Email : {selectedUser?.header.email}</div>
                    </div>
                    <Form id="frmChangePwd" onSubmit={handleSubmitChangePwd}>
                        <Form.Input icon={<Icon name={visible ? 'eye slash' : 'eye'} link onClick={handleVis} />} onChange={(e) => setPwd(e.target.value)} label="Password" type={visible ? 'text' : 'password'} required />
                        <Form.Input icon={<Icon name={visible ? 'eye slash' : 'eye'} link onClick={handleVis} />} onChange={(e) => setConPwd(e.target.value)} label="Confirm Password" type={visible ? 'text' : 'password'} required />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Save Change" icon="save" form="frmChangePwd"/>
                </Modal.Footer>
            </Modal>
        </>
    )
}
import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useMyGlobal } from "../../component/Global";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Stack from "react-bootstrap/Stack"
import { Button, Checkbox, Form, FormCheckbox, FormGroup, FormInput, FormRadio, FormSelect, Icon, Label, Popup } from "semantic-ui-react";
import Modal from "rsuite/Modal";
import { capitalize } from "../../component/utils";
import { Toaster } from "../../component/Toaster";
import { Reacttable } from "../../component/Reacttable";

export const MasterCustomer = () => {
    const [modalCreate, setModalCreate] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)
    const { host, userProfile, cityOpt, provOpt } = useMyGlobal();
    const [salesmanOpt, setSalesmanOpt] = useState([])
    const [dataCust, setDataCust] = useState([])
    const [custName, setCustName] = useState('')
    const [sales, setSales] = useState('')
    const [custType, setCustType] = useState('')
    const [custStatus, setCustStatus] = useState(true)
    const [usePkp, setUsePkp] = useState(false)
    const [custAddress, setCustAddress] = useState('')
    const [pic, setPic] = useState('')
    const [city, setCity] = useState('')
    const [prov, setProv] = useState('')
    const [post, setPost] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [custBillAddress, setCustBillAddress] = useState('')
    const [billPic, setBillPic] = useState('')
    const [billCity, setBillCity] = useState('')
    const [billProv, setBillProv] = useState('')
    const [billPost, setBillPost] = useState('')
    const [billPhone, setBillPhone] = useState('')
    const [billEmail, setBillEmail] = useState('')
    const [npwp, setNpwp] = useState('0')
    const [sameAddress, setSameAddress] = useState(false)
    const [idDsCustomer, setIdDsCustomer] = useState('')
    const [customerTypeOpt, setCustomerTypeOpt] = useState([])
    const [top, setTop] = useState(0)

    const columnsCustomer = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_ds_customer"
        },
        {
            Header: "Customer",
            accessor: "customer_name"
        },
        {
            Header: "Type",
            accessor: "customer_type"
        },
        {
            Header: "Active",
            accessor: "status",
            Cell: ({ cell }) => (<div>{cell.value}</div>)
        },
        {
            Header: "Address",
            accessor: "address",
            Cell: ({ cell }) => (
                <div style={{ width: '180px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Bill Address",
            accessor: "bill_address",
            Cell: ({ cell }) => (
                <div style={{ width: '180px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Phone",
            accessor: "phone"
        },
        {
            Header: "Email",
            accessor: "customer_email"
        },
        {
            Header: "User",
            accessor: "user_name",
            Cell: ({ cell }) => (
                <Stack direction="vertical">
                    <div>{cell.value}</div>
                    <div className="text-muted" style={{ lineHeight: '0.8em' }}>{cell.row.original.role_name}</div>
                </Stack>
            )
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup size="tiny" content="Edit" trigger={
                        <Button icon={{ name: 'edit', color: 'blue' }} onClick={() => handleClickEdit(cell.row.original)} />
                    } />
                    <Popup size="tiny" content="Delete" trigger={
                        <Button icon={{ name: 'trash alternate', color: 'blue' }} onClick={() => handleClickDelete(cell.row.original.id_ds_customer)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const handleGetSplitedString = (input, seg) => {
        const segments = input.split(', ')
        return segments[seg]
    }

    const handleClickEdit = (row) => {
        setIdDsCustomer(row.id_ds_customer)
        setCustName(row.customer_name)
        setCustStatus(row.status === 'Y' ? true : false)
        setUsePkp(row.use_pkp === 'Y' ? true : false)
        setCustType(row.customer_type)
        setNpwp(row.npwp)
        setTop(row.top)
        setSales(row.id_salesman)
        setSameAddress(false)
        setModalEdit(true)

        setPic(row.pic)
        setCity(cityOpt.find(i => i.text === handleGetSplitedString(row.address, 1)).value)
        setCustAddress(handleGetSplitedString(row.address, 0))
        setProv(handleGetSplitedString(row.address, 2))
        setPost(handleGetSplitedString(row.address, 3))
        setPhone(row.phone)
        setEmail(row.email)

        setBillPic(row.bill_pic)
        setBillCity(cityOpt.find(i => i.text === handleGetSplitedString(row.bill_address, 1)).value)
        setCustBillAddress(handleGetSplitedString(row.bill_address, 0))
        setBillProv(handleGetSplitedString(row.bill_address, 2))
        setBillPost(handleGetSplitedString(row.bill_address, 3))
        setBillPhone(row.bill_phone)
        setBillEmail(row.bill_email)
    }

    const handleClickDelete = async (id) => {
        let cfm = window.confirm("Are you sure ?")
        if (cfm) {
            await axios({
                method: "DELETE",
                url: `${host}/directsales/index.php?action=deleteCustomer`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
                },
                data: {
                    id_ds_customer: id
                }
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: "success",
                        text: res.data.msg,
                        position: "top-right",
                    })
                    customer()
                } else {
                    Toaster.fire({
                        icon: "error",
                        text: res.data.msg,
                        position: "top-right",
                    })
                }
            })
        }
    }

    const salesman = async () => {
        await axios({
            method: "GET",
            url: `${host}/directsales/index.php?action=salesman`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesmanOpt(res.data.data.map(i => {
                    return { key: i.id_salesman, text: capitalize(i.sales_name), value: i.id_salesman }
                }))
            }
        })
    }

    const handleClickAdd = () => {
        setModalCreate(true)
        clearForm()
    }

    const customer = () => {
        axios({
            method: "GET",
            url: `${host}/directsales/index.php?action=customer`,
            headers:{
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataCust(res.data.data)
            }
        })
    }

    const handleEditCustomer = () => {
        let cityText = cityOpt.find(item => item.value === city).text
        let billCityText = cityOpt.find(item => item.value === billCity).text

        let data = {
            id_ds_customer: idDsCustomer,
            customer_name: custName,
            id_user: userProfile.profile.id_user,
            sales: sales,
            type: custType,
            npwp: npwp,
            top: top,
            status: custStatus ? 'Y' : 'N',
            use_pkp: usePkp ? 'Y' : 'N',
            pic: pic,
            address: custAddress + ', ' + cityText + ', ' + prov + ', ' + post,
            phone: phone,
            email: email,

            bill_pic: billPic,
            bill_address: custBillAddress + ', ' + billCityText + ', ' + billProv + ', ' + billPost,
            bill_phone: billPhone,
            bill_email: billEmail,
        }

        axios({
            method: "PUT",
            url: `${host}/directsales/index.php?action=editCustomer`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: data
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success edit customer',
                    position: 'top-right',
                })
                clearForm()
                setModalEdit(false)
                customer()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right',
                })
            }
        })
    }

    const handleSubmit = () => {
        let param = new FormData()
        let cityText = cityOpt.find(item => item.value === city).text
        let billCityText = cityOpt.find(item => item.value === billCity).text

        param.append("customer_name", custName)
        param.append("id_user", userProfile.profile.id_user)
        param.append("sales", sales)
        param.append("type", custType)
        param.append("npwp", npwp)
        param.append("top", top)
        param.append("status", custStatus ? 'Y' : 'N')
        param.append("use_pkp", usePkp ? 'Y' : 'N',)
        param.append("pic", pic)
        param.append("address", custAddress + ', ' + cityText + ', ' + prov + ', ' + post)
        param.append("phone", phone)
        param.append("email", email)

        param.append("bill_pic", pic)
        param.append("bill_address", custAddress + ', ' + billCityText + ', ' + prov + ', ' + post)
        param.append("bill_phone", phone)
        param.append("bill_email", email)

        axios({
            method: "POST",
            url: `${host}/directsales/index.php?action=createCustomer`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            },
            data: param
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success create new customer',
                    position: 'top-right',
                })
                clearForm()
                setModalCreate(false)
                customer()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right',
                })
            }
        })
    }

    const customerType = async () => {
        await axios({
            method: "GET",
            url: `${host}/directsales/index.php?action=customerType`,
            headers:{
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107"
            }
        }).then(res => {
            if (res.data.status === 200) {
                setCustomerTypeOpt(res.data.data.map((item, i) => (
                    {
                        key: item.id_customer_type,
                        text: item.code_customer_type,
                        value: item.code_customer_type,
                    }
                )))
            }
        })
    }

    const handleChangeCity = (value) => {
        let text = cityOpt.find(item => item.value === value).text
        setCity(value)
        setProv(provOpt?.find(item => item.value === value).text)
    }

    const handleChangeCityBill = (value) => {
        let text = cityOpt.find(item => item.value === value).text
        setBillCity(value)
        setBillProv(provOpt?.find(item => item.value === value).text)
    }
    
    const handleChangeNpwp = (e, {value}) => {
        if (!value.startsWith('0')) {
            value = '0' + value;
        }
        if (value.length > 21) {
            value = value.slice(0,21); // NPWP hanya terdiri dari 15 digit angka tanpa format
        }
        // Hapus semua karakter yang bukan angka
        value = value.replace(/[^\d]/g, '');
   
        // Format NPWP
        if (value.length <= 3) { 
            value = value;
        } else if (value.length <= 6) { 
            value = `${value.slice(0, 3)}.${value.slice(3)}`;
        } else if (value.length <= 9) { 
            value = `${value.slice(0, 3)}.${value.slice(3, 6)}.${value.slice(6)}`;
        } else if (value.length <= 13) { 
            value = `${value.slice(0, 3)}.${value.slice(3, 6)}.${value.slice(6, 9)}.${value.slice(9,10)}-${value.slice(10)}`;
        } else if (value.length <= 16) { 
            value = `${value.slice(0, 3)}.${value.slice(3, 6)}.${value.slice(6, 9)}.${value.slice(9,10)}-${value.slice(10,13)}.${value.slice(13)}`;
        } else { 
            value = `${value.slice(0, 3)}.${value.slice(3, 6)}.${value.slice(6, 9)}.${value.slice(9,10)}-${value.slice(10,13)}.${value.slice(13,16)}`;
        } 

        if (value.length === 21) { 
            value = value += '000000'; 
        }
      
        setNpwp(value);
    };        

    const clearForm = () => {
        setCustName('')
        setCustStatus(true)
        setCustType('')
        setNpwp('')
        setSales('')
        setSameAddress(false)

        setPic('')
        setCustAddress('')
        setCity('')
        setProv('')
        setPost('')
        setPhone('')
        setEmail('')

        setBillPic('')
        setCustBillAddress('')
        setBillCity('')
        setBillProv('')
        setBillPost('')
        setBillPhone('')
        setBillEmail('')
    }

                        
    const handleClickSameAddress = (checked) => {
        setSameAddress(checked)
        if (checked) {
            setBillPic(pic)
            setCustBillAddress(custAddress)
            setBillCity(city)
            setBillProv(prov)
            setBillPost(post)
            setBillPhone(phone)
            setBillEmail(email)
        } else {
            setBillPic('')
            setCustBillAddress('')
            setBillCity('')
            setBillProv('')
            setBillPost('')
            setBillPhone('')
            setBillEmail('')
        }
    }

    useEffect(() => {
        customerType()
        salesman()
        customer()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Master Customer</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={12} lg={12} md={12} className="mb-3">
                        <Button content="Add New Customer" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={12} lg={12} md={12}>
                        <Reacttable data={dataCust} columns={columnsCustomer} pageRow={10} />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalCreate} onClose={() => setModalCreate(false)} overflow={false} backdrop="static" size="lg">
                <Modal.Header>
                    <Modal.Title>Create New Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="">
                        <Col>
                            <Form id="frmCreate" onSubmit={handleSubmit}>
                                <Form.Group>
                                    <FormInput className="small" label="Customer Name" maxLength="35" width={6} placeholder="Toko Berkah Jaya" value={custName} onChange={(e, { value }) => setCustName(value)} required />
                                    <FormSelect className="small" label="Sales" options={salesmanOpt} width={5} search value={sales} onChange={(e, { value }) => setSales(value)} required />
                                    <FormSelect className="small" label="Customer Type" options={customerTypeOpt} width="3" value={custType} onChange={(e, { value }) => setCustType(value)} required />
                                    <FormInput className="small" label="NPWP" width="5" maxLength="27" value={npwp} onChange={handleChangeNpwp} />
                                    <FormInput className="small" type="number" label="TOP" width="1" value={top} onChange={(e, { value }) => setTop(value)} max="200" min="0" required />
                                </Form.Group>
                                <Form.Group inline>
                                    <label>Status</label>
                                    <FormCheckbox label="Active" checked={custStatus} onChange={(e, data) => setCustStatus(data.checked)} />
                                    <FormCheckbox label="PKP" checked={usePkp} onChange={(e, data) => setUsePkp(data.checked)} />
                                </Form.Group>
                                <Row>
                                    <Col lg={6} md={6} sm={12} className="border ps-5 pe-5 pt-4 pb-3">
                                        <span><Icon name="point" color="orange" /></span>
                                        <span className="fw-bold">Delivery Address</span>
                                        <Form.Group grouped className="small">
                                            <FormInput label="PIC" maxLength="35" value={pic} onChange={(e, { value }) => setPic(value)} required />
                                            <FormInput label="Address" maxLength="50" value={custAddress} onChange={(e, { value }) => setCustAddress(value)} placeholder="Jl. Surapati No.61, Sadang Serang, Coblong" required />
                                            <FormSelect label="City" options={cityOpt} value={city} onChange={(e, { value }) => handleChangeCity(value)} search required />
                                            <FormInput label="Province" value={prov} readOnly required />
                                            <FormInput label="Postal Code" value={post} onChange={(e, { value }) => setPost(value)} required />
                                            <FormInput label="Phone" type="phone" maxLength="15" value={phone} onChange={(e, { value }) => setPhone(value)} />
                                            <FormInput label="Email" type="email" maxLength="35" value={email} onChange={(e, { value }) => setEmail(value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} className="border ps-5 pe-5 pt-4 pb-3">
                                        <span><Icon name="point" color="blue" /></span>
                                        <span className="fw-bold">Bill Address</span>
                                        <Checkbox className="float-end" label="Same as address" checked={sameAddress} onChange={(e, data) => handleClickSameAddress(data.checked)} />
                                        <Form.Group grouped className="small">
                                            <FormInput label="PIC" maxLength="35" value={billPic} onChange={(e) => setBillPic(e.value)} required />
                                            <FormInput label="Address" maxLength="50" value={custBillAddress} onChange={(e) => setCustBillAddress(e.value)} placeholder="Jl. Surapati No.61, Sadang Serang, Coblong" required />
                                            <FormSelect label="City" options={cityOpt} value={billCity} onChange={(e, { value }) => handleChangeCityBill(value)} search required />
                                            <FormInput label="Province" value={billProv} onChange={(e, { value }) => setBillProv(value)} readOnly required />
                                            <FormInput label="Postal Code" value={billPost} onChange={(e, { value }) => setPost(value)} required />
                                            <FormInput label="Phone" type="phone" maxLength="15" value={billPhone} onChange={(e, { value }) => setBillPhone(value)} />
                                            <FormInput label="Email" type="email" maxLength="35" value={billEmail} onChange={(e, { value }) => setBillEmail(value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" form="frmCreate" content="Submit" color="blue" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="lg">
                <Modal.Header>
                    <Modal.Title>Create New Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="">
                        <Col>
                            <Form id="frmEdit" onSubmit={handleEditCustomer}>
                                <Form.Group className="small">
                                    <FormInput label="Customer Name" maxLength="35" width={6} placeholder="Toko Berkah Jaya" value={custName} onChange={(e, { value }) => setCustName(value)} required />
                                    <FormSelect label="Sales" options={salesmanOpt} search width={5} value={sales} onChange={(e, { value }) => setSales(value)} required />
                                    <FormSelect label="Customer Type" options={customerTypeOpt} value={custType} onChange={(e, { value }) => setCustType(value)} required />
                                    <FormInput label="NPWP" value={npwp} maxLength="27" width={5} onChange={handleChangeNpwp} />
                                    <FormInput type="number" label="TOP" value={top} width={1} onChange={(e, { value }) => setTop(value)} max="200" min="0" required />
                                </Form.Group>
                                <Form.Group inline>
                                    <label>Status</label>
                                    <FormCheckbox label="Active" checked={custStatus} onChange={(e, data) => setCustStatus(data.checked)} />
                                    <FormCheckbox label="PKP" value="pkp" checked={usePkp} onChange={(e, data) => setCustStatus(data.checked)} />
                                </Form.Group>
                                <Row>
                                    <Col lg={6} md={6} sm={12} className="border ps-5 pe-5 pt-4 pb-3">
                                        <span><Icon name="point" color="orange" /></span>
                                        <span className="fw-bold">Delivery Address</span>
                                        <Form.Group grouped className="small">
                                            <FormInput label="PIC" maxLength="35" value={pic} onChange={(e, { value }) => setPic(value)} required />
                                            <FormInput label="Address" maxLength="50" value={custAddress} onChange={(e, { value }) => setCustAddress(value)} placeholder="Jl. Surapati No.61, Sadang Serang, Coblong" required />
                                            <FormSelect label="City" options={cityOpt} value={city} onChange={(e, { value }) => handleChangeCity(value)} search required />
                                            <FormInput label="Province" value={prov} readOnly required />
                                            <FormInput label="Postal Code" value={post} onChange={(e, { value }) => setPost(value)} required />
                                            <FormInput label="Phone" type="phone" maxLength="15" value={phone} onChange={(e, { value }) => setPhone(value)} />
                                            <FormInput label="Email" type="email" maxLength="35" value={email} onChange={(e, { value }) => setEmail(value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} className="border ps-5 pe-5 pt-4 pb-3">
                                        <span><Icon name="point" color="blue" /></span>
                                        <span className="fw-bold">Bill Address</span>
                                        <Checkbox className="float-end" label="Same as address" checked={sameAddress} onChange={(e, data) => handleClickSameAddress(data.checked)} />
                                        <Form.Group grouped className="small">
                                            <FormInput label="PIC" maxLength="35" value={billPic} onChange={(e) => setBillPic(e.value)} required />
                                            <FormInput label="Address" maxLength="50" value={custBillAddress} onChange={(e) => setCustBillAddress(e.value)} placeholder="Jl. Surapati No.61, Sadang Serang, Coblong" required />
                                            <FormSelect label="City" options={cityOpt} value={billCity} onChange={(e, { value }) => handleChangeCityBill(value)} search required />
                                            <FormInput label="Province" value={billProv} readOnly required />
                                            <FormInput label="Postal Code" value={billPost} required />
                                            <FormInput label="Phone" type="phone" maxLength="15" value={billPhone} />
                                            <FormInput label="Email" type="email" maxLength="35" value={billEmail} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" form="frmEdit" content="Confirm Edit" color="blue" />
                </Modal.Footer>
            </Modal>

        </>
    )
}
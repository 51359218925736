import React, { useEffect, useMemo, useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useMyGlobal } from "../component/Global";
import axios from "axios";
import Stack from "react-bootstrap/Stack"
import { Button, Form, FormInput, FormSelect, Popup } from "semantic-ui-react";
import Modal from "rsuite/Modal"
import { Reacttable } from "../component/Reacttable";
import { Badge } from "rsuite";
import moment from "moment";
import { Toaster } from "../component/Toaster";


export const InventoryPC = () => {
    const { host, userProfile } = useMyGlobal()
    const [modalNew, setModalNew] = useState(false)
    const [inventoryData, setInventoryData] = useState([])
    const [modalEdit, setModalEdit] = useState(false)
    const [pcNumber, setPcNumber] = useState('')
    const [mainboard, setMainboard] = useState('')
    const [proc, setProc] = useState('')
    const [ram,setRam] = useState('')
    const [sound, setSound] = useState('')
    const [gpu, setGpu] = useState('')
    const [ether, setEther] = useState('')
    const [mac, setMac] = useState('')
    const [buydate, setBuydate] = useState(moment().format('YYYY-MM-DD'))
    const [condition, setCondition] = useState('Baik')
    const [location, setLocation] = useState('')
    const [user, setUser] = useState('')
    const [loadDelete, setLoadDelete] = useState({id:null,status:false})
    const [selectedRow, setSelectedRow] = useState({})
    const [id, setId] = useState('')

    const condOpt = [
        {key:1, text:'Baik', value:'baik'},
        {key:2, text:'Kurang Baik', value:'kurang baik'},
        {key:3, text:'Rusak', value:'rusak'},
        {key:4, text:'Belum Dicek', value:'belum dicek'}
    ]

    const columnInventoryPC = useMemo(() => [
        {
            Header:'No PC',
            accessor:'no_pc'
        },
        {
            Header:'Mainboard',
            accessor:'motherboard',
            Cell:({cell}) => (
                <div style={{width:'200px'}}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>}
                    />
                </div>
            )
        },
        {
            Header:'Processor',
            accessor:'processor',
            Cell:({cell}) => (
                <div style={{width:'200px'}}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>}
                    />
                </div>
            )
        },
        {
            Header:'RAM',
            accessor:'ram',
            Cell:({cell}) => (
                <div style={{width:'100px'}}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>}
                    />
                </div>
            )
        },
        {
            Header:'Soundcard',
            accessor:'soundcard',
            Cell:({cell}) => (
                <div style={{width:'200px'}}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>}
                    />
                </div>
            )
        },
        {
            Header:'VGA/GPU',
            accessor:'gpu',
            Cell:({cell}) => (
                <div style={{width:'200px'}}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>}
                    />
                </div>
            )
        },
        {
            Header:'Ethernet',
            accessor:'ethernet',Cell:({cell}) => (
                <div style={{width:'200px'}}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>}
                    />
                </div>
            )
        },
        {
            Header:'MAC',
            accessor:'mac_addr',
            Cell:({cell}) => (
                <div style={{width:'150px'}}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>}
                    />
                </div>
            )
        },
        {
            Header:'Buy Date',
            accessor:'buy_date',
        },
        {
            Header:'Condition',
            accessor:'condition',
            Cell:({cell}) => (
                <div className="text-capitalize">
                    <span className="me-2"><Badge color={cell.value.toLowerCase() === 'rusak' ? 'red' : cell.value.toLowerCase() === 'baik' ? 'green' : cell.value.toLowerCase() === 'kurang baik' ? 'orange' : 'grey' }/></span>
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header:'Location',
            accessor:'location'
        },
        {
            Header:'User',
            accessor:'user'
        },
        {
            Header:'Last Update',
            accessor:'updateAt'
        },
        {
            Header:'Actions',
            accessor:null,
            Cell:({cell}) => (
                <Button.Group size="mini" basic>
                    <Popup content="Delete selected PC" size="tiny" trigger={
                        <Button icon={{name:'trash alternate', color:'blue'}} onClick={() => handleDelete(cell.row.original.id)} loading={loadDelete.id === cell.row.original.id && loadDelete.status} disabled={loadDelete.id === cell.row.original.id && loadDelete.status}/>
                    }/> 
                    <Popup content="Edit this inventory" size="tiny" trigger={
                        <Button icon={{name:'edit', color:'blue'}} onClick={() => handleEdit(cell.row.original)}/>
                    }/>
                </Button.Group>
            )
        },
    ])

    const handleEdit = (data) => { 
        setSelectedRow(data)
        setPcNumber(data.no_pc)
        setBuydate(data.buy_date)
        setMainboard(data.motherboard)
        setProc(data.processor)
        setRam(data.ram)
        setSound(data.soundcard)
        setGpu(data.gpu)
        setEther(data.ethernet)
        setMac(data.mac_addr)
        setCondition(data.condition.toLowerCase())
        setLocation(data.location)
        setUser(data.user)
        setModalEdit(true)
        setId(data.id)
    }

    const clearForm =() => {
        setSelectedRow(null)
        setPcNumber('')
        setBuydate('')
        setMainboard('')
        setProc('')
        setRam('')
        setSound('')
        setGpu('')
        setEther('')
        setMac('')
        setCondition('')
        setLocation('')
        setUser('')
    }

    const handleSubmitEdit = () => {
        const params = {
            pc_number:pcNumber,
            buy_date:buydate,
            mainboard:mainboard,
            processor:proc,
            ram:ram,
            soundcard:sound,
            gpu:gpu,
            ethernet:ether,
            mac:mac,
            condition:condition,
            placed:location,
            user:user,
            id_user:userProfile.profile.id_user,
            id:id
        }
        
        axios({
            method: 'PUT',
            url: `${host}/ict/index.php?action=editPc`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            },
            data:params
        }).then (res => {
            if (res.data.status === 200){
                Toaster.fire({
                    icon:'success',
                    text:'Success edit selected Inventory',
                    position:'top-right'
                })
                setModalEdit(false)
                getInventory()
                clearForm()
            } else {
                Toaster.fire({
                    icon:'error',
                    text:res.data.msg,
                    position:'top-right'
                })
            }
        })
    }

    const handleDelete = (id) => {
        deletePc(id)
    }

    const handleClickNewEntry = () => {
        clearForm()
        setModalNew(true)
    }
    
    const getInventory = async () => {
        await axios({
            method: 'GET',
            url: `${host}/ict/index.php?action=inventoryPc`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            },
        }).then (res => {
            if (res.data.status === 200){
                setInventoryData(res.data.data)
            }
        })
    }

    const handleSubmit = () => {
        const params = {
            pc_number:pcNumber,
            buy_date:buydate,
            mainboard:mainboard,
            processor:proc,
            ram:ram,
            soundcard:sound,
            gpu:gpu,
            ethernet:ether,
            mac:mac,
            condition:condition,
            placed:location,
            user:user,
            id_user:userProfile.profile.id_user
        }
        
        axios({
            method: 'POST',
            url: `${host}/ict/index.php?action=addPc`,
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
            },
            data:params
        }).then (res => {
            if (res.data.status === 200){
                Toaster.fire({
                    icon:'success',
                    text:'Success adding new Inventory',
                    position:'top-right'
                })
                setModalNew(false)
                getInventory()
                clearForm()
            } else {
                Toaster.fire({
                    icon:'error',
                    text:res.data.msg,
                    position:'top-right'
                })
            }
        })
    }

    const deletePc = (id) => {
        const cfm = window.confirm("Are you sure you want to do this?")

        if (cfm) {
            setLoadDelete({id:id, status:true})
            axios({
                method: 'DELETE',
                url: `${host}/ict/index.php?action=deletePc`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer edc6104533fb7fc7dc782d646f27870753c5c05a70cb47f61218a27bf76f2107",
                },
                params:{
                    id:id
                }
            }).then (res => {
                if (res.data.status === 200){
                    Toaster.fire({
                        icon:"success",
                        text:"Success delete PC data",
                        position:"top-right"
                    })
                } else {
                    Toaster.fire({
                        icon:"error",
                        text:res.data.msg,
                        position:"top-right"
                    })
                }
            }).finally(() => {
                setLoadDelete({id:id, status:false})
                getInventory()
            })
        }
    }

    useEffect(() => {
        getInventory()
    },[])
    
    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Inventory PC</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={12} lg={12} md={12} sm={12} className="mb-3">
                        <Button content="Create New Entry" icon="add" color="blue" onClick={handleClickNewEntry}/>
                    </Col>
                    <Col lxl={12} lg={12} md={12} sm={12}>
                        <Reacttable
                            data={inventoryData}
                            columns={columnInventoryPC}
                            pageRow={10}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalNew} onClose={() => setModalNew(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Add New Entry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmNew" onSubmit={handleSubmit}>
                        <Form.Group>
                            <FormInput type="text" label="PC Number" value={pcNumber} onChange={(e) => setPcNumber(e.target.value)} placeholder="PC 01" required/>
                            <FormInput type="date" label="Buy Date" value={buydate} onChange={(e) => setBuydate(e.target.value)} width={10}/>   
                        </Form.Group>
                        <Form.Group grouped>
                            <FormInput type="text" label="Mainboard" value={mainboard} onChange={(e) => setMainboard(e.target.value)} placeholder="ASRock X570 Phantom Gaming X AMD AM4" required/>
                            <FormInput type="text" label="Processor" value={proc} onChange={(e) => setProc(e.target.value)} placeholder="AMD Ryzen 9 7945HX 16 Core 32 thread 2,5 Ghz" required/>
                            <FormInput type="text" label="RAM DIMM/SODIMM (in Gigabyte)" value={ram} onChange={(e) => setRam(e.target.value)} placeholder="32 GB (Visipro)" required/>
                            <FormInput type="text" label="Soundcard (Version)" value={sound} onChange={(e) => setSound(e.target.value)} placeholder="Realtek High Definition Audio Versi. R2.83" required/>
                            <FormInput type="text" label="GPU" value={gpu} onChange={(e) => setGpu(e.target.value)} placeholder="AMD Radeon RX 7900 GRE (8Gb)" required/>
                            <FormInput type="text" label="Ethernet (Version)" value={ether} onChange={(e) => setEther(e.target.value)} placeholder="Realtek PCIe GbE Family Controller" required/>   
                            <FormInput type="text" label="MAC Address" value={mac} onChange={(e) => setMac(e.target.value)} placeholder="00-B0-D0-63-C2-26" required/>   
                            <FormSelect label="Condition" options={condOpt} selection value={condition} allowAdditions onChange={(e, {value}) => setCondition(value)}/>
                            <FormInput type="text" label="Placed / Location" value={location} onChange={(e) => setLocation(e.target.value)} placeholder="Toko Hoops Surapati (Kasir)" required/> 
                            <FormInput type="text" label="User" value={user} onChange={(e) => setUser(e.target.value)} placeholder=" Bpk. Mamad (CS Website)" required/> 
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" content="Submit" form="frmNew" icon="save" color="blue" fluid/>
                </Modal.Footer>
            </Modal>

            <Modal open={modalEdit} onClose={() => setModalEdit(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Edit Inventory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                        <Form.Group>
                            <FormInput type="text" label="PC Number" value={pcNumber} onChange={(e) => setPcNumber(e.target.value)} placeholder="PC 01" required/>
                            <FormInput type="date" label="Buy Date" value={buydate} onChange={(e) => setBuydate(e.target.value)} width={10}/>   
                        </Form.Group>
                        <Form.Group grouped>
                            <FormInput type="text" label="Mainboard" value={mainboard} onChange={(e) => setMainboard(e.target.value)} placeholder="ASRock X570 Phantom Gaming X AMD AM4" required/>
                            <FormInput type="text" label="Processor" value={proc} onChange={(e) => setProc(e.target.value)} placeholder="AMD Ryzen 9 7945HX 16 Core 32 thread 2,5 Ghz" required/>
                            <FormInput type="text" label="RAM DIMM/SODIMM (in Gigabyte)" value={ram} onChange={(e) => setRam(e.target.value)} placeholder="32 GB (Visipro)" required/>
                            <FormInput type="text" label="Soundcard (Version)" value={sound} onChange={(e) => setSound(e.target.value)} placeholder="Realtek High Definition Audio Versi. R2.83" required/>
                            <FormInput type="text" label="GPU" value={gpu} onChange={(e) => setGpu(e.target.value)} placeholder="AMD Radeon RX 7900 GRE (8Gb)" required/>
                            <FormInput type="text" label="Ethernet (Version)" value={ether} onChange={(e) => setEther(e.target.value)} placeholder="Realtek PCIe GbE Family Controller" required/>   
                            <FormInput type="text" label="MAC Address" value={mac} onChange={(e) => setMac(e.target.value)} placeholder="00-B0-D0-63-C2-26" required/>   
                            <FormSelect label="Condition" options={condOpt} selection value={condition} allowAdditions onChange={(e, {value}) => setCondition(value)}/>
                            <FormInput type="text" label="Placed / Location" value={location} onChange={(e) => setLocation(e.target.value)} placeholder="Toko Hoops Surapati (Kasir)" required/> 
                            <FormInput type="text" label="User" value={user} onChange={(e) => setUser(e.target.value)} placeholder=" Bpk. Mamad (CS Website)" required/> 
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" content="Submit" form="frmEdit" icon="save" color="blue" fluid/>
                </Modal.Footer>
            </Modal>
        </>
    )
}